import { useState, useEffect } from "react";
import { closeIcon, infoIcon, plusIcon2 } from "../../SVG";
import DoghChart from "../../DoghChart";
import {
	BD,
	BN,
	ZERO,
	ZERO_BYTES_32,
	getUnscaledBD,
	inputToBnScaled,
	printBD,
} from "interfaces/Numbers";
import { Addresses } from "index";
import { computeRatio } from "App";
import { TxIDs } from "../ModalWrapper";

export default function SetAttack(props) {
	const {
		account,
		setAlert,
		setModal,
		adBlockedCKI,
		setTxInfo,
		ckiUnused,
		cookieList,
		addTx,
		balCki,
		setAd,
		signPermit,
		withdrawing,
		soundEffects,
	} = props;
	const [balance, setBalance] = useState(1000);
	const [value, setValue] = useState(0);
	const [updatedCookieList, setUpdatedCookieList] = useState([...cookieList]);
	const [updatedDailyRate, setUpdatedDailyRate] = useState(0);
	const [updatedAdBlockedCKI, setupdatedAdBlockedCKI] = useState(adBlockedCKI);

	useEffect(() => {
		if (value !== undefined && value !== "" && !isNaN(Number(value))) {
			const updateAdBlocked = !withdrawing
				? Number(adBlockedCKI) + Number(value * 1e18)
				: Number(adBlockedCKI) - Number(value * 1e18);
			setupdatedAdBlockedCKI(updateAdBlocked);
		} else {
			setupdatedAdBlockedCKI(adBlockedCKI);
		}
	}, [adBlockedCKI, value]);

	useEffect(() => {
		if (value && !isNaN(value))
			if (withdrawing) {
				setUpdatedDailyRate(BD(Number(adBlockedCKI.subtract(BD(inputToBnScaled(value))))));
			} else {
				setUpdatedDailyRate(BD(Number(adBlockedCKI.add(BD(inputToBnScaled(value))))));
			}
		else setUpdatedDailyRate(BD(Number(adBlockedCKI)));
	}, [value, adBlockedCKI]);

	useEffect(() => {
		setBalance(Math.floor(getUnscaledBD(withdrawing ? adBlockedCKI : ckiUnused) * 100) / 100);
	}, [ckiUnused, adBlockedCKI]);

	useEffect(() => {
		if (
			typeof value === "string" &&
			((value.charAt(value.length - 1) != "." &&
				value.charAt(value.length - 2) != "." &&
				value.charAt(value.length - 3) != ".") ||
				value[0] == "-" ||
				value[0] == "+")
		) {
			setValue(Math.abs(Math.floor(parseFloat(value.toString()) * 100) / 100));
		}
	}, [value]);

	// console.log(printBD(adBlockedCKI - BD(inputToBnScaled(value))));
	useEffect(() => {
		setUpdatedCookieList(cookieList);
		if (value && value !== "" && !isNaN(Number(value)) && balCki.compareTo(ZERO) !== 0) {
			const tot = cookieList.reduce((sum, item) => BD(sum).add(BD(item.bal)), BD(0));
			const newValue = BD(value)
				.multiply(BD(10 ** 18))
				.toBigInteger();

			const newCookieList = [
				{
					...updatedCookieList[0],
					percent: withdrawing
						? computeRatio(BD(BN(cookieList[0].bal).add(BN(newValue))), tot)
						: computeRatio(BD(BN(cookieList[0].bal).sub(BN(newValue))), tot),
				},
				{
					...cookieList[1],
				},
				{
					...cookieList[2],
				},
				{
					...cookieList[3],
				},
				{
					...updatedCookieList[4],
					percent: withdrawing
						? computeRatio(BD(BN(cookieList[4].bal).sub(BN(newValue))), tot)
						: computeRatio(BD(BN(cookieList[4].bal).add(BN(newValue))), tot),
				},
				{
					...cookieList[5],
				},
			];

			setUpdatedCookieList(newCookieList);
		}
	}, [value, cookieList, balCki]);

	return (
		<div className="modalStake">
			<div className="modalStake__inner">
				<div
					className="modalStake__inner-close"
					onClick={() => {
						soundEffects["click"].play();
						setModal(null);
					}}
				>
					{closeIcon}
				</div>
				<div className="modalStake__inner-title">
					<h4>{!withdrawing ? "Generate more Attack Dough" : "Withdraw Blocked CKI"}</h4>
				</div>
				<div className="modalStake__inner-desc">
					<p>
						{!withdrawing
							? "Block CKI to generate Attack Dough over time, which you can then use to launch attacks on others."
							: "Withdraw blocked CKI without any constraint or fee."}
					</p>
				</div>

				<div className="modalStake__content">
					<div className="modalStake__left">
						<div className="modalStake__left-func">
							<div className="modalStake__left-title">
								<p className="sm">
									{!withdrawing ? "Available Balance:" : "Current Blocked CKI:"}
								</p>
								<div className="modalStake__left-title-row">
									<p className="sm">
										{!withdrawing ? printBD(ckiUnused) : printBD(adBlockedCKI)}
									</p>

									<img src={process.env.PUBLIC_URL + "images/logo.svg"} alt="" />
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(value) ? value : ""}
										onChange={(e) => {
											if ((e.nativeEvent.data !== " ") && e.target.value <= balance) {
												setValue(e.target.value);
											}
										}}
									/>
									<p>{"CKI"}</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setValue(balance);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span
										style={{
											width: `${(value * 100) / balance}%`,
										}}
									></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 0 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 0)}
									></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.25 * 100) / 100)
										}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.5 * 100) / 100)
										}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.75 * 100) / 100)
										}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 99.999 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-box">
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>Blocked CKI</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(updatedAdBlockedCKI)}</p>
									<img
										src={process.env.PUBLIC_URL + "images/logo.svg"}
										alt=""
										style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
									/>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>Dough Generation</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												The new number of AD generated per second.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(BD(Number(updatedDailyRate) / 3600))}</p>
									<img
										src={
											process.env.PUBLIC_URL +
											"images/icons/kitchen/dough.svg"
										}
										alt=""
										style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
									/>
									<p style={{ fontWeight: "bold" }}>/ sec</p>
								</div>
							</div>
						</div>
						<div
							className="button primary sm"
							onClick={async () => {
								if (account === undefined) {
									soundEffects["error"].play();
									setAlert("Please connect your wallet.");
									return;
								}
								
								if (value === undefined || isNaN(value)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number.");
									return;
								}

								if (value === 0) {
									soundEffects["error"].play();
									setAlert("Please enter a non-zero amount.");
									return;
								}

								const val = withdrawing
									? adBlockedCKI.subtract(BD(inputToBnScaled(value)))
									: adBlockedCKI.add(BD(inputToBnScaled(value)));

								if (withdrawing) {
									soundEffects["decrease"].play();
								} else {
									soundEffects["coin"].play();
								}

								const permit = withdrawing
									? {
											deadline: 0,
											v: 0,
											r: ZERO_BYTES_32,
											s: ZERO_BYTES_32,
									  }
									: await signPermit(
											Addresses.CKI,
											Addresses.CCCompete,
											inputToBnScaled(value).toString()
									  );

								const tx = setAd(
									BN(val),
									permit.deadline,
									permit.v,
									permit.r,
									permit.s
								);
								addTx(
									TxIDs.SET_DOUGH,
									"Changing Dough Generation",
									(withdrawing ? "Withdrawing " : "Adding ") +
										value.toLocaleString("en-US") +
										" CKI",
									""
								);
								setModal(null);
							}}
						>
							{withdrawing ? "Withdraw Blocked CKI" : "Block to Generate Dough"}
						</div>
					</div>
					<div className="modalStake__right">
						<div className="modalStake__right-title">
							<h5 className="sm">{"CKI"} Balance</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Your new CKI balance and distribution strategy.
									</div>
								</div>
							</div>
						</div>
						<div className="modalStake__right-inner">
							<div className="modalStake__right-chart">
								<div className="modalStake__right-chart-bal">
									<img src="./images/logo.svg" alt="" />
									{printBD(balCki)}
								</div>
								<DoghChart
									data={updatedCookieList.map((item) => item.percent)}
									bgColors={updatedCookieList.map((item) => item.color)}
									percentCircle="75%"
								/>
							</div>
							<div className="modalStake__right-data">
								{updatedCookieList.map((item, index) => {
									return (
										<div className="modalStake__right-data-item" key={index}>
											<div
												className="modalStake__right-data-item-icon"
												style={{ background: item.color }}
											></div>
											{item.title} <span>{item.percent}%</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
