import { motion, AnimatePresence } from "framer-motion";
import { printBD, printUnscaledBD } from "interfaces/Numbers";
import React, { useEffect, useRef, useState } from "react";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";
import { TxIDs } from "./Modals/ModalWrapper";

export default function Notif(props) {
	const {
		fdgLockedClaimable,
		fdgStakedClaimable,
		ckiLockedClaimable,
		ckiStakedClaimable,
		addTx,
		claimAll,
		attackEvents,
		account,
		soundEffects,
	} = props;
	const wrapper = useRef(null);
	const [active, setActive] = useState(false);
	const toggleActive = () => {
		soundEffects["click"].play();
		setActive(!active);
	};
	const [notifList, setNotifList] = useState([]);

	const claimText = `Claim all your in-game earnings: ${
		printBD(Number(ckiLockedClaimable) + Number(fdgStakedClaimable)) +
		"  CKI, " +
		printBD(Number(fdgLockedClaimable) + Number(ckiStakedClaimable)) +
		" FDG."
	}`;
	const empyList = [
		{
			id: 0,
			type: "period",
			time: "",
			text: claimText,
		},
	];

	useEffect(() => {
		const list = [empyList[0]];
		if (attackEvents) {
			for (var i = 0; i < attackEvents.value.length; i++) {
				const data = attackEvents.value[i].data;
				if (data.adv == account) {
					const time = new Date(Number(data.timestamp) * 1000);
					const hour = time.getHours();
					const minutes = time.getMinutes();
					const avatarAttacker = createAvatar(avataaarsNeutral, {
						seed: data.attacker,
					}).toString();
					const style =
						' style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px;background-color: #d0eef4;"';

					const avatar =
						avatarAttacker.substring(0, 4) +
						style +
						avatarAttacker.substring(4, avatarAttacker.length - 6) +
						"<title>" +
						data.attacker +
						"</title>" +
						"</svg>";

					const adLoot = Number(data.gain) == 0 ? printBD(data.apUSed.div(2)) : 1;
					const gain = Number(data.gain) == 0 ? "Won" : `-${printBD(data.gain)}`;
					list.push({
						id: i + 1,
						time: `${hour}:${minutes < 10 ? "0" + minutes : minutes}`,
						type: "attack",
						text: `Attacked by ${avatar}(${data.attacker.substring(0, 10)}...)`,
						lost: gain,
						ad: `+${adLoot} AD`,
					});
				}
			}
		}
		setNotifList(list);
	}, [attackEvents]);

	useEffect(() => {
		const windowClick = ({ target }) => {
			if (!wrapper.current.contains(target)) setActive(false);
		};

		if (active) window.addEventListener("click", windowClick);
		else window.removeEventListener("click", windowClick);

		return () => window.removeEventListener("click", windowClick);
	}, [active]);

	return (
		<div className={"notif"} ref={wrapper}>
			<button className={"notifBtn " + (active ? "active" : "")} onClick={toggleActive}>
				<div className="notifBtn__icon">
					<img src="./images/icons/bell.svg" alt="" />
					{notifList.length > 1 && <span>{notifList.length - 1}</span>}
				</div>
			</button>
			<AnimatePresence>
				{active === true && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.2 }}
						exit={{ opacity: 0 }}
						className={`notifDrop  ${active ? "active" : ""} `}
					>
						<h5>My Notifications</h5>
						<div className="notifDrop__checks">
							<div className="notifDrop__check">
								<input type="checkbox" defaultChecked />
								<label htmlFor=""></label>
								<p>Attacks</p>
							</div>
							{/*s<div className="notifDrop__check">
								<input type="checkbox" defaultChecked />
								<label htmlFor=""></label>
								<p>gCKI Releases</p>
							</div>*/}
						</div>
						<div className="notifDrop__inner">
							{notifList.map((item, index) => {
								return (
									<div
										className={
											"notifDrop__item " +
											(item.type === "period" ? "period" : "")
										}
										key={index}
									>
										<div className="notifDrop__item-left">
											<div className="notifDrop__item-left-time">
												{item.type === "attack" && <span></span>}
												<p className="sm">{item.time}</p>
											</div>
											<p
												className="sm"
												dangerouslySetInnerHTML={{ __html: item.text }}
											>
												{/* {item.text} */}
											</p>
										</div>
										<div className="notifDrop__item-right">
											{item.type === "period" && (
												<button
													className="button secondary"
													onClick={async () => {
														soundEffects["claim"].play();
														addTx(
															TxIDs.CLAIM_ALL,
															"Claiming rewards",
															"Claiming All",
															""
														);

														await claimAll(account);
													}}
												>
													Claim All
												</button>
											)}
											{item.type === "attack" && (
												<>
													<div
														className={
															"notifDrop__item-right-tag " +
															(item.lost != "Won" ? "red" : "green")
														}
													>
														{item.lost}
													</div>
													<div className="notifDrop__item-right-tag orange">
														{item.ad}
													</div>
												</>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
