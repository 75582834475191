import React, { useEffect, useState } from "react";
import { TxIDs, TxState } from "./ModalWrapper";

const getIcons = () => ({
	[TxIDs.ATTACK]: process.env.PUBLIC_URL + "/images/icons/mixing.png",
	[TxIDs.SET_DOUGH]: process.env.PUBLIC_URL + "/images/icons/kitchen/dough.svg",
	[TxIDs.SET_DEFENCE]: process.env.PUBLIC_URL + "/images/icons/kitchen/points.svg",
	[TxIDs.SET_GCKI]: process.env.PUBLIC_URL + "/images/icons/kitchen/goldCookie.svg",
	[TxIDs.LOCK_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.UNLOCK_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.LOCK_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.UNLOCK_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.STAKE_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.UNSTAKE_CKI]: process.env.PUBLIC_URL + "/images/icons/cookie_staking.png",
	[TxIDs.STAKE_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.UNSTAKE_FDG]: process.env.PUBLIC_URL + "/images/icons/fudge_staking.png",
	[TxIDs.CLAIM_ALL]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_FDG_LOCK]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_CKI_LOCK]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_CKI_STAKING]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.CLAIM_FDG_STAKING]: process.env.PUBLIC_URL + "/images/icons/cup.png",
	[TxIDs.MINT]: process.env.PUBLIC_URL + "/images/icons/goldCookie.svg",
	[TxIDs.SEARCH]: process.env.PUBLIC_URL + "/images/icons/mixing.png",
});

export default function ModalSmall(props) {
	const { modal, close, state } = props;

	const [hide, setHide] = useState(false);
	const { id, type, title, amount } = modal;

	const icons = getIcons();

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		// transitionState(modal, TxState.SUCCESS);
	// 		// state = TxState.SUCCESS;
	// 	}, 3000);
	// }, []);

	useEffect(() => {
		if (state === TxState.SUCCESS || state === TxState.FAILURE) {
			setTimeout(() => {
				close(id);
			}, 3000);
		}
	}, [state, modal]);

	return (
		<>
			<div className={`modalMiniTx ${hide ? "hide" : ""}`}>
				<img className="modalMiniTx-img" loading="lazy" src={icons[id]} />
				<div className="modalMiniTx__inner">
					<img
						className="img-modalMiniTx__inner"
						loading="lazy"
						src={process.env.PUBLIC_URL + "/images/icons/close-cross.svg"}
						onClick={() => close(id)}
					/>
					<div className="modalMiniTx__inner_type">{type}</div>
					<div className="modalMiniTx__inner_content">
						<div className="modalMiniTx__inner_title">{title}</div>
						{/* <img
							className="modalMiniTx__inner_title-img"
							loading="lazy"
							src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e6d6ab465f9168c9de8c33933a75cef2fef7009c42ce14c6e47fdec8775872e?"
						/> */}
						{amount != "" && <div className="modalMiniTx__inner_amount">{amount}</div>}
					</div>
					{state == TxState.SIGNING && (
						<div className="modalMiniTx__inner_loading">
							<img
								className="modalMiniTx__inner_loading-img"
								loading="lazy"
								src={process.env.PUBLIC_URL + "/images/icons/loading-orange.webp"}
							/>
							<div
								className="modalMiniTx__inner_loading_text"
								style={{ color: "#CC5500" }}
							>
								Sign in wallet
							</div>
						</div>
					)}
					{state == TxState.LOADING && (
						<div className="modalMiniTx__inner_loading">
							<img
								className="modalMiniTx__inner_loading-img"
								loading="lazy"
								src={process.env.PUBLIC_URL + "/images/icons/loading.webp"}
							/>
							<div className="modalMiniTx__inner_loading_text">Loading</div>
						</div>
					)}
					{state == TxState.SUCCESS && (
						<div className="modalMiniTx__inner_loading">
							<div
								className="modalMiniTx__inner_loading_text"
								style={{ color: "green" }}
							>
								&#10003; Success
							</div>
						</div>
					)}
					{state == TxState.FAILURE && (
						<div className="modalMiniTx__inner_loading">
							<div
								className="modalMiniTx__inner_loading_text"
								style={{ color: "red" }}
							>
								&#10799; Failed
							</div>
						</div>
					)}
				</div>
			</div>
			<style jsx>{`
				.modalMiniTx {
					position: relative;
					z-index: 999999999;
					display: flex;
					font-weight: 400;
					align-items: center;
					background-color: white;
					padding: 12px;
					flex-grow: 1;
					border-radius: 28px;
					min-width: 100%; /* Set a minimum width */
					-webkit-box-shadow: 0px 4px 8px rgba(45, 35, 25, 0.18);
					box-shadow: 0px 4px 8px rgba(45, 35, 25, 0.18);
					margin-top: 15px;
					// position: fixed;
					// bottom: 1%; /* Anchor it to the bottom */
					// left: 1%; /* Anchor it to the left */
					transition: opacity 2s ease-in-out;

					opacity: 1; /* Default opacity */
				}

				.modalMiniTx.hide {
					opacity: 0;
					visibility: hidden;
				}
				.modalMiniTx-img {
					aspect-ratio: 1;
					object-fit: auto;
					object-position: center;
					width: 60px;
					height: 60px;
					margin-right: 8px;
					// box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
				}

				.modalMiniTx__inner {
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					flex-basis: 0;
					width: fit-content;
					margin-right: auto 0;

					// margin: auto 0;
					// padding: 0 0px;
				}
				.img-modalMiniTx__inner {
					aspect-ratio: 0.56;
					// object-fit: auto;
					// object-position: center;
					width: 12px;
					// fill: #e9e7e3;
					align-self: end;
					// margin-left: 30%;
					position: absolute;
					top: 7px; // Adjust top position to move it downward
					right: 9px; // Adjust right position to move it inward
				}
				.modalMiniTx__inner_type {
					color: #41496d;
					font: 14px Montserrat, sans-serif;
				}
				.modalMiniTx__inner_content {
					justify-content: space-between;
					align-items: center;
					display: flex;
					gap: 10px;
					font-weight: 700;
					// padding: 1px 0;
				}
				.modalMiniTx__inner_title {
					color: #687f92;
					align-self: stretch;
					margin: auto 0;
					font: bold 16px Montserrat, sans-serif;
				}
				.modalMiniTx__inner_title-img {
					aspect-ratio: 1;
					object-fit: auto;
					object-position: center;
					width: 34px;
					align-self: stretch;
				}
				.modalMiniTx__inner_amount {
					justify-content: center;
					border-radius: 100px;
					background-color: #ff5c33;
					align-self: stretch;
					color: rgba(255, 255, 255, 0.9);
					margin: auto 0;
					padding: 6px;
					font: 12px Montserrat, sans-serif;
				}
				.modalMiniTx__inner_loading {
					align-self: start;
					display: flex;
					align-items: center;
					gap: 3px;
					font-size: 11px;
					color: #687f92;
					white-space: nowrap;
				}
				.modalMiniTx__inner_loading-img {
					aspect-ratio: 1;
					object-fit: auto;
					object-position: center;
					width: 10px;
					height: 10px;
					margin-right: 5px;
					animation: spin 1s linear infinite;
				}
				@keyframes spin {
					from {
						transform: rotate(0deg);
					}
					to {
						transform: rotate(360deg);
					}
				}
				.modalMiniTx__inner_loading_text {
					font-family: Montserrat, sans-serif;
				}
			`}</style>
		</>
	);
}
