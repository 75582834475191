import { BigNumber } from "ethers";
var bigdecimal = require("bigdecimal");

export const ZERO = bigdecimal.BigDecimal(0);
export const OFFSET = bigdecimal.BigDecimal(10 ** 18);
export const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

export const ZERO_BYTES_32 = "0x" + "00".repeat(32);

export function BD(nb: any) {
	return new bigdecimal.BigDecimal(nb.toString());
}

export function BN(nb: any) {
    if (nb instanceof bigdecimal.BigDecimal && nb.compareTo(ZERO) === 0) {
        return BigNumber.from(0);
    }
    return BigNumber.from(nb.toString());
}

export function getUnscaledBD(bd: any) {
	return parseFloat(BD(bd)?.divide(OFFSET).toString());
}

export function printBD(bd: any) {
	const value = bd && !isNaN(bd) ? Number(BD(bd)?.divide(OFFSET).toString()) : 0;
	const roundedDownValue = Math.floor(value * 100) / 100;

	return roundedDownValue.toLocaleString("en-US", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 2,
		useGrouping: true,
	});
}

export function printUnscaledBD(bd: any, decimals: number = 2) {
	const value = bd && !isNaN(bd) ? Number(BD(bd)?.toString()) : 0;
	const roundedDownValue = Math.floor(value * 100) / 100;

	return roundedDownValue.toLocaleString("en-US", {
		minimumFractionDigits: 0,
		maximumFractionDigits: decimals,
		useGrouping: true,
	});
}

export function printUnscaledNoRound(bd: any, decimals: number = 2) {
	const value = bd && !isNaN(bd) ? Number(BD(bd)?.toString()) : 0;

	return value.toLocaleString("en-US", {
		minimumFractionDigits: 0,
		maximumFractionDigits: decimals,
		useGrouping: true,
	});
}	

export function abbreviateNumber(number: number) {
	const abbreviations = {
		B: 1000000000,
		M: 1000000,
		K: 1000,
	};

	for (const symbol in abbreviations) {
		if (number >= abbreviations[symbol]) {
			return (
				(number / abbreviations[symbol]).toLocaleString("en-US", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 3,
					useGrouping: true,
				}) + symbol
			);
		}
	}

	return number.toString();
}

export function printBDInt(bd: any) {
	if (!bd) return "0";
	const value = bd && !isNaN(bd) ? Number(BD(bd)?.divide(OFFSET).toString()) : 0;
	return value.toLocaleString("en-US", {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	});
}

export function printApy(nb: any) {
	if (!nb) return "-%";
	if (!isFinite(nb)) return "∞%";
	return (
		nb.toLocaleString("en-US", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			useGrouping: true,
		}) + "%"
	);
}

export function inputToBn(s: any) {
	if (!isNaN(+s)) {
		let bd = bigdecimal.BigDecimal(s.toString());

		// return ZERO;

		return BigNumber.from(bd.toString().split(".")[0]);
	} else {
		alert("invalid input");
	}
}

export function inputToBnScaled(s: any) {
	if (!isNaN(+s)) {
		const bd = bigdecimal.BigDecimal(s.toString()).multiply(OFFSET);
		return BigNumber.from(bd.toString().split(".")[0]);
	} else {
		alert("invalid input");
	}
}

export function randomBN() {
	// Generate random BN
	const random256b = binToDec(
		Array(256)
			.fill(0)
			.map(() => (Math.random() > 0.5 ? "1" : "0"))
			.join("")
	);

	return random256b;
}

function binToDec(binStr: String) {
	const lastIndex = binStr.length - 1;
	return Array.from(binStr).reduceRight(
		(total, currValue, index) =>
			currValue === "1" ? total.add(BN(2).pow(BN(lastIndex - index))) : total,
		BN(0)
	);
}
