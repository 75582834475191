import React, { useEffect } from "react";
import { closeIcon, roundArrowIcon, walletIcon } from "../../SVG";

export default function Purchase2({
	setModal,
	txInfo,
	stakeCkiClaimState,
	stakeCkiState,
	stakeFdgClaimState,
	stakeFdgState,
	unstakeCkiState,
	unstakeFdgState,
	setDefenceState,
	setAdState,
	ckiLockState,
	ckiUnlockState,
	ckiLockedClaimState,
	fdgLockState,
	fdgUnlockState,
	fdgLockedClaimState,
	gCkiChangeState
}) {
	useEffect(() => {
		let state = null;
		if (txInfo.action == "stake" && txInfo.token == "CKI") {
			state = stakeCkiState;
		} if (txInfo.action == "gCki") {
			state = gCkiChangeState;
		} else if (txInfo.action == "claim" && txInfo.token == "FDG") {
			state = stakeCkiClaimState;
		} else if (txInfo.action == "claim" && txInfo.token == "CKI") {
			state = stakeFdgClaimState;
		} else if (txInfo.action == "claimLock" && txInfo.token == "FDG") {
			state = fdgLockedClaimState;
		} else if (txInfo.action == "claimLock" && txInfo.token == "CKI") {
			state = ckiLockedClaimState;
		} else if (txInfo.action == "stake" && txInfo.token == "FDG") {
			state = stakeFdgState;
		} else if (txInfo.action == "unstake" && txInfo.token == "CKI") {
			state = unstakeCkiState;
		} else if (txInfo.action == "unstake" && txInfo.token == "FDG") {
			state = unstakeFdgState;
		} else if (txInfo.action == "defence") {
			state = setDefenceState;
		} else if (txInfo.action == "ad") {
			state = setAdState;
		} else if (txInfo.action == "lock" && txInfo.token == "CKI") {
			state = ckiLockState;
		} else if (txInfo.action == "lock" && txInfo.token == "FDG") {
			state = fdgLockState;
		} else if (txInfo.action == "unlock" && txInfo.token == "CKI") {
			state = ckiUnlockState;
		} else if (txInfo.action == "unlock" && txInfo.token == "FDG") {
			state = fdgUnlockState;
		}

		if (state["status"] == "PendingSignature") {
			setModal("purchase");
		} else if (state["status"] == "Mining") {
			setModal("purchase2");
		} else if (state["status"] == "Success") {
			setModal("purchaseSuccess");
		} else if (state["status"] == "Error") {
			setModal("purchaseLose");
		} else if (state["status"] == "Exception") {
			setModal("purchaseLose");
		}
	}, [
		txInfo,
		stakeCkiState,
		stakeCkiClaimState,
		stakeFdgClaimState,
		stakeFdgState,
		unstakeCkiState,
		unstakeFdgState,
		setDefenceState,
		setAdState,
		ckiLockState,
		ckiUnlockState,
		ckiLockedClaimState,
		fdgLockState,
		fdgUnlockState,
		fdgLockedClaimState,
		gCkiChangeState
	]);

	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<h5>Transaction Sent</h5>
				<div className="modalAttack__item center">
					<div className="modalAttack__item-inner">
						<h6>{txInfo.description}</h6>
						<p className="sm">
							<b>{txInfo.value}</b>
							<span>
								<img
									src={
										txInfo.token == "CKI"
											? process.env.PUBLIC_URL + "/images/logo.svg"
											: process.env.PUBLIC_URL + "/images/fudge.svg"
									}
									alt=""
								/>
							</span>
						</p>
						{txInfo.action == "gCki" ? <p className="sm"><b>{txInfo.days} days</b></p> : ""}
					</div>
				</div>
				<div className="modalAttack__inner-image sm">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/mixing.png"}
						alt=""
					/>
				</div>
				<div className="modalAttack__back sm">
					<p className="sm">Mixing dough...</p>
				</div>
			</div>
		</div>
	);
}
