import React, { useEffect } from "react";
import { closeIcon, infoIcon } from "../../SVG";

export default function PurchaseLose({
	setModal,
	txInfo,
	stakeCkiClaimState,
	stakeCkiState,
	stakeFdgClaimState,
	stakeFdgState,
	unstakeCkiState,
	unstakeFdgState,
	setDefenceState,
	setAdState,
	ckiLockState,
	ckiUnlockState,
	ckiLockedClaimState,
	fdgLockState,
	fdgUnlockState,
	fdgLockedClaimState,
	gCkiChangeState
}) {
	useEffect(() => {
		let state = null;
		if (txInfo.action == "stake" && txInfo.token == "CKI") {
			state = stakeCkiState;
		} if (txInfo.action == "gCki") {
			state = gCkiChangeState;
		} else if (txInfo.action == "claim" && txInfo.token == "FDG") {
			state = stakeCkiClaimState;
		} else if (txInfo.action == "claim" && txInfo.token == "CKI") {
			state = stakeFdgClaimState;
		} else if (txInfo.action == "claimLock" && txInfo.token == "FDG") {
			state = fdgLockedClaimState;
		} else if (txInfo.action == "claimLock" && txInfo.token == "CKI") {
			state = ckiLockedClaimState;
		} else if (txInfo.action == "stake" && txInfo.token == "FDG") {
			state = stakeFdgState;
		} else if (txInfo.action == "unstake" && txInfo.token == "CKI") {
			state = unstakeCkiState;
		} else if (txInfo.action == "unstake" && txInfo.token == "FDG") {
			state = unstakeFdgState;
		} else if (txInfo.action == "defence") {
			state = setDefenceState;
		} else if (txInfo.action == "ad") {
			state = setAdState;
		} else if (txInfo.action == "lock" && txInfo.token == "CKI") {
			state = ckiLockState;
		} else if (txInfo.action == "lock" && txInfo.token == "FDG") {
			state = fdgLockState;
		} else if (txInfo.action == "unlock" && txInfo.token == "CKI") {
			state = ckiUnlockState;
		} else if (txInfo.action == "unlock" && txInfo.token == "FDG") {
			state = fdgUnlockState;
		}

		if (state["status"] == "PendingSignature") {
			setModal("purchase");
		} else if (state["status"] == "Mining") {
			setModal("purchase2");
		} else if (state["status"] == "Success") {
			setModal("purchaseSuccess");
		} else if (state["status"] == "Error") {
			setModal("purchaseLose");
		} else if (state["status"] == "Exception") {
			setModal("purchaseLose");
		}
	}, [
		txInfo,
		stakeCkiState,
		stakeCkiClaimState,
		stakeFdgClaimState,
		stakeFdgState,
		unstakeCkiState,
		unstakeFdgState,
		setDefenceState,
		setAdState,
		ckiLockState,
		ckiUnlockState,
		ckiLockedClaimState,
		fdgLockState,
		fdgUnlockState,
		fdgLockedClaimState,
		gCkiChangeState
	]);

	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>

				<h5>Transaction Failed</h5>
				<div className="modalAttack__inner-tick">
					<img src={process.env.PUBLIC_URL + "/images/icons/cross.png"} alt="" />
				</div>
				<div className="modalAttack__success fail">
					<h6>{txInfo.description}</h6>
					<p>
						<b>{txInfo.value}</b>
						<span>
							<img
								src={
									txInfo.token == "CKI"
										? process.env.PUBLIC_URL + "/images/logo.svg"
										: process.env.PUBLIC_URL + "/images/fudge.svg"
								}
								alt=""
							/>
						</span>
					</p>
					{txInfo.action == "gCki" ? <p className="sm"><b>{txInfo.days} days</b></p> : ""}
				</div>
				<div className="modalAttack__foot">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				{/* <div className="modalAttack__inner-callback">
					<p>Waiting for Chainlink callback</p>
					<div className="tooltip">
						<div className="tooltip__icon">{infoIcon}</div>
						<div className="tooltip__content">
							<div className="tooltip__content-inner">
								Lorem ipsum dolor sit amet consectetur adipisicing elit.
							</div>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
}
