import { BigNumber, Contract, utils } from "ethers";
import CkiDistr from "../abis/CkiDistr.json";
import FdgDistr from "../abis/FdgDistr.json";
import UtilsAbi from "../abis/Utils.json";
import { useCall, useContractFunction } from "@usedapp/core";
import { BD, BN } from "./Numbers";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { useEffect, useState } from "react";
import { computeRatio } from "App";
var bigdecimal = require("bigdecimal");

class Utils {
	private ckiRevenue: any;
	private fdgRevenue: any;
	public gCkiChangeState: any;

	public deposit: (
		period: BigNumber,
		amount: BigNumber,
		deadline: any, v: any, r: any, s: any, owner: any
	) => Promise<TransactionReceipt | undefined>;

	public claimAll: (user: string) => Promise<TransactionReceipt | undefined>;
	public claimAllState: any;

	public ckiRev() {
		const ret = this.ckiRevenue
			? bigdecimal.BigDecimal(this.ckiRevenue.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public fdgRev() {
		const ret = this.fdgRevenue
			? bigdecimal.BigDecimal(this.fdgRevenue.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public ckiStakeRev() {
		return (this.fdgRev() * 25) / 100;
	}

	public fdgStakeRev() {
		return (this.ckiRev() * 25) / 100;
	}

	public ckiLockRev() {
		return (this.ckiRev() * 75) / 100;
	}

	public fdgLockRev() {
		return (this.fdgRev() * 75) / 100;
	}

	constructor(ckiRevenue: any, fdgRevenue: any, claimAll: any, claimAllState: any, deposit: any, gCkiChangeState: any) {
		this.ckiRevenue = ckiRevenue;
		this.fdgRevenue = fdgRevenue;
		this.claimAll = claimAll;
		this.claimAllState = claimAllState;
		this.deposit = deposit;
		this.gCkiChangeState = gCkiChangeState;
	}
}

export function CreateUtils(addressCki: string, addressFdg: string, addressUtils: string) {
	const ckiDistr = new Contract(addressCki, new utils.Interface(CkiDistr));
	const fdgDistr = new Contract(addressFdg, new utils.Interface(FdgDistr));
	const stakingUtils = new Contract(addressUtils, new utils.Interface(UtilsAbi));

	const { value: ckiRevenue, error } =
		useCall({
			contract: ckiDistr,
			method: "revenue",
			args: [60 * 60 * 24], // One day.
		}) ?? {};
	if (error) {
		console.error(error.message);
	}

	const { value: fdgRevenue, error: err } =
		useCall({
			contract: fdgDistr,
			method: "revenue",
			args: [60 * 60 * 24], // One day.
		}) ?? {};
	if (err) {
		console.error(err.message);
	}

	const { state: claimAllState, send: claimAll } = useContractFunction(stakingUtils, "claimAll", {
		transactionName: "claimAll",
		gasLimitBufferPercentage: 10,
	});

	const { state: gCkiChangeState, send: addGCkiAndUpdatePools } = useContractFunction(stakingUtils, "addGCkiAndUpdatePools", {
		transactionName: "addGCkiAndUpdatePools",
		gasLimitBufferPercentage: 10,
	});

	const deposit = (period: BigNumber, amount: BigNumber, deadline: any, v: any, r: any, s: any, owner: any) => {
		return addGCkiAndUpdatePools(period, amount, deadline, v, r, s, owner);
	};

	return new Utils(ckiRevenue, fdgRevenue, claimAll, claimAllState, deposit, gCkiChangeState);
}

// tanh(((x-75)/10))) + 1.75
export function getLeagueBoost(league: any) {
	if (league <= 0) return 0;
	return Math.tanh((league - 75) / 10) + 1.75;
}

export function getGCki(ckiDep: any, daysBlocked: any) {
	return (2 * (ckiDep * daysBlocked)) / 28;
}

export function getLockStrength(newStake: any, gCkiBal: any) {
	let league = Math.floor(Math.log2(newStake)) + 1;
	if (!isFinite(league)) league = 0;

	const leagueBoost = getLeagueBoost(league);
	const boostMultiplier = Number(newStake) * gCkiBal;

	return leagueBoost * (Number(newStake) + 2 * Math.sqrt(boostMultiplier));
}

export function RealTimeBalance(claimableValue: any, ownStakeHk: any, totalStakeHk: any, rev: any, timestamp: any) {
	const [claimable, setClaimable] = useState(claimableValue);
	
	useEffect(() => {
		const ownStake = ownStakeHk
			? bigdecimal.BigDecimal(ownStakeHk.toString())
			: bigdecimal.BigDecimal(0);
		const totalStake = totalStakeHk
			? bigdecimal.BigDecimal(totalStakeHk.toString())
			: bigdecimal.BigDecimal(0);

		const ratio = computeRatio(ownStake, totalStake);
		if (claimableValue && timestamp) {
			const refreshRate = 1000; // In ms.
			const rate = rev / (24 * 60 * 60 * 1000);

			const intervalId = setInterval(() => {
				const localElapsed = new Date().getTime() - timestamp.getTime();
				setClaimable(
					BN(claimableValue).add(BN(Math.ceil((rate * localElapsed * ratio) / 100)))
				);
			}, refreshRate);

			return () => clearInterval(intervalId);
		} else {
			setClaimable(claimableValue);
		}
	}, [claimableValue, timestamp]);

	return claimable;
}

export function RealTimeAD(ad: any, attackCki: any, timestamp: any) {
	const [adBal, setAdBal] = useState(ad);
	useEffect(() => {
		const now = new Date();
		if (ad && timestamp) {
			const adDay = BD(attackCki.toString()).multiply(BD(24));
			const refreshRate = 1000; // In ms.
			const rate = adDay / (24 * 60 * 60 * 1000);
			const intervalId = setInterval(() => {
				const localElapsed = new Date().getTime() - timestamp.getTime();
				setAdBal(BN(ad).add(BN(Math.ceil(rate * localElapsed))));
			}, refreshRate);

			return () => {
				clearInterval(intervalId);
			};
		} else {
			setAdBal(ad);
		}
	}, [ad, timestamp]);

	return adBal;
}
