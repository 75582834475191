import React from "react";
import { infoIcon } from "../../../../../Base/SVG";
import { CompModul } from "./CompModul";
import TableItem from "./TableItem";

export default function Table({
	setOpponent,
	setListView,
	listOpponents,
	dataType,
	adUsed,
	account,
}) {
	// console.log(listOpponents);
	return (
		<div className="competeBody__table">
			<div className="competeBody__table-inner">
				<table>
					<thead>
						<tr>
							<th style={{ padding: "" }}></th>
							<th></th>
							<th>
								<div className="competeBody__table-th">
									<h6>Defence</h6>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												The more defence an opponent has, the harder it is
												to attack them successfully.
											</div>
										</div>
									</div>
								</div>
							</th>
							<th>
								<div className="competeBody__table-th">
									<h6>Bounty</h6>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Reward you will capture from your opponent if the
												attack is successful.
											</div>
										</div>
									</div>
								</div>
							</th>
							<th>
								<div className="competeBody__table-th">
									<h6>Victory</h6>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Increase the dough used for the attack to increase
												your victory chances!
											</div>
										</div>
									</div>
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						{listOpponents?.map((item, index) => {
							return (
								<TableItem
									itemData={item}
									account={account}
									key={index}
									dataType={dataType}
									adUsed={adUsed}
									onClick={() => {
										setOpponent(item);
										setListView(false);
									}}
								/>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}
