import { Contract, BigNumber, utils } from "ethers";
import CKI from "../abis/Cki.json";
import { useCall, useContractFunction } from "@usedapp/core";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { BD, printBD, ZERO } from "./Numbers";
import { Addresses } from "index";

export class Erc20 {
	private balance: any;
	public approveState: any;
	private competeAllowance: any;

	private approveFunc: (
		spender: string,
		amount: BigNumber
	) => Promise<TransactionReceipt | undefined>;

	public mint: (amount: BigNumber) => Promise<TransactionReceipt | undefined>;

	public bal() {
		const ret = this.balance ? BD(this.balance.toString()) : ZERO;
		return ret;
	}

	public approved() {
		const ret = this.competeAllowance ? BD(this.competeAllowance.toString()) : ZERO;
		return Number(ret);
	}

	public async approve(spender: string, amount: BigNumber) {
		await this.approveFunc(spender, amount);
	}

	constructor(balance: any, mint: any, approve: any, approveState: any, competeAllowance: any) {
		this.balance = balance;
		this.mint = mint;
		this.approveFunc = approve;
		this.approveState = approveState;
		this.competeAllowance = competeAllowance;
	}
}

export function CreateErc20(
	address: string,
	account: string | undefined,
	needAllowance: boolean = false
) {
	const contract = new Contract(address, new utils.Interface(CKI));

	const { value, error } =
		useCall(
			account && {
				contract: contract,
				method: "balanceOf",
				args: [account],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	const balance = value?.[0];

	let competeAllowance = undefined;
	// if (needAllowance) {
	const { value: val, error: err } =
		useCall(
			account && {
				contract: contract,
				method: "allowance",
				args: [account, Addresses.CCCompete],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	competeAllowance = val?.[0];
	// }

	// console.log("competeAllowance", printBD(competeAllowance));

	const { send: sendMint } = useContractFunction(contract, "devMint", {
		transactionName: "Mint",
		gasLimitBufferPercentage: 10,
	});

	const mint = (amount: BigNumber) => {
		return sendMint(amount);
	};

	const { send: sendApprove, state: approveState } = useContractFunction(contract, "approve", {
		transactionName: "approve",
		gasLimitBufferPercentage: 10,
	});

	const approve = (spender: string, amount: BigNumber) => {
		return sendApprove(spender, amount);
	};

	// const { value: nonce, error: err2 } =
	// 	useCall(
	// 		account && {
	// 			contract: contract,
	// 			method: "nonces",
	// 			args: [account],
	// 		}
	// 	) ?? {};
	// if (err2) {
	// 	console.error(error.message);
	// }

	return new Erc20(balance, mint, approve, approveState, competeAllowance);
}
