import { printBD } from "interfaces/Numbers";
import React from "react";
import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";

export default function TableItem({ itemData, onClick, dataType, adUsed, account }) {
	// console.log(itemData.address);
	const avatar = createAvatar(avataaarsNeutral, {
		seed: itemData.address,
	});

	return (
		<tr onClick={onClick}>
			<td>
				<div
					style={{
						borderRadius: "50%",
						overflow: "hidden",
						display: "block",
						width: "20px",
						height: "20px",
						position: "relative",
						backgroundColor: "#d0eef4",
					}}
				>
					{account == itemData.address && (
						<img src="./images/icons/metamask.svg" alt="" />
					)}
					{account != itemData.address && (
						<svg
							style={{
								position: "absolute",
								width: "100%",
								height: "100%",
							}}
							dangerouslySetInnerHTML={{ __html: avatar.toString() }}
						/>
					)}
				</div>
			</td>
			<td>
				<p>
					{account == itemData.address ? "You" : itemData.address.substring(0, 8) + "..."}
				</p>
			</td>
			<td>{printBD(itemData.defenceBal())}</td>
			<td>
				{printBD(itemData.stealable())} {dataType == "cookie" ? "CKI" : "FDG"}
			</td>
			<td>{Number(itemData.winProb(adUsed)).toFixed(2)}%</td>
		</tr>
	);
}
