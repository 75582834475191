import { useEffect, useRef, useState } from "react";
import { attackIcon, eyeIcon, infoIcon, refreshIcon } from "../../../../Base/SVG";
import { Addresses } from "index";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";

import Search from "./components/Search";
import Slider from "react-slick";
import Single from "./components/Single";
import Table from "./components/Table";
import Spinbox from "./components/Spinbox";
import Attacking from "./components/Attacking";
import Locking from "./components/Locking";
import Opponent from "interfaces/Opponent";
import { useSelector } from "react-redux";
import {
	abbreviateNumber,
	BD,
	BN,
	getUnscaledBD,
	inputToBn,
	inputToBnScaled,
	printBD,
	printUnscaledBD,
	ZERO_BYTES_32,
} from "interfaces/Numbers";
import { getLeagueBoost } from "interfaces/Utils";
import NoOpponents from "./components/NoOpponents";
import { TxIDs } from "Base/Modals/ModalWrapper";

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";
export const leagueInfo = {
	55: {
		name: "Unranked",
		max: 0.5,
	},
	/*56: { name: "Iron IV", max: 0.8 },
	57: { name: "Iron III", max: 0.14 },
	58: { name: "Iron II", max: 0.28 },
	59: { name: "Iron I", max: 0.56 },*/
	60: {
		name: "Bronze IV",
		max: 1.15,
	},
	61: {
		name: "Bronze III",
		max: 2.31,
	},
	62: {
		name: "Bronze II",
		max: 4.61,
	},
	63: {
		name: "Bronze I",
		max: 9.22,
	},
	64: {
		name: "Silver IV",
		max: 18.5,
	},
	65: {
		name: "Silver III",
		max: 36.9,
	},
	66: {
		name: "Silver II",
		max: 73.8,
	},
	67: {
		name: "Silver I",
		max: 147.6,
	},
	68: {
		name: "Gold IV",
		max: 295,
	},
	69: {
		name: "Gold III",
		max: 590,
	},
	70: {
		name: "Gold II",
		max: 1180,
	},
	71: {
		name: "Gold I",
		max: 2360,
	},
	72: {
		name: "Platinum III",
		max: 4722,
	},
	73: {
		name: "Platinum II",
		max: 9444,
	},
	74: {
		name: "Platinum I",
		max: 18890,
	},
	75: {
		name: "Diamond III",
		max: 37778,
	},
	76: {
		name: "Diamond II",
		max: 75557,
	},
	77: {
		name: "Diamond I",
		max: 151115,
	},
	78: {
		name: "Master III",
		max: 302230,
	},
	79: {
		name: "Master II",
		max: 604462,
	},
	80: {
		name: "Master I",
		max: 1208925,
	},
	81: {
		name: "Challenger III",
		max: 2417581,
	},
	82: {
		name: "Challenger II",
		max: 4835703,
	},
	83: {
		name: "Challenger I",
		max: 9671406,
	},
	/*84: { name: "Whale III", max: 19342813 },
	85: { name: "Whale II", max: 38685626 },
	86: { name: "Whale I", max: 77371252 },
	87: { name: "Godlike III", max: 154742504 },
	88: { name: "Godlike II", max: 309485009 },
	89: { name: "Godlike I", max: 618970019 },*/
};

export function getLeagueName(league) {
	return leagueInfo.hasOwnProperty(league) ? leagueInfo[league].name : "Unranked";
}

export function getLeagueMax(league) {
	return leagueInfo.hasOwnProperty(league) ? leagueInfo[league].max : 1;
}

export default function Compete(props) {
	const {
		addTx,
		setAlert,
		setModal,
		step,
		setStep,
		soundEffects,

		account,
		signPermit,
		adObj,
		adBal,
		createAttack,
		pendingAttacks,
		setPendingAttacks,
		attackTokens,
		attackCount,
		newOpponents,
		ckiLockedRaw,
		ckiLockedStake,
		ckiLockedTotal,
		ckiLockedTotalEarned,
		ckiLock,
		ckiUnlock,
		ckiLockedClaim,
		ckiLockRev,
		ckiLeague,
		ckiLeagueSize,
		defence,

		adversaryVector,
		ckiOpponents,
		fdgOpponents,

		fdgLockedRaw,
		fdgLockedStake,
		fdgLockedTotal,
		fdgLockedTotalEarned,
		fdgLock,
		fdgUnlock,
		fdgLockedClaim,
		fdgLockRev,
		fdgLeague,
		fdgLeagueSize,

		targetLeague,
		setTargetLeague,

		setTxInfo,
		ckiLockingClaimableRT,
		fdgLockingClaimableRT,
		hasMinted,
	} = props;

	const [ckiLockYield, setCkiLockYield] = useState(null);
	const { dataType } = useSelector((state) => state.common);
	const [opponents, setOpponents] = useState(dataType == "cookie" ? ckiOpponents : fdgOpponents);

	useEffect(() => {
		if (ckiLockRev && ckiLockedTotal && ckiLockedStake) {
			setCkiLockYield((ckiLockedStake * ckiLockRev) / (BD(ckiLockedTotal) * 24));
		}
	}, [ckiLockedTotal, ckiLockedStake, ckiLockedRaw, ckiLockRev]);

	useEffect(() => {
		if (Number(adversaryVector) == 0 || opponents[0].address == NULL_ADDRESS) {
			if ((dataType == "cookie" ? Number(ckiLeagueSize) : Number(fdgLeagueSize)) == 0) {
				setStep("noOpponents");
			} else {
				setStep("needOpponents");
			}
		} else if (adversaryVector == 1) {
			setStep("search");
		} else if (adversaryVector == 2) {
			setStep("attack");
		} else {
			setStep("choose");
		}
	}, [opponents, step, adversaryVector, dataType]);

	const [fdgLockYield, setFdgLockYield] = useState(null);

	useEffect(() => {
		if (fdgLockRev && fdgLockedTotal && fdgLockedStake) {
			setFdgLockYield((fdgLockedStake * fdgLockRev) / (BD(fdgLockedTotal) * 24));
		}
	}, [fdgLockedTotal, fdgLockedStake, fdgLockedRaw, fdgLockRev]);

	const [type, setType] = useState("lock");
	const [tooltipHover, setTooltipHover] = useState(false);
	const [attackDough, setAttackDough] = useState(10);
	const [opponent, setOpponent] = useState(dataType == "cookie" ? opponents[0] : opponents[0]);
	const [previousLeague, setPreviousLeague] = useState(targetLeague);

	useEffect(() => {
		if (type == "compete") {
			setPreviousLeague(targetLeague);
		}
	}, [targetLeague, dataType]);

	useEffect(() => {
		if (targetLeague == 0 || targetLeague == 55) {
			setTargetLeague(dataType == "cookie" ? Number(ckiLeague) : Number(fdgLeague));
		}
	}, [ckiLeague, fdgLeague, dataType]);

	const [listView, setListView] = useState(false);
	const settings = {
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
	};

	const [avatar, setAvatar] = useState(null);
	useEffect(() => {
		setAvatar(createAvatar(avataaarsNeutral, { seed: opponent.address }));
	}, [opponent]);

	useEffect(() => {
		let opps = dataType == "cookie" ? ckiOpponents : fdgOpponents;

		if (opps[0].address == NULL_ADDRESS) {
			const storedOpps = localStorage.getItem(dataType + "opponents" + targetLeague);
			if (storedOpps && adversaryVector) {
				const parsed_opps = JSON.parse(storedOpps);
				let valid = opps.length == 8;
				for (let i = 0; i < 8; i++) {
					// console.log(i, parsed_opps[7 - i].address.substr(42 - 8, 8));
					// console.log(adversaryVector[0]._hex.substr(2 + i * 8, 8));
					if (
						parsed_opps[7 - i].address.substr(42 - 8, 8).toLowerCase() !=
						adversaryVector[0]._hex.substr(2 + i * 8, 8).toLowerCase()
					) {
						valid = false;
						break;
					}
				}
				if (valid) {
					const loadedOpps = [];
					for (let i = 0; i < 8; i++) {
						loadedOpps.push(new Opponent().loadJson(parsed_opps[i]));
					}
					setOpponents(loadedOpps);
					console.log("loaded opponents from local storage");
					return;
				} else {
					console.log("invalid opponents in local storage");
				}
			} else {
				console.log("no opponents in local storage");
			}
		} else {
			localStorage.setItem(dataType + "opponents" + targetLeague, JSON.stringify(opps));
			console.log("saved opponents to local storage");
		}
		setOpponents(opps);
	}, [dataType, ckiOpponents, fdgOpponents]);

	return (
		<>
			<div className="compete">
				<div className="compete__top">
					<div className="compete__top-title">
						{type === "compete" ? <h3>Compete for Yield</h3> : <h3>My Locking</h3>}
						{type === "compete" ? (
							<p>
								Put your cooking skills to the test in the arena. Earn rewards by
								defeating your opponents.
							</p>
						) : (
							<p>
								Lock {dataType == "cookie" ? "CKI " : "FDG "}
								tokens strategically to maximize yield and compete against other
								players in the arena to win even more{" "}
								{dataType == "cookie" ? "CKI " : "FDG "}
								tokens!
							</p>
						)}{" "}
					</div>
					<div className="theme">
						<div className="themeBtn">
							<input
								type="radio"
								name="yield"
								checked={type === "compete"}
								onChange={() => {
									soundEffects["click"].play();
									setTargetLeague(previousLeague);
									setType("compete");
								}}
							/>
							<div className="themeBtn__inner">
								<p>Competition</p>
							</div>
						</div>
						<div className="themeBtn">
							<input
								type="radio"
								name="yield"
								checked={type === "lock"}
								onChange={() => {
									setType("lock");
									soundEffects["click"].play();
									setStep("choose");
									// setPreviousLeague(targetLeague);
									setTargetLeague(
										dataType == "cookie" ? Number(ckiLeague) : Number(fdgLeague)
									);
								}}
							/>
							<div className="themeBtn__inner">
								<p>Locking</p>
							</div>
						</div>
					</div>
				</div>
				<div className="competeBody">
					<div className="competeBody__top">
						<div className="competeBody__slider-outer">
							<div className="competeBody__slider">
								{tooltipHover && (
									<div className="competeBody__slider-tooltip">
										<div className="competeBody__slider-tooltip-inner">
											Higher leagues get a boost on their yield!
										</div>
									</div>
								)}
								<div className="competeBody__slider-item">
									<div className="competeBody__slider-item-image">
										<img
											src={
												process.env.PUBLIC_URL + "/images/icons/trophy.svg"
											}
											alt=""
										/>
									</div>
									<div className="competeBody__slider-item-content">
										<h6>
											{" "}
											{
												/* {dataType == "cookie"
													? getLeagueName(ckiLeague)
													: getLeagueName(fdgLeague)} */
												getLeagueName(targetLeague)
											}{" "}
										</h6>
										<div className="competeBody__slider-item-content-text">
											{dataType == "cookie"
												? Number(ckiLeagueSize)
												: Number(fdgLeagueSize)}{" "}
											Players
										</div>
										<div className="competeBody__slider-item-content-row">
											<p>
												{" "}
												{"x" +
													// (dataType == "cookie"
													// ? printUnscaledBD(
													// getLeagueBoost(ckiLeague)
													// )
													// : printUnscaledBD(
													// getLeagueBoost(fdgLeague)
													// ))
													printUnscaledBD(
														getLeagueBoost(targetLeague)
													)}{" "}
											</p>
											<div
												className="tooltip"
												onMouseOver={() => setTooltipHover(true)}
												onMouseOut={() => setTooltipHover(false)}
											>
												<div className="tooltip__icon">{infoIcon}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{type === "compete" && (
								<button
									type="button"
									className="competeBody__slider-view"
									onClick={() => {
										soundEffects["click"].play();
										setModal("leagues");
									}}
								>
									Change League
								</button>
							)}
							{/* <button
								type="button"
								className="competeBody__slider-view"
								onClick={() => setModal("leagues")}
							>
								Change League
							</button> */}{" "}
						</div>
						{step === "choose" && type === "compete" && (
							<button
								type="button"
								className="competeBody__top-view"
								onClick={() => {
									soundEffects["click"].play();
									setListView(!listView);
								}}
							>
								{eyeIcon}
								{listView ? "Single view" : "List view"}{" "}
							</button>
						)}
						{type === "lock" && (
							<div className="competeBody__top-info">
								<h4>
									{" "}
									{printBD(
										dataType == "cookie" ? ckiLockYield : fdgLockYield
									)}{" "}
								</h4>
								<div className="competeBody__top-info-row">
									<h5>
										{" "}
										{dataType === "cookie" ? (
											<img
												src={process.env.PUBLIC_URL + "/images/logo.svg"}
												alt=""
											/>
										) : (
											<img
												src={process.env.PUBLIC_URL + "/images/fudge.svg"}
												alt=""
											/>
										)}
										<span>/ H</span>
									</h5>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Hourly yield from your locked{" "}
												{dataType == "cookie" ? "CKI" : "FDG"}.
											</div>
										</div>
									</div>
								</div>
							</div>
						)}{" "}
					</div>
					{type === "compete" ? (
						<>
							{" "}
							{step === "search" && <Search setStep={setStep} setModal={setModal} />}
							{step === "choose" && (
								<div className="competeBody__choose">
									{listView ? (
										<Table
											setOpponent={setOpponent}
											account={account}
											setListView={setListView}
											listOpponents={opponents}
											dataType={dataType}
											adUsed={attackDough}
										/>
									) : (
										<Single
											account={account}
											setOpponent={setOpponent}
											listOpponents={opponents}
											opponent={opponent}
											setListView={setListView}
											setStep={setStep}
											adUsed={attackDough}
											dataType={dataType}
										/>
									)}
									<div className="competeBody__choose-footer">
										<div className="competeBody__choose-footer-info">
											<p className="sm">Attack Dough</p>
											<div className="tooltip">
												<div className="tooltip__icon">{infoIcon}</div>
												<div className="tooltip__content">
													<div className="tooltip__content-inner">
														The amount of dough you use will define your
														odds of winning!
													</div>
												</div>
											</div>
										</div>
										<div className="competeBody__choose-footer-btns">
											<div className="competeBody__choose-row">
												<div className="competeBody__choose-input">
													<input
														type="number"
														value={attackDough}
														onChange={(e) => {
															if (
																parseInt(e.target.value, 10) <=
																getUnscaledBD(adBal).toFixed(0)
															) {
																setAttackDough(e.target.value);
															}
															if (
																isNaN(parseInt(e.target.value, 10))
															) {
																setAttackDough("");
															}
														}}
													/>
													<p>AD</p>
												</div>
												<button
													className="button secondary"
													onClick={() => {
														soundEffects["click"].play();
														setAttackDough(
															getUnscaledBD(adBal).toFixed(0)
														);
													}}
												>
													Max
												</button>
											</div>
											{listView ? (
												<button
													className="button primary big"
													onClick={async () => {
														soundEffects["search"].play();
														await searchOpponents(
															adObj,
															newOpponents,
															targetLeague,
															dataType == "cookie"
																? Addresses.CCLockingCKI
																: Addresses.CCLockingFDG,
															addTx,
															setModal,
															step,
															setStep,
															signPermit
														);
													}}
												>
													{refreshIcon}
													New Opponents&nbsp;
													<span
														style={{
															fontSize: "small",
															fontWeight: "normal",
														}}
													>
														{" "}
														(- 4 DGH)
													</span>
												</button>
											) : (
												<button
													className="button primary big svg24"
													onClick={async () => {
														if (attackDough < 2) {
															soundEffects["error"].play();
															setAlert(
																"You need at least 2 AD to launch an attack"
															);
															return;
														}
														if (opponent.address == account) {
															soundEffects["error"].play();
															setAlert("You cannot attack yourself");
															return;
														}
														const val = inputToBnScaled(attackDough);

														soundEffects["launchAttack"].play();

														if (adObj.approved() < val) {
															setTxInfo({
																action: "approve",
																description: "Approve spending",
																value: printBD(val),
																token: "AD",
															});
															setModal("transaction");

															await adObj.approve(
																Addresses.CCCompete,
																val
															);
														}

														const previousStep = step;

														addTx(
															TxIDs.ATTACK,
															"Competition",
															"Attacking " +
																opponent.address.substr(0, 10) +
																"...",
															"- " +
																abbreviateNumber(printBD(val)) +
																" DGH"
														);

														// setTxInfo({
														// 	action: "attack",
														// 	description: opponent.address,
														// 	value: printBD(val),
														// 	token: "AD",
														// 	avatar: avatar.toString(),
														// });
														// setModal("attack");

														try {
															await createAttack(
																opponent.id - 1,
																val,
																opponent.address,
																dataType == "cookie"
																	? Addresses.CCLockingCKI
																	: Addresses.CCLockingFDG,
																true,
																BN(targetLeague)
															);
															setPendingAttacks(
																new Set(
																	pendingAttacks.add(
																		attackCount + 1
																	)
																)
															);
															attackTokens[attackCount + 1] =
																dataType == "cookie"
																	? "CKI"
																	: "FDG";
														} catch (error) {
															console.error(error);
															setStep(previousStep);
														}

														// setStep("attack");
													}}
												>
													{attackIcon}
													Attack with {abbreviateNumber(attackDough)}
													dough
												</button>
											)}{" "}
										</div>
									</div>
								</div>
							)}
							{step === "attack" && (
								<Attacking attackDough={attackDough} opponent={opponent} />
							)}
							{step === "needOpponents" && (
								<NoOpponents
									account={account}
									setAlert={setAlert}
									league={targetLeague}
									adObj={adObj}
									adBal={adBal}
									newOpponents={newOpponents}
									setModal={setModal}
									addTx={addTx}
									setStep={setStep}
									step={step}
									dataType={dataType}
									signPermit={signPermit}
									soundEffects={soundEffects}
								/>
							)}
							{step === "noOpponents" && (
								<div className="competeBody__noplayers">
									<div className="competeBody__noplayers-title">
										Insufficient Players
									</div>
									<div className="competeBody__noplayers-image">
										<img
											className="swim2"
											src={
												process.env.PUBLIC_URL +
												"/images/noplayers-avatar.png"
											}
											alt=""
										/>
									</div>
									<div className="competeBody__noplayers-content">
										<p>
											Please wait for other players to join or choose another
											league.
										</p>
									</div>
								</div>
							)}{" "}
						</>
					) : (
						<Locking
							soundEffects={soundEffects}
							setModal={setModal}
							addTx={addTx}
							raw={dataType == "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType == "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType == "cookie" ? ckiLockedTotal : fdgLockedTotal}
							totalEarned={
								dataType == "cookie" ? ckiLockedTotalEarned : fdgLockedTotalEarned
							}
							claimable={
								dataType == "cookie" ? ckiLockingClaimableRT : fdgLockingClaimableRT
							}
							lock={dataType == "cookie" ? ckiLock : fdgLock}
							unlock={dataType == "cookie" ? ckiUnlock : fdgUnlock}
							claim={dataType == "cookie" ? ckiLockedClaim : fdgLockedClaim}
							rev={dataType == "cookie" ? ckiLockRev : fdgLockRev}
							league={dataType == "cookie" ? ckiLeague : fdgLeague}
							setTxInfo={setTxInfo}
							defence={defence}
							account={account}
							setAlert={setAlert}
							hasMinted={hasMinted}
						/>
					)}{" "}
				</div>
			</div>
		</>
	);
}

export async function searchOpponents(
	adObj,
	newOpponents,
	league,
	pool,
	addTx,
	setModal,
	step,
	setStep,
	signPermit
) {
	const val = inputToBnScaled(4);

	const previousStep = step;

	addTx(TxIDs.SEARCH, "League " + getLeagueName(league), "Searching new opponnents", "4 DGH");
	// setTxInfo({
	// 	action: "search",
	// 	description: "Search opponents",
	// 	value: printBD(val),
	// 	token: "AD",
	// });
	// setModal("transaction");

	try {
		await newOpponents(pool, BN(league));
	} catch (error) {
		console.error(error);
		setStep(previousStep);
	}
}
