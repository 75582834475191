import {Contract, BigNumber, utils} from "ethers";
import CCStaking from "../abis/CCStaking.json";
import {TypedFilter, useBlockNumber, useCall, useContractFunction, useLogs} from "@usedapp/core";
import {TransactionReceipt} from "@ethersproject/abstract-provider";
var bigdecimal = require("bigdecimal");

class StakingPool {
    private userState : any;
	private globalState : any;
    private claimable : any;

    private changeStakes : any;
    private claims : any;

    public stakeState : any;;
    public claimState : any;;
    public unstakeState : any;;

    public stake : (amount : BigNumber, deadline : BigNumber, v : BigNumber, r : string, s : string) => Promise<TransactionReceipt | undefined>;
    public unstake : (amount : BigNumber) => Promise<TransactionReceipt | undefined>;
    public claim : () => Promise<TransactionReceipt | undefined>;

    public ownStake() {
        const ret = this.userState && this.userState.ownStake ? bigdecimal.BigDecimal(this.userState.ownStake.toString()) : bigdecimal.BigDecimal(0);
        return ret;
    }

	public totalStake() {
		const ret = this.globalState && this.globalState.totalStake ? bigdecimal.BigDecimal(this.globalState.totalStake.toString()) : bigdecimal.BigDecimal(0);
        return ret;
    }

    public nowClaimable() {
        return this.claimable;
    }

    public totalEarned() {
        const ret = this.userState && this.userState.alreadyClaimedTotal ? bigdecimal.BigDecimal(this.userState.alreadyClaimedTotal.toString()) : bigdecimal.BigDecimal(0);
        return ret;
    }

    public changeStakeEvents() {
        return this.changeStakes;
    }

    public claimEvents() {
        return this.claims;
    }

    constructor(userState : any, globalState: any, stake : any, claimable : any, stakeState : any, unstake : any, unstakeState : any, claim : any, claimState : any, changeStakes : any, claims : any) {
        this.userState = userState;
		this.globalState = globalState;
        this.stake = stake;
        this.claimable = claimable;
        this.stakeState = stakeState;
        this.unstake = unstake;
        this.unstakeState = unstakeState;
        this.claim = claim;
        this.claimState = claimState;
        this.changeStakes = changeStakes;
        this.claims = claims;
    }
}

export function CreateStakingPool(address : string, account : string | undefined) {
    const contract = new Contract(address, new utils.Interface(CCStaking));

    const {value, error} = useCall(account && {
        contract: contract,
        method: "usersState",
        args: [account]
    }) ?? {};
    if (error) {
        console.error(error.message);
    }
    const userState = value;

	const {value: globalState, error: err1} = useCall({
        contract: contract,
        method: "globalState",
        args: []
    }) ?? {};
    if (err1) {
        console.error(err1.message);
    }

    const {value: claimableValue, error: err2} = useCall(account && {
        contract: contract,
        method: "claimable",
        args: [account]
    }) ?? {};
    if (err2) {
        console.error(err2.message);
    }

    const {state: stakeState, send: sendStake} = useContractFunction(contract, "stake", {
        transactionName: "stake",
        gasLimitBufferPercentage: 10
    });

    const stake = (amount : BigNumber, deadline : BigNumber, v : BigNumber, r : string, s : string) => {
        return sendStake(amount, deadline, v, r, s);
    };

    const {state: unstakeState, send: sendUnstake} = useContractFunction(contract, "unstake", {
        transactionName: "unstake",
        gasLimitBufferPercentage: 10
    });

    const unstake = (amount : BigNumber) => {
        return sendUnstake(amount);
    };

    const {state: claimState, send: sendClaim} = useContractFunction(contract, "claim", {
        transactionName: "claim",
        gasLimitBufferPercentage: 10
    });

    const claim = () => {
        return sendClaim();
    };

    const changeStakesFilter: TypedFilter = {
        event: "ChangeStake",
        args: [],
        contract: contract
    };

    const claimsFilter: TypedFilter = {
        event: "Claim",
        args: [],
        contract: contract
    };

    const currentBlockNumber = useBlockNumber();
    const fromBlock = currentBlockNumber ? currentBlockNumber - 900 : "latest"; // TODO(alexis): Change for mainnet.
    
    // const changeStakes = useLogs(changeStakesFilter, {fromBlock});
    // const claims = useLogs(claimsFilter, {fromBlock});

    return new StakingPool(userState, globalState, stake, claimableValue, stakeState, unstake, unstakeState, claim, claimState, undefined, undefined);
}
