import React from "react";
import Progress from "./Progress";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";
import { abbreviateNumber } from "interfaces/Numbers";

export default function Attacking({ attackDough, opponent }) {
	const avatar = createAvatar(avataaarsNeutral, {
		seed: opponent.address,
	});
	return (
		<div className="competeBody__attack">
			<div className="competeBody__attack-image">
				<div className="competeBody__attack-image-inner">
					<svg dangerouslySetInnerHTML={{ __html: avatar.toString() }} />
				</div>
			</div>
			<div className="competeBody__attack-info">
				<span>{opponent?.address}</span>
			</div>
			<div className="competeBody__attack-box">
				<div className="competeBody__attack-box-image">
					<img src={process.env.PUBLIC_URL + "images/icons/attack_el.png"} alt="" />
				</div>
				<div className="competeBody__attack-box-content">
					<h6 className="sm">Attacking with</h6>
					<h4>{abbreviateNumber(attackDough)} AD</h4>
				</div>
			</div>

			<Progress title1="Battle sent" title2={"End of battle"} />
		</div>
	);
}
