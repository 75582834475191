import React, { useEffect } from "react";
import { closeIcon, roundArrowIcon } from "../../SVG";

export default function Transaction2({ setModal, txInfo, approveState, searchState }) {
	useEffect(() => {
		let state = approveState;
		if (txInfo["action"] == "search") {
			state = searchState;
		}

		if (state["status"] == "PendingSignature") {
			setModal("transaction");
		} else if (state["status"] == "Mining") {
			setModal("transaction2");
		} else if (state["status"] == "Success") {
			setModal("transaction3");
		} else if (state["status"] == "Error") {
			setModal("transactionLose");
		} else if (state["status"] == "Exception") {
			setModal("transactionLose");
		}
	}, [txInfo, approveState, searchState]);

	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<h5>Transaction Sent</h5>
				<div className="modalAttack__item big">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-icon">{roundArrowIcon}</div>
						<div className="modalAttack__item-content">
							<h6>{txInfo.description}</h6>
							<p className="big">
								<b>Competition</b>
								<img
									src={process.env.PUBLIC_URL + "/images/icons/cupIcon.svg"}
									alt=""
								/>
							</p>
						</div>
					</div>
					<div className="modalAttack__item-price">-{txInfo.value} AD</div>
				</div>
				<div className="modalAttack__inner-image sm">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/mixing.png"}
						alt=""
					/>
				</div>
				<div className="modalAttack__back sm">
					<p className="sm">Mixing dough...</p>
				</div>
			</div>
		</div>
	);
}
