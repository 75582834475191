import React, { useEffect } from "react";
import { shareIcon } from "../../SVG";

export default function AttackVictory({ setModal, txInfo }) {
	const tweet =
		"I just won " +
		txInfo.value +
		txInfo.token +
		" on a battle on CryptoCookies! 🍪🚀 Thank you @Web3Cookies ;)";

	var tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}`;
	return (
		<div className="modalAttack victory">
			<div className="modalAttack__inner">
				<h5 className="big">Sweet Victory!</h5>

				<div className="modalAttack__inner-image big">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/victory-cookie.svg"}
						alt=""
					/>
				</div>
				<button
					className="modalAttack__share"
					onClick={() => window.open(tweetUrl, "_blank")}
				>
					{shareIcon}
					<b>Share with friends</b>
				</button>
				<div className="modalAttack__item light">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-avatar">
							<svg dangerouslySetInnerHTML={{ __html: txInfo.avatar }} />
						</div>
						<div className="modalAttack__item-content">
							<h6 className="sm">Opponent</h6>
							<p>{txInfo.description.substring(0, 10)}...</p>
						</div>
					</div>
					<button className="modalAttack__item-btn" onClick={() => setModal(null)}>
						+{txInfo.value} {txInfo.token}
					</button>
				</div>
				<div className="modalAttack__foot light">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				<div className="modalAttack__inner-callback light">
					<p>
						View on <a href="">Etherscan</a>{" "}
					</p>
				</div>
			</div>
		</div>
	);
}
