import DoghChart from "../../Base/DoghChart";
import {polRight} from "../../Base/SVG";
import {printBD} from "interfaces/Numbers";

export default function Balance(props) {
    const {
        balFdg,
        balCki,
        cookieList,
        fudgeList,
        mint,
		setAlert,
		account,
		soundEffects,
        balance
    } = props;
    return (
        <div className="layoutStaking__balance">
            <div className="layoutStaking__balance-item">
                <div className="layoutStaking__balance-item-title">
                    <div className="layoutStaking__balance-item-title-info">
                        <h5>Cookie Balance</h5>
                        <div className="layoutStaking__balance-item-title-info-row">
                            <img src={
                                    process.env.PUBLIC_URL + "images/logo.svg"
                                }
                                alt=""/>
                            <h6 className="big">
                                {
                                printBD(balCki)
                            }</h6>
                        </div>
                    </div>
                    {
                    (account === undefined ||  (Number(balCki) === 0) && (Number(balFdg) === 0)) && (
                        <button className="button secondary"
                            onClick={
                                () => {
									if (account === undefined) {
										soundEffects["error"].play();
										setAlert("Please connect your wallet.");
										return;
									}
                                    if (Number(balance) < 1e15) {
                                        soundEffects["error"].play();
                                        setAlert("You need at least 0.001 ETH to create an account.");
                                        return;
                                    }
									soundEffects["claim"].play();
                                    mint();
                                }
                        }>
                            Mint CKI & FDG
                        </button>
                    )
                } </div>
                <div className="layoutStaking__balance-item-content">
                    <div className="layoutStaking__balance-item-chart">
                        <DoghChart data={
                                cookieList.map((item) => item.percent)
                            }
                            bgColors={
                                cookieList.map((item) => item.color)
                            }
                            percentCircle="62.35%"/>
                    </div>
                    <div className="layoutStaking__balance-item-data">
                        <div className="layoutStaking__balance-item-data-icon">
                            {polRight}</div>
                        {
                        cookieList.map((item, index) => {
                            return (
                                <div className="layoutStaking__balance-item-data-item"
                                    key={index}>
                                    <div className="layoutStaking__balance-item-data-item-icon"
                                        style={
                                            {background: item.color}
                                    }></div>
                                    {
                                    item.title + " "
                                }
                                    <span>{
                                        item.percent
                                    }%</span>
                                </div>
                            );
                        })
                    } </div>
                </div>
            </div>
            <div className="layoutStaking__balance-item">
                <div className="layoutStaking__balance-item-title">
                    <div className="layoutStaking__balance-item-title-info">
                        <h5>Fudge Balance</h5>
                        <div className="layoutStaking__balance-item-title-info-row">
                            <img src={
                                    process.env.PUBLIC_URL + "images/fudge.svg"
                                }
                                alt=""/>
                            <h6 className="big">
                                {
                                printBD(balFdg)
                            }</h6>
                        </div>
                    </div>
                    {/*<button className="button secondary">More FDG {plusIcon2}</button>*/} </div>
                <div className="layoutStaking__balance-item-content">
                    <div className="layoutStaking__balance-item-chart">
                        <DoghChart data={
                                fudgeList.map((item) => item.percent)
                            }
                            bgColors={
                                fudgeList.map((item) => item.color)
                            }
                            percentCircle="62.35%"/>
                    </div>
                    <div className="layoutStaking__balance-item-data">
                        <div className="layoutStaking__balance-item-data-icon">
                            {polRight}</div>
                        {
                        fudgeList.map((item, index) => {
                            return (
                                <div className="layoutStaking__balance-item-data-item"
                                    key={index}>
                                    <div className="layoutStaking__balance-item-data-item-icon"
                                        style={
                                            {background: item.color}
                                    }></div>
                                    {
                                    item.title + " "
                                }
                                    <span>{
                                        item.percent
                                    }%</span>
                                </div>
                            );
                        })
                    } </div>
                </div>
            </div>
        </div>
    );
}
