import { Contract, BigNumber, utils } from "ethers";
import CCLocking from "../abis/CCLocking.json";
import { TypedFilter, useCall, useBlockNumber, useContractFunction, useLogs } from "@usedapp/core";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { NULL_ADDRESS } from "./Numbers";
var bigdecimal = require("bigdecimal");

class LockingPool {
	private userState: any;
	private globalState: any;
	private yieldStake: any;
	private opponents: any;
	private changeLocks: any;
	private claims: any;
	private claimable: any;
	private leagueSizeData: any;

	public lockState: any;
	public claimState: any;
	public unlockState: any;

	public lock: (
		amount: BigNumber,
		deadline: BigNumber,
		v: BigNumber,
		r: string,
		s: string
	) => Promise<TransactionReceipt | undefined>;
	public unlock: (amount: BigNumber) => Promise<TransactionReceipt | undefined>;
	public claim: () => Promise<TransactionReceipt | undefined>;
	public updateBoost: (account: string) => Promise<TransactionReceipt | undefined>;

	public ownStake() {
		const ret = this.userState
			? bigdecimal.BigDecimal(this.userState.ownStake.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public totalStake() {
		const ret = this.globalState
			? bigdecimal.BigDecimal(this.globalState.totalStake.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public nowClaimable() {
		return this.claimable;
	}

	public totalEarned() {
		const ret =
			this.userState && this.userState.alreadyClaimedTotal
				? bigdecimal.BigDecimal(this.userState.alreadyClaimedTotal.toString())
				: bigdecimal.BigDecimal(0);
		return ret;
	}

	public rawStake() {
		const ret = this.yieldStake
			? bigdecimal.BigDecimal(this.yieldStake.stake.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public league() {
		const ret = this.yieldStake
			? bigdecimal.BigDecimal(this.yieldStake.league.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public leagueSize() {
		const ret = this.leagueSizeData
			? bigdecimal.BigDecimal(this.leagueSizeData.toString())
			: bigdecimal.BigDecimal(0);
		return ret;
	}

	public leagueBoost() {
		const ret = this.yieldStake
			? bigdecimal.BigDecimal(this.yieldStake.leagueBoost.toString())
			: bigdecimal.BigDecimal(0);
		return parseInt(ret.toString());
	}

	public adversaries(league: any) {
		// console.log("HERE1");
		// console.log(this.opponents);

		if (this.opponents && this.opponents.value?.length > 0) {
			// console.log("HERE");
			for (let i = this.opponents.value.length - 1; i >= 0; i--) {
				const data = this.opponents.value[i].data;
				// console.log(data);
				// console.log(data.league);
				// console.log(league);
				if (data.league == league) {
					// console.log("SUCCESS");
					return [data.o1, data.o2, data.o3, data.o4, data.o5, data.o6, data.o7, data.o8];
				}
			}
		}
		return [
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
			NULL_ADDRESS,
		];
	}

	// public getLeagueSize(league: any) {}

	public claimEvents() {
		return this.claims;
	}

	public changeLocksEvents() {
		return this.changeLocks;
	}

	constructor(
		userState: any,
		globalState: any,
		claimable: any,
		yieldStake: any,
		lock: any,
		lockState: any,
		unlock: any,
		unlockState: any,
		claim: any,
		claimState: any,
		updateBoost: any,
		changeLocks: any,
		claims: any,
		opponents: any,
		leagueSize: any
	) {
		this.userState = userState;
		this.globalState = globalState;
		this.claimable = claimable;
		this.yieldStake = yieldStake;
		this.lock = lock;
		this.lockState = lockState;
		this.unlock = unlock;
		this.unlockState = unlockState;
		this.claim = claim;
		this.claimState = claimState;
		this.updateBoost = updateBoost;
		this.changeLocks = changeLocks;
		this.claims = claims;
		this.opponents = opponents;
		this.leagueSizeData = leagueSize;
	}
}

export function CreateLockingPool(
	address: string,
	account: string | undefined,
	lockingRev: any,
	league: any
) {
	const contract = new Contract(address, new utils.Interface(CCLocking));

	const { value, error } =
		useCall(
			account && {
				contract: contract,
				method: "usersState",
				args: [account],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	const userState = value;

	const { value: globalState, error: err1 } =
		useCall({
			contract: contract,
			method: "globalState",
			args: [],
		}) ?? {};
	if (err1) {
		console.error(err1.message);
	}

	const { value: leagueSize, error: err9 } =
		useCall({
			contract: contract,
			method: "getLeagueSize",
			args: [league],
		}) ?? {};
	if (err9) {
		console.error(err9);
	}

	const { value: claimableValue, error: err } =
		useCall(
			account && {
				contract: contract,
				method: "claimable",
				args: [account],
			}
		) ?? {};
	if (err) {
		console.error(err.message);
	}

	const { value: yVal, error: err2 } =
		useCall(
			account && {
				contract: contract,
				method: "yieldStakes",
				args: [account],
			}
		) ?? {};
	if (err2) {
		console.error(err2.message);
	}
	const yieldStake = yVal;

	const { state: lockState, send: sendLock } = useContractFunction(contract, "stake", {
		transactionName: "stake",
		gasLimitBufferPercentage: 10,
	});

	const lock = (amount: BigNumber, deadline: BigNumber, v: BigNumber, r: string, s: string) => {
		return sendLock(amount, deadline, v, r, s);
	};

	const { state: unlockState, send: sendUnlock } = useContractFunction(contract, "unstake", {
		transactionName: "unstake",
		gasLimitBufferPercentage: 10,
	});

	const unlock = (amount: BigNumber) => {
		return sendUnlock(amount);
	};

	const { state: claimState, send: sendClaim } = useContractFunction(contract, "claim", {
		transactionName: "claim",
		gasLimitBufferPercentage: 10,
	});

	const claim = () => {
		return sendClaim();
	};

	const { send: sendUpdateBoost } = useContractFunction(contract, "updatePoolStrength", {
		transactionName: "updatePoolStrength",
		gasLimitBufferPercentage: 10,
	});

	const updateBoost = (addr: string) => {
		return sendUpdateBoost(addr);
	};

	const changeLocksFilter: TypedFilter = {
		event: "ChangeLock",
		args: [],
		contract: contract,
	};
	const claimsFilter: TypedFilter = {
		event: "Claim",
		args: [],
		contract: contract,
	};

	const opponentFilter: TypedFilter = {
		event: "NewOpponents",
		args: [account, null],
		contract: contract,
	};

	const currentBlockNumber = useBlockNumber();
	const fromBlock = "latest";

	// const changeLocks = useLogs(changeLocksFilter, { fromBlock });
	// const claims = useLogs(claimsFilter, { fromBlock });
	// const opponents = useLogs(opponentFilter, { fromBlock });

	return new LockingPool(
		userState,
		globalState,
		claimableValue,
		yieldStake,
		lock,
		lockState,
		unlock,
		unlockState,
		claim,
		claimState,
		updateBoost,
		undefined,
		undefined,
		undefined,
		leagueSize
	);
}
