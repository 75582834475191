import { motion, AnimatePresence } from "framer-motion";
import { printBD, printUnscaledBD } from "interfaces/Numbers";
import React, { useEffect, useRef, useState } from "react";

export default function Socials(props) {
	const { soundEffects } = props;
	const [active, setActive] = useState(false);
	const wrapper = useRef(null);

	useEffect(() => {
		const windowClick = ({ target }) => {
			if (wrapper.current && !wrapper.current.contains(target)) setActive(false);
		};

		if (active) window.addEventListener("click", windowClick);
		return () => window.removeEventListener("click", windowClick);
	}, [active]);

	return (
		<div className={"socialLinksWrapper"} ref={wrapper}>
			<button
				type="button"
				className="header__inner-side-info"
				style={{ marginLeft: "15px" }}
				onClick={() => {
					soundEffects["click"].play();
					setActive(!active);
				}}
			>
				<img
					className="notifBtn__icon"
					src={process.env.PUBLIC_URL + "/images/icons/links.png"}
					alt="Links icon"
				/>
			</button>
			<AnimatePresence>
				{active && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="socialDrop"
						style={{
							position: "absolute",
							backgroundColor: "#fff",
							padding: "10px",
							borderRadius: "5px",
							transform: "translateX(-10px)",
							WebkitBoxSizing: "border-box",
							boxSizing: "border-box",
							WebkitBoxShadow: "0px 4px 8px 0px rgba(45, 35, 25, 0.12)",
							boxShadow: "0px 4px 8px 0px rgba(45, 35, 25, 0.12)",
						}}
					>
						<a
							href="https://twitter.com/Web3Cookies"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={process.env.PUBLIC_URL + "/images/icons/twitter.png"}
								alt="Twitter"
								style={{ width: "30px", margin: "5px" }}
							/>
						</a>
						<a
							href="https://discord.gg/VEH6wMwZ"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img
								src={process.env.PUBLIC_URL + "/images/icons/discord.png"}
								alt="Discord"
								style={{ width: "30px", margin: "5px" }}
							/>
						</a>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
