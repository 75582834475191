import React, { useEffect } from "react";
import { closeIcon } from "../../SVG";

export default function AttackFail({ setModal, attackState, txInfo }) {
	useEffect(() => {
		if (attackState["status"] == "PendingSignature") {
			setModal("attack");
		} else if (attackState["status"] == "Mining") {
			setModal("attack2");
		} else if (attackState["status"] == "Success") {
			setModal("attack3");
		} else if (attackState["status"] == "Error") {
			setModal("attackFail");
		} else if (attackState["status"] == "Exception") {
			setModal("attackFail");
		}
	}, [txInfo, attackState]);

	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>

				<h5>Transaction Failed</h5>
				<div className="modalAttack__inner-tick">
					<img src={process.env.PUBLIC_URL + "/images/icons/cross.png"} alt="" />
				</div>
				<div className="modalAttack__item last">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-avatar">
							<svg dangerouslySetInnerHTML={{ __html: txInfo.avatar }} />
						</div>
						<div className="modalAttack__item-content">
							<h6 className="sm">Opponent</h6>
							<p>{txInfo.description.substring(0, 10)}...</p>
						</div>
					</div>
					<div className="modalAttack__item-price">-0 AD</div>
				</div>
				<div className="modalAttack__foot">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				<div className="modalAttack__inner-callback dark">
					<p>
						View on <a href="">Etherscan</a>{" "}
					</p>
				</div>
			</div>
		</div>
	);
}
