import React from "react";
import { closeIcon } from "../../SVG";

export default function GeneralInfo(props) {
	const { title, content, setModal } = props;
	return (
		<div className="modalGeneral">
			<div className="modalGeneral__inner">
				<div className="modalGeneral__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalGeneral__inner-icon">
					<img src={process.env.PUBLIC_URL + "images/icons/attention.png"} alt="" />
				</div>
				<div className="modalGeneral__inner-content">
					<h4>{title}</h4>
					<p>{content}</p>
				</div>
			</div>
		</div>
	);
}
