import { getLeagueMax, getLeagueName } from "Pages/Home/components/Compete/Compete";
import { SeasonDurationDays, SeasonStart } from "index";
import { printBD } from "interfaces/Numbers";
import { useState, useEffect } from "react";
import Slider from "react-slick";

export default function LayoutCard({ type, league, total, onClick, token }) {
	const settings = {
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		speed: 600,
		fade: true,
	};
	const [tooltipHover1, setTooltipHover1] = useState(false);
	const [tooltipHover2, setTooltipHover2] = useState(false);

	const [progress, setProgress] = useState((Date.now() - SeasonStart.getTime()) / (SeasonDurationDays * 24 * 60 * 60 * 1000) * 100);

    useEffect(() => {
        const updateTimestamp = () => {
			setProgress((Date.now() - SeasonStart.getTime()) / (SeasonDurationDays * 24 * 60 * 60 * 1000) * 100);
        };

        const intervalId = setInterval(updateTimestamp, 5000);

        return () => clearInterval(intervalId);
    }, []);

	return (
		<div className="layoutCard">
			{tooltipHover1 && (
				<div className="layoutCard__tooltip">
					<div className="layoutCard__tooltip-inner">
						Obtain more CKI to reach the next league for better rewards.
					</div>
				</div>
			)}
			{tooltipHover2 && (
				<div className="layoutCard__tooltip">
					<div className="layoutCard__tooltip-inner">
						Gather as much CKI and FDG as possible before the season ends!
					</div>
				</div>
			)}
			{type === "board" && (
				<div className="layoutCard__item">
					<div className="layoutCard__icon">
						<img className="swim2" src="./images/icons/cup.png" alt="" />
					</div>
					<div className="layoutCard__content">
						<h5>{getLeagueName(league)}</h5>
						<p>
							{printBD(total).split(".")[0]} /{" "}
							{Math.ceil(getLeagueMax(league)).toLocaleString("en-US")} {token}
							<span
								onMouseOver={() => setTooltipHover1(true)}
								onMouseOut={() => setTooltipHover1(false)}
							>
								<img src="./images/icons/tooltip.svg" alt="" />
							</span>
						</p>
						<button className="button" onClick={onClick}>
							View Global Leaderboard
						</button>
					</div>
				</div>
			)}
			{type === "season" && (
				<>
					<Slider {...settings}>
						<div className="layoutCard__item blue">
							<div className="layoutCard__icon">
								<img className="swim2" src="./images/icons/calendar.png" alt="" />
							</div>
							<div className="layoutCard__content">
								<h5>Beta Season</h5>
								<p>
									{(progress <= 0) ? "Not yet started" : (progress >= 100) ? "Ended" : "In progress"}
									<span
										onMouseOver={() => setTooltipHover2(true)}
										onMouseOut={() => setTooltipHover2(false)}
									>
										<img src="./images/icons/tooltip.svg" alt="" />
									</span>
								</p>
								<div className="progress">
									<span style={{ maxWidth: `${progress}%` }}></span>
									<p style={{ position: 'relative', zIndex: 1, margin: 0}}>{progress.toFixed(2)}%</p>
								</div>
							</div>
						</div>
					</Slider>
				</>
			)}
		</div>
	);
}
