import CCDistr from "../abis/CCDistr.json";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { Contract, BigNumber, utils, ethers } from "ethers";
import { useCall, useContractFunction } from "@usedapp/core";

class Distr {
    private mintState : any;
    private mintableState : any;
    private hasMintedState : any;

	public hasMinted() {
		const ret = this.hasMintedState !== undefined ? this.hasMintedState[0] : undefined;
		return ret;
	}

    public mint : () => Promise<TransactionReceipt | undefined>;

    constructor(mint : any, mintState : any, mintableState : any, hasMintedState : any) {
        this.mint = mint;
        this.mintState = mintState;
        this.mintableState = mintableState;
        this.hasMintedState = hasMintedState;
    }
}

export function CreateCCDistr(address: string, account: string | undefined) {
	const contract = new Contract(address, new utils.Interface(CCDistr));

	const { state: mintState, send: sendMint } = useContractFunction(contract, "mint", {
		transactionName: "mint",
		gasLimitBufferPercentage: 10,
	});

	const mint = () => {
		return sendMint({ value: ethers.utils.parseEther("0.001") });
	};

	const { value: mintableState } =
		useCall(
			account && {
				contract: contract,
				method: "mintable",
				args: [account],
			}
		) ?? {};

	const { value: hasMintedState } =
		useCall(
			account && {
				contract: contract,
				method: "hasMinted",
				args: [account],
			}
		) ?? {};

	return new Distr(mint, mintState, mintableState, hasMintedState);
}
