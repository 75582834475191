import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDataType } from "../store/commonSlice";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Balance from "./Balance";
import Notif from "./Notif";
import { Chainlabs } from "../";
import { questionIcon } from "./SVG";
import Sound from "react-sound";
import Socials from "./Socials";
import { BN } from "interfaces/Numbers";

export default function Header(props) {
	const {
		activateBrowserWallet,
		soundEffects,
		attackEvents,
		account,
		deactivate,
		switchNetwork,
		chainId,
		balCki,
		balFdg,
		ckiList,
		addTx,
		fdgList,
		fdgLockedClaimable,
		fdgStakedClaimable,
		ckiLockedClaimable,
		ckiStakedClaimable,
		claimAll,
		setModal,
		hasMinted,
	} = props;

	const [menu, setMenu] = useState(false);
	const [musicEnabled, setMusicEnabled] = useState("PAUSED");
	const location = useLocation();

	const switchCheck1 = useRef(null);
	const switchCheck2 = useRef(null);
	const { dataType } = useSelector((state) => state.common);
	const navigate = useNavigate();
	const [theme, setTheme] = useState("cookie");
	const dispatch = useDispatch();

	useEffect(() => {
		if (hasMinted === false) {
			setModal("tutorial");
		}
	}, [hasMinted]);

	useEffect(() => {
		window.scrollTo(0, 0);
		setMenu(false);
		document.body.classList.remove("active");
	}, [location]);
	useEffect(() => {
		if (menu) {
			document.body.classList.add("active");
		} else {
			document.body.classList.remove("active");
		}
	}, [menu]);
	const close = (e) => {
		if (e.target === e.currentTarget) setMenu(false);
	};
	useEffect(() => {
		if (switchCheck1.current && switchCheck2.current) {
			const storageThemeState = localStorage.getItem("theme");
			if (storageThemeState) {
				document.documentElement.setAttribute("data-theme", storageThemeState);
				setTheme(storageThemeState);
				dispatch(setDataType(storageThemeState));
			} else {
				document.documentElement.setAttribute("data-theme", "cookie");
				localStorage.setItem("theme", "cookie");
				setTheme("cookie");
				dispatch(setDataType("cookie"));
			}
		}
	}, [switchCheck1, switchCheck2]);

	const switchTheme = (type) => {
		document.documentElement.setAttribute("data-theme", type);
		localStorage.setItem("theme", type);
		setTheme(type);
		soundEffects["switchPage"].play();
		dispatch(setDataType(type));
		navigate("/");
	};
	const clickHandler = () => {
		document.documentElement.setAttribute("data-theme", "staking");
		localStorage.setItem("theme", "staking");
		setTheme("staking");
		soundEffects["openStaking"].play();
		dispatch(setDataType("staking"));
		navigate("/staking");
	};

	const clickHandler2 = () => {
		document.documentElement.setAttribute("data-theme", "cookie");
		localStorage.setItem("theme", "cookie");
		setTheme("cookie");
		dispatch(setDataType("cookie"));
		navigate("/");
	};

	return (
		<header className="header" id="header">
			<div className="auto__container">
				<div className="header__inner">
					<div className="header__inner-side">
						<Link to="/" onClick={clickHandler2} className="header__inner-logo">
							<img src="./images/logo.svg" alt="" />
						</Link>
						<div className={"nav " + (menu ? "active" : "")} id="menu" onClick={close}>
							<div className="nav__inner">
								<div className="theme">
									<div className="themeBtn">
										<input
											ref={switchCheck1}
											type="radio"
											name="theme"
											checked={theme === "cookie"}
											onChange={() => switchTheme("cookie")}
										/>
										<div className="themeBtn__inner">
											<span>
												<img src="./images/logo.svg" alt="" />
											</span>
											<p>Cookies</p>
										</div>
									</div>
									<div className="themeBtn">
										<input
											ref={switchCheck2}
											type="radio"
											name="theme"
											checked={theme === "fudge"}
											onChange={() => switchTheme("fudge")}
										/>
										<div className="themeBtn__inner">
											<span>
												<img src="./images/fudge.svg" alt="" />
											</span>
											<p>Fudge</p>
										</div>
									</div>
								</div>
								<NavLink
									to="/staking"
									onClick={clickHandler}
									className="header__inner-link"
								>
									Staking
								</NavLink>
							</div>
						</div>
					</div>

					<div className="header__inner-side">
						<Notif
							addTx={addTx}
							fdgLockedClaimable={fdgLockedClaimable}
							fdgStakedClaimable={fdgStakedClaimable}
							ckiLockedClaimable={ckiLockedClaimable}
							ckiStakedClaimable={ckiStakedClaimable}
							claimAll={claimAll}
							attackEvents={attackEvents}
							account={account}
							soundEffects={soundEffects}
						/>{" "}
						{dataType !== "staking" && (
							<Balance
								bal={theme === "fudge" ? balFdg : balCki}
								balanceList={theme === "fudge" ? fdgList : ckiList}
								soundEffects={soundEffects}
							/>
						)}
						<button
							type="button"
							className="header__inner-side-info"
							onClick={() => {
								soundEffects["click"].play();
								setModal("tutorial");
							}}
						>
							{/*questionIcon*/}?
						</button>
						<Socials soundEffects={soundEffects} />
						<button
							type="button"
							className="header__inner-side-info"
							style={{ marginLeft: "15px" }}
							onClick={() => {
								if (musicEnabled === "PLAYING") {
									setMusicEnabled("PAUSED");
								} else {
									setMusicEnabled("PLAYING");
								}
							}}
						>
							<img
								className="notifBtn__icon"
								src={
									process.env.PUBLIC_URL +
									(musicEnabled === "PLAYING"
										? "images/volume-full-line-icon.svg"
										: "images/volume-silent-line-icon.svg")
								}
								alt=""
							/>
						</button>
						<hr />
						<div
							className="account"
							onClick={async () => {
								soundEffects["click"].play();
								if (!account) {
									await activateBrowserWallet();
									if (chainId != Chainlabs.chainId) {
										switchNetwork(Chainlabs.chainId);
									}
								} else deactivate();
							}}
						>
							<div className="account__icon">
								<img src="./images/icons/metamask.svg" alt="" />
							</div>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									lineHeight: "1.2",
									alignItems: "center",
								}}
							>
								<a href="#" style={{ marginBottom: "0px" }}>
									{account ? account.substring(0, 10) + "..." : "Connect"}
									<p
										style={{
											marginTop: "0px",
											color: "grey",
											fontSize: "11px",
											WebkitTransition: "0.3s ase",
										}}
									>
										v0.1.51
									</p>
								</a>
							</div>
						</div>
						<div
							className={"burger " + (menu ? "active " : "")}
							id="menuBtn"
							onClick={() => {
								soundEffects["click"].play();
								setMenu(!menu);
							}}
						>
							<span></span>
						</div>
					</div>
				</div>
				<Sound
					url={"/audio/background-open-book.mp3"}
					playStatus={musicEnabled}
					volume={8}
					loop={true}
				/>
			</div>
		</header>
	);
}
