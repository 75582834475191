import { Contract, BigNumber, utils } from "ethers";
import CCCompete from "../abis/CCCompete.json";
import { useCall, useBlockNumber, useLogs, useContractFunction, TypedFilter } from "@usedapp/core";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { BD, ZERO } from "./Numbers";

class Compete {
	private userState: any;
	private defense: any;
	private ap: any;
	private AP_PER_DAY = BD(24);
	private changeDefences: any;
	private changeADGens: any;
	private advVector: any;

	private attackLogs: any;

	public setDefenseState: any;
	public setApGenState: any;
	public attackState: any;
	public searchState: any;

	public setDefense: (
		amount: BigNumber,
		deadline: BigNumber,
		v: BigNumber,
		r: string,
		s: string
	) => Promise<TransactionReceipt | undefined>;

	public setApGen: (
		amount: BigNumber,
		deadline: BigNumber,
		v: BigNumber,
		r: string,
		s: string
	) => Promise<TransactionReceipt | undefined>;

	public drawOpponents: (
		pool: string,
		league: BigNumber,
	) => Promise<TransactionReceipt | undefined>;

	public createAttack: (
		index: BigNumber,
		ap: BigNumber,
		opponent: string,
		pool: string,
		redraw: boolean,
		league: BigNumber
	) => Promise<TransactionReceipt | undefined>;

	public defenseBal() {
		const ret = this.defense ? BD(this.defense.toString()) : ZERO;
		return ret;
	}

	public adBal() {
		return this.ap;
	}

	public adversaryVector() {
		return this.advVector;
	}

	public apPerDay() {
		const ret = this.ap ? BD(this.ckiBlockedAp()).multiply(this.AP_PER_DAY) : ZERO;
		return ret;
	}

	public ckiBlockedAp() {
		const ret = this.userState ? BD(this.userState.attackCki.toString()) : ZERO;
		return ret;
	}

	public attackEvents() {
		return this.attackLogs;
		// if (this.attackLogs && this.attackLogs.value.length > 0) {
		// 	return this.attackLogs.value.map((value: { data: any }) => value.data);
		// } else {
		// 	return [];
		// }
	}

	public changeDefenceEvents() {
		return this.changeDefences;
	}

	public changeADGenEvents() {
		return this.changeADGens;
	}

	public attackCount() {
		// console.log(this.userState);
		return this.userState?.attackCount;
	}

	constructor(
		userState: any,
		defense: any,
		ap: any,
		setDefense: any,
		setDefenseState: any,
		setApGen: any,
		setApGenState: any,
		drawOpponents: any,
		searchState: any,
		createAttack: any,
		attackState: any,
		attackLogs: any,
		changeDefences: any,
		changeADGens: any,
		advVector: any
	) {
		this.userState = userState;
		this.defense = defense;
		this.ap = ap;
		this.setDefense = setDefense;
		this.setDefenseState = setDefenseState;
		this.setApGen = setApGen;
		this.setApGenState = setApGenState;
		this.drawOpponents = drawOpponents;
		this.searchState = searchState;
		this.createAttack = createAttack;
		this.attackState = attackState;
		this.attackLogs = attackLogs;
		this.changeDefences = changeDefences;
		this.changeADGens = changeADGens;
		this.advVector = advVector;
	}
}

export function CreateCompete(address: any, account: string, league: any, pool: any) {
	const contract = new Contract(address, new utils.Interface(CCCompete));

	const { value: def, error: err } =
		useCall(
			account && {
				contract: contract,
				method: "defenseBalances",
				args: [account],
			}
		) ?? {};

	if (err) {
		console.error(err.message);
	}
	const defense = def?.[0];

	// const { value: sub, error: err3 } =
	// 	useCall(
	// 		account && {
	// 			contract: contract,
	// 			method: "s_subscriptionId",
	// 			args: [],
	// 		}
	// 	) ?? {};
	// // s_subscriptionId
	// console.log(sub?.[0]);

	const { value: attUserState, error } =
		useCall(
			account && {
				contract: contract,
				method: "attackUserStates",
				args: [account],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	const userState = attUserState;

	const { value: advVector, error: err9 } =
		useCall(
			account && {
				contract: contract,
				method: "getOpponents",
				args: [account, pool, league],
			}
		) ?? {};
	if (err9) {
		console.error(err9.message);
	}

	const { value: apVal, error: err2 } =
		useCall(
			account && {
				contract: contract,
				method: "dghBalanceOf",
				args: [account],
			}
		) ?? {};
	if (err2) {
		console.error(err2.message);
	}
	const ap = apVal;

	const { send: sendSetDef, state: defState } = useContractFunction(
		contract,
		"setDefensePoints",
		{
			transactionName: "setDefensePoints",
			gasLimitBufferPercentage: 10,
		}
	);

	const setDefense = (
		amount: BigNumber,
		deadline: BigNumber,
		v: BigNumber,
		r: string,
		s: string
	) => {
		return sendSetDef(amount, deadline, v, r, s);
	};

	const { send: sendSetAp, state: setApState } = useContractFunction(
		contract,
		"setDghGeneration",
		{
			transactionName: "setDghGeneration",
			gasLimitBufferPercentage: 10,
		}
	);

	const setApGen = (
		amount: BigNumber,
		deadline: BigNumber,
		v: BigNumber,
		r: string,
		s: string
	) => {
		return sendSetAp(amount, deadline, v, r, s);
	};

	const { send: sendDrawOpponents, state: searchState } = useContractFunction(
		contract,
		"getNewSeed",
		{
			transactionName: "getNewSeed",
			gasLimitBufferPercentage: 10,
		}
	);

	const drawOpponents = (
		pool: string,
		league: BigNumber,
	) => {
		return sendDrawOpponents(pool, league);
	};

	const { send: sendCreateAttack, state: attackState } = useContractFunction(
		contract,
		"createAttack",
		{
			transactionName: "createAttack",
			gasLimitBufferPercentage: 10,
		}
	);

	const createAttack = (
		index: BigNumber,
		ap: BigNumber,
		opponent: string,
		pool: string,
		redraw: boolean,
		league: BigNumber
	) => {
		return sendCreateAttack(index, ap, opponent, pool, redraw, league);
	};

	const currentBlockNumber = useBlockNumber();
	const fromBlock = currentBlockNumber ? currentBlockNumber - 900 : "latest";

	const logFilter: TypedFilter = {
		event: "AttackOutcome",
		args: [],
		contract: contract,
	};

	const changeDefencesFilter: TypedFilter = {
		event: "ChangeDefence",
		args: [],
		contract: contract,
	};

	const changeADGensFilter: TypedFilter = {
		event: "ChangeADGen",
		args: [],
		contract: contract,
	};

	// const attackLogs = useLogs(logFilter, { fromBlock });
	// const changeDefences = useLogs(changeDefencesFilter, { fromBlock });
	// const changeADGens = useLogs(changeADGensFilter, { fromBlock });

	return new Compete(
		userState,
		defense,
		ap,
		setDefense,
		defState,
		setApGen,
		setApState,
		drawOpponents,
		searchState,
		createAttack,
		attackState,
		undefined,
		undefined,
		undefined,
		advVector
	);
}
