import React from "react";
import Progress from "./Progress";
import {refreshIcon} from "Base/SVG";
import {BN, ZERO_BYTES_32, inputToBnScaled} from "interfaces/Numbers";
import {Addresses} from "index";
import {searchOpponents} from "../Compete";

export default function NoOpponents({
    league,
    account,
    adObj,
    adBal,
    newOpponents,
    addTx,
    setModal,
    setStep,
    step,
    dataType,
    setAlert,
    signPermit,
    soundEffects
}) {
    return (
        <div className="competeBody__noplayers">
            <div className="competeBody__noplayers-title">Find Your Opponent!</div>
            <div className="competeBody__noplayers-image">
                <img className="swim2"
                    src={
                        process.env.PUBLIC_URL + "/images/dice.png"
                    }
                    alt=""/>
            </div>
            <div className="competeBody__noplayers-content">
                <p>
                    Draw 8 random opponents in this league.
                </p>
            </div>
            <button className="button primary noOpponents"
                onClick={
                    async () => {
                        if (account === undefined) {
                            soundEffects["error"].play();
                            setAlert("Please connect your wallet.");
                            return;
                        }

						if (Number(adBal) < (4 * 1e18)) {
                            soundEffects["error"].play();
                            setAlert("You have less than 4 DGH.");
                            return;
                        }

                        soundEffects["search"].play(); //TODO(alexis) musique trop épique 
                        await searchOpponents(adObj, newOpponents, league, dataType == "cookie" ? Addresses.CCLockingCKI : Addresses.CCLockingFDG, addTx, setModal, step, setStep, signPermit);
                    }
            }>
                {refreshIcon}
                Search Opponents &nbsp;
                <span style={
                    {
                        fontSize: "small",
                        fontWeight: "normal"
                    }
                }>
                    (4 DGH)</span>
            </button>
        </div>
    );
}
