import GCKI from "../abis/GCki.json";
import { Contract, BigNumber, utils } from "ethers";
import { TypedFilter, useBlockNumber, useCall, useContractFunction, useLogs } from "@usedapp/core";
import { TransactionReceipt } from "@ethersproject/abstract-provider";
import { BD, ZERO } from "./Numbers";

class GCki {
	private balance: any;
	private escrow: any;
	private changeGCki: any;
	private withdraws: any;

	public gCkiChangeState : any;

	public withdraw: () => Promise<TransactionReceipt | undefined>;

	public bal() {
		const ret = this.balance ? BD(this.balance.toString()) : ZERO;
		return ret;
	}

	public getDeposit() {
		if (this.escrow && this.escrow["cki"]) {
			return BD(this.escrow["cki"]);
		}
		return ZERO;
	}

	public getEnd() {
		if (this.escrow && this.escrow["end"]) {
			return BD(this.escrow["end"]);
		}
		return ZERO;
	}

	public withdrawEvents() {
		return this.withdraws;
	}

	public changeGCkiEvents() {
		return this.changeGCki;
	}

	constructor(balance: any, escrow: any, withdraw: any, changeGCki: any, withdraws: any) {
		this.balance = balance;
		this.escrow = escrow;
		this.withdraw = withdraw;
		this.changeGCki = changeGCki;
		this.withdraws = withdraws;
	}
}

export function CreateGCki(address: string, account: string | undefined) {
	const contract = new Contract(address, new utils.Interface(GCKI));

	const { value: valBal, error: err } =
		useCall(
			account && {
				contract: contract,
				method: "balanceOf",
				args: [account],
			}
		) ?? {};
	if (err) {
		console.error(err.message);
	}
	const balance = valBal?.[0];

	const { value: escrVal, error } =
		useCall(
			account && {
				contract: contract,
				method: "usersEscrow",
				args: [account],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	const escrow = escrVal;

	const { send: sendWithdraw } = useContractFunction(contract, "withdraw", {
		transactionName: "withdraw",
		gasLimitBufferPercentage: 10,
	});

	const changeGCkiFilter: TypedFilter = {
		event: "ChangeGCki",
		args: [],
		contract: contract
	};

	const withdrawsFilter: TypedFilter = {
		event: "WithdrawCki",
		args: [],
		contract: contract
	};

	const withdraw = () => {
		return sendWithdraw();
	};

	const currentBlockNumber = useBlockNumber();
	const fromBlock = "latest";

	// const changeGCki = useLogs(changeGCkiFilter, {fromBlock});
	// const withdraws = useLogs(withdrawsFilter, {fromBlock});

	return new GCki(balance, escrow, withdraw, undefined, undefined);
}
