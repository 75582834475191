import { useCall } from "@usedapp/core";
import { BD, ZERO, getUnscaledBD, printBD } from "./Numbers";
import CCCompete from "../abis/CCCompete.json";
import CCLocking from "../abis/CCLocking.json";
import { Contract, utils } from "ethers";

export default class Opponent {
	private defence;
	private stakeState;
	private ratioVal;

	private loaded: boolean;
	private defenceStr;
	private stealableStr;

	public address;
	public id = 1;

	public stealable() {
		if (this.loaded) {
			return BD(this.stealableStr);
		}
		const stake = this.stakeState ? BD(this.stakeState.stake.toString()) : ZERO;
		const ratio = this.ratioVal ? BD(this.ratioVal.toString()) : BD("1");

		return stake.divide(ratio);
	}

	public defenceBal() {
		if (this.loaded) {
			return BD(this.defenceStr);
		}
		const ret = this.defence ? BD(this.defence.toString()) : ZERO;
		return ret;
	}

	public winProb(ad: any) {
		ad = Math.max(ad - 2, 0);
		let dp = this.loaded ? getUnscaledBD(this.defenceStr) : getUnscaledBD(this.defenceBal());
		let x = ad / (ad + dp);
		let tanh2 = Math.tanh(2);

		const temp = x * 4 - 2;
		// console.log(Math.tanh(temp));
		x = Math.tanh(temp) + tanh2;
		x = x / 2;
		return x * 100;
	}

	public toJSON() {
		const j = {
			address: this.address,
			id: this.id,
			defence: this.defenceBal().toString(),
			stealable: this.stealable().toString(),
		};

		return j;
	}

	public loadJson(json: any) {
		// console.log("loading", json);
		this.defenceStr = json.defence;
		this.stealableStr = json.stealable;
		this.loaded = true;
		this.address = json.address;
		this.id = json.id;
		return this;
	}

	constructor(defence?: any, ratioVal?: any, stakeState?: any, address?: string, id?: any) {
		this.defence = defence;
		this.ratioVal = ratioVal;
		this.stakeState = stakeState;
		this.address = address;
		this.id = id;
		this.loaded = false;
	}
}

// function tanh(x) {
// 	console.log(Math.exp(x));
// 	return (Math.exp(x) - Math.exp(-x)) / (Math.exp(x) + Math.exp(-x));
// }

export function CreateAllOpponents(
	lockingAddr: string,
	competeAddr: string,
	users: string[],
	account: string
) {
	const lockingContract = new Contract(lockingAddr, new utils.Interface(CCLocking));
	const competeContract = new Contract(competeAddr, new utils.Interface(CCCompete));

	const Opponents: Opponent[] = [];
	let i = 1;
	for (let user of users) {
		Opponents.push(CreateOpponent(lockingContract, competeContract, user, account, i));
		i++;
	}

	return Opponents;
}

function CreateOpponent(
	lockingContract: Contract,
	competeContract: Contract,
	user: string,
	account: string,
	id: any
) {
	const { value: def, error: err } =
		useCall(
			account && {
				contract: competeContract,
				method: "defenseBalances",
				args: [user],
			}
		) ?? {};
	if (err) {
		console.error(err.message);
	}
	const defence = def?.[0];

	const { value: ratioVal, error } =
		useCall(
			account && {
				contract: competeContract,
				method: "CAPTURE_RATIO",
				args: [],
			}
		) ?? {};
	if (error) {
		console.error(error.message);
	}
	const captureRatio = ratioVal;

	const { value: yVal, error: err2 } =
		useCall(
			account && {
				contract: lockingContract,
				method: "yieldStakes",
				args: [user],
			}
		) ?? {};
	if (err2) {
		console.error(err2.message);
	}
	const stakeState = yVal;

	return new Opponent(defence, captureRatio, stakeState, user, id);
}
