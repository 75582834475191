import React, { useEffect, useMemo } from "react";
import ModalSmall from "./ModalSmall";

export const TxState = {
	SUCCESS: "success",
	FAILURE: "failure",
	LOADING: "loading",
	SIGNING: "signing",
};

export const TxIDs = {
	CLAIM_CKI_LOCK: 0,
	CLAIM_FDG_LOCK: 1,
	CLAIM_CKI_STAKING: 2,
	CLAIM_FDG_STAKING: 3,
	SET_DEFENCE: 4,
	SET_DOUGH: 5,
	ATTACK: 6,
	CLAIM_ALL: 7,
	SET_GCKI: 8,
	CLAIM_GCKI: 9,
	MINT: 10,
	LOCK_CKI: 11,
	UNLOCK_CKI: 19,
	LOCK_FDG: 12,
	UNLOCK_FDG: 18,
	STAKE_CKI: 13,
	UNSTAKE_CKI: 14,
	STAKE_FDG: 15,
	UNSTAKE_FDG: 16,
	SEARCH: 17,
};

export default function ModalWrapper(props) {
	const {
		txs,
		setTxs,
		removeTx,
		lockingCki,
		lockingFdg,
		stakingCki,
		stakingFdg,
		compete,
		utils,
		ccDistr,
	} = props;

	const states = useMemo(
		() => ({
			[TxIDs.CLAIM_CKI_LOCK]: lockingCki.claimState,
			[TxIDs.CLAIM_FDG_LOCK]: lockingFdg.claimState,
			[TxIDs.CLAIM_CKI_STAKING]: stakingFdg.claimState,
			[TxIDs.CLAIM_FDG_STAKING]: stakingCki.claimState,
			[TxIDs.SET_DEFENCE]: compete.setDefenseState,
			[TxIDs.SET_DOUGH]: compete.setApGenState,
			[TxIDs.ATTACK]: compete.attackState,
			[TxIDs.CLAIM_ALL]: utils.claimAllState,
			[TxIDs.MINT]: ccDistr.mintState,
			[TxIDs.SET_GCKI]: utils.gCkiChangeState,
			[TxIDs.LOCK_CKI]: lockingCki.lockState,
			[TxIDs.LOCK_FDG]: lockingFdg.lockState,
			[TxIDs.STAKE_CKI]: stakingCki.stakeState,
			[TxIDs.STAKE_FDG]: stakingFdg.stakeState,
			[TxIDs.SEARCH]: compete.searchState,
			[TxIDs.UNLOCK_CKI]: lockingCki.unlockState,
			[TxIDs.UNLOCK_FDG]: lockingFdg.unlockState,
			[TxIDs.UNSTAKE_CKI]: stakingCki.unstakeState,
			[TxIDs.UNSTAKE_FDG]: stakingFdg.unstakeState,
		}),
		[
			lockingCki.claimState,
			lockingFdg.claimState,
			stakingCki.claimState,
			stakingFdg.claimState,
			compete.setDefenseState,
			compete.setApGenState,
			compete.attackState,
			utils.claimAllState,
			utils.gCkiChangeState,
			ccDistr.mintState,
			lockingCki.lockState,
			lockingCki.unlockState,
			lockingFdg.lockState,
			lockingFdg.unlockState,
			stakingCki.stakeState,
			stakingCki.unstakeState,
			stakingFdg.stakeState,
			stakingFdg.unstakeState,
			compete.searchState,
		]
	);

	useEffect(() => {
		setTxs((prevTxs) =>
			prevTxs.map((tx) => {
				if (tx.initialized) {
					if (states[tx.id]["status"] === "Mining") {
						return { ...tx, state: TxState.LOADING };
					}
					if (states[tx.id]["status"] === "Success") {
						return { ...tx, state: TxState.SUCCESS };
					}
					if (
						states[tx.id]["status"] === "Error" ||
						states[tx.id]["status"] === "Exception"
					) {
						return { ...tx, state: TxState.FAILURE };
					}
					return tx;
				} else if (
					!tx.initialized &&
					states[tx.id] &&
					(states[tx.id]["status"] == "PendingSignature" ||
						states[tx.id]["status"] == "Mining")
				) {
					return { ...tx, initialized: true };
				}
				return tx;
			})
		);
	}, [states]);

	return (
		<div className="MiniModalWrapper">
			{txs.map((modal) => (
				<ModalSmall key={modal.id} modal={modal} state={modal.state} close={removeTx} />
			))}
		</div>
	);
}
