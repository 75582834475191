import React, { useEffect } from "react";
import { closeIcon, infoIcon } from "../../SVG";

export default function Attack3({ setModal, attackState, txInfo }) {
	useEffect(() => {
		if (attackState["status"] == "PendingSignature") {
			setModal("attack");
		} else if (attackState["status"] == "Mining") {
			setModal("attack2");
		} else if (attackState["status"] == "Success") {
			setModal("attack3");
		} else if (attackState["status"] == "Error") {
			setModal("attackFail");
		} else if (attackState["status"] == "Exception") {
			setModal("attackFail");
		}
	}, [txInfo, attackState]);
	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<h5>Battle Confirmed</h5>
				<div className="modalAttack__item">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-avatar">
							<svg dangerouslySetInnerHTML={{ __html: txInfo.avatar }} />
						</div>
						<div className="modalAttack__item-content">
							<h6 className="sm">Opponent</h6>
							<p>{txInfo.description.substring(0, 10)}...</p>
						</div>
					</div>
					<div className="modalAttack__item-price">-{txInfo.value} AD</div>
				</div>
				<div className="modalAttack__inner-image">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/mixing.png"}
						alt=""
					/>
				</div>
				<div className="modalAttack__foot">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				<div className="modalAttack__inner-callback">
					<p>Waiting for attack result</p>{" "}
				</div>
			</div>
		</div>
	);
}
