import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export default function DoghChart(props) {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    elements: {},
  };

  const labels = props.labels;
  const newData = [...props.data];
  if(props.data.reduce((sum, element) => sum + element, 0) == 0) {
    newData[0] = 100;
  }

  const data = {
    type: "doughnut",
    labels,
    datasets: [
      {
        data: newData,
        backgroundColor: props.bgColors,
        borderWidth: 0,
        cutout: props.percentCircle,
      },
    ],
  };
  return <Doughnut options={options} data={data} />;
}
