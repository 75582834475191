import React, { useEffect } from "react";
import { closeIcon } from "../../SVG";

export default function AttackLose({ setModal, txInfo }) {
	useEffect(() => {
		setTimeout(() => {
			setModal(null);
		}, 3000);
	}, []);
	return (
		<div className="modalAttack lose">
			<div className="modalAttack__inner">
				<div className="modalAttack__inner-close bg" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<h5 className="big">Better luck next time...</h5>

				<div className="modalAttack__inner-image sm">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/black-cookies.png"}
						alt=""
					/>
				</div>
				<div className="modalAttack__inner-text">
					<p>Nobody likes burnt cookies... </p>
				</div>
				<div className="modalAttack__item light">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-avatar">
							<svg dangerouslySetInnerHTML={{ __html: txInfo.avatar }} />
						</div>
						<div className="modalAttack__item-content">
							<h6 className="sm">Opponent</h6>
							<p>{txInfo.description.substring(0, 10)}...</p>
						</div>
					</div>
					<button className="modalAttack__item-btn red" onClick={() => setModal(null)}>
						+{txInfo.value} {txInfo.token}
					</button>
				</div>
				<div className="modalAttack__foot light">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				<div className="modalAttack__inner-callback light">
					<p>
						View on <a href="">Etherscan</a>{" "}
					</p>
				</div>
			</div>
		</div>
	);
}
