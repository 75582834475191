import React from "react";
import { closeIcon } from "../SVG";
import { TxIDs } from "./ModalWrapper";

export default function ClaimAll(props) {
	const { title, content, setModal, addTx, user, claimAll, soundEffects, setShowWelcome } = props;
	return (
		<div className="modalClaimAll">
			<div className="modalClaimAll__inner">
				<div
					className="modalClaimAll__inner-close"
					onClick={() => {
						setModal(null);
					}}
				>
					{closeIcon}
				</div>
				<div className="modalClaimAll__inner-icon">
					<img src={process.env.PUBLIC_URL + "images/icons/clock.png"} alt="" />
				</div>
				<div className="modalClaimAll__inner-content">
					<h4>{title}</h4>
					{content}
					<button
						className="button secondary"
						onClick={async () => {
							setModal(null);
							soundEffects["claim"].play();
							addTx(TxIDs.CLAIM_ALL, "Claiming rewards", "Claiming All", "");
							await claimAll(user);
						}}
					>
						Claim All
					</button>
				</div>
			</div>
		</div>
	);
}
