import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dataType: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDataType(state, action) {
      state.dataType = action.payload;
    },
  },
});

export const { setThemeType, setDataType } = commonSlice.actions;

export default commonSlice.reducer;
