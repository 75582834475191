import { useState, useEffect } from "react";
import { closeIcon, infoIcon, plusIcon2 } from "../../SVG";
import DoghChart from "../../DoghChart";
import {
	BD,
	BN,
	ZERO,
	ZERO_BYTES_32,
	getUnscaledBD,
	inputToBnScaled,
	printBD,
} from "interfaces/Numbers";
import { Addresses } from "index";
import { computeRatio } from "App";
import { TxIDs } from "../ModalWrapper";

export function GetVulnerability(defence, locked) {
	const def = Number(defence);
	const lock = Number(locked);
	let vuln;
	if (lock === 0) {
		vuln = 1;
	} else if (def === 0) {
		vuln = 10;
	} else {
		const ratio = def / lock;

		// Use logarithmic scaling to calculate a base value
		if (ratio >= 2) {
			// Ratio is high, leaning towards 1
			vuln = 1;
		} else if (ratio <= 0.1) {
			// Ratio is low, leaning towards 10
			vuln = 10;
		} else {
			// Calculate logarithmically between 1 and 10 based on the ratio
			// Logarithmic calculation adjusted to fit the specified values:
			// ln(2) maps to 1, ln(1) maps to 5, ln(0.1) maps to 10
			const scale = Math.log(ratio);
			vuln = 5 + (scale / -2.302585) * 5;
		}
		// vuln *= 5;
		vuln = Math.max(1, Math.min(10, vuln));
		vuln = parseFloat(vuln.toPrecision(2)).toString(); // removes redundants 0
	}

	const scale = vuln / 6;
	const red = 255 * Math.pow(scale, 2);
	const green = 255 * (1 - Math.pow(scale, 2));
	const color = `rgb(${Math.round(red)}, ${Math.round(green)}, 0)`;

	return { vulnerability: vuln, color: color };
}

export default function SetDefence(props) {
	const {
		account,
		setAlert,
		cookieList,
		balCki,
		addTx,
		ckiUnused,
		setDefence,
		defence,
		ckiLocked,
		fdgLocked,
		setModal,
		signPermit,
		setTxInfo,
		withdrawing,
		soundEffects,
		adding,
	} = props;
	const [balance, setBalance] = useState(1000);
	const [value, setValue] = useState(0);
	const [updatedCookieList, setUpdatedCookieList] = useState([...cookieList]);

	const [vulnerabilityCki, setVulnerabilityCki] = useState(GetVulnerability(defence, ckiLocked));
	const [vulnerabilityFdg, setVulnerabilityFdg] = useState(GetVulnerability(defence, fdgLocked));

	const [newDef, setNewDef] = useState(defence);
	useEffect(() => {
		if (value !== undefined && value !== "" && !isNaN(Number(value))) {
			const updatedDef = adding
				? Number(defence) + Number(value * 1e18)
				: Number(defence) - Number(value * 1e18);
			setNewDef(updatedDef);
			setVulnerabilityCki(GetVulnerability(updatedDef, ckiLocked));
			setVulnerabilityFdg(GetVulnerability(updatedDef, fdgLocked));
		} else {
			setNewDef(defence);
			setVulnerabilityCki(GetVulnerability(defence, ckiLocked));
			setVulnerabilityFdg(GetVulnerability(defence, fdgLocked));
		}
	}, [defence, value, ckiLocked, fdgLocked]);

	useEffect(() => {
		setBalance(Math.floor(getUnscaledBD(withdrawing ? defence : ckiUnused)));
	}, [ckiUnused]);

	useEffect(() => {
		setValue(Math.abs(Math.floor(parseFloat(value.toString()))));
	}, [value]);

	useEffect(() => {
		setUpdatedCookieList(cookieList);
		if (value && value !== "" && !isNaN(Number(value)) && balCki.compareTo(ZERO) !== 0) {
			const tot = cookieList.reduce((sum, item) => BD(sum).add(BD(item.bal)), BD(0));
			const newValue = BD(value)
				.multiply(BD(10 ** 18))
				.toBigInteger();

			const newCookieList = [
				{
					...updatedCookieList[0],
					percent: withdrawing
						? computeRatio(BD(BN(cookieList[0].bal).add(BN(newValue))), tot)
						: computeRatio(BD(BN(cookieList[0].bal).sub(BN(newValue))), tot),
				},
				{
					...cookieList[1],
				},
				{
					...cookieList[2],
				},
				{
					...cookieList[3],
				},
				{
					...cookieList[4],
				},
				{
					...updatedCookieList[5],
					percent: withdrawing
						? computeRatio(BD(BN(cookieList[5].bal).sub(BN(newValue))), tot)
						: computeRatio(BD(BN(cookieList[5].bal).add(BN(newValue))), tot),
				},
			];

			setUpdatedCookieList(newCookieList);
		}
	}, [value, cookieList, balCki]);

	return (
		<div className="modalStake">
			<div className="modalStake__inner">
				<div className="modalStake__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalStake__inner-title">
					<h4>{adding ? "Improve your Defence" : "Withdraw from Defence"}</h4>
				</div>
				<div className="modalStake__inner-desc">
					<p>
						{adding
							? "Invest your unused CKI in Defence to alleviate the impact of enemy attacks on your locking positions."
							: "Withdraw CKI from Defence without any constraint or fee."}
					</p>
				</div>

				<div className="modalStake__content">
					<div className="modalStake__left">
						<div className="modalStake__left-func">
							<div className="modalStake__left-title">
								<p className="sm">
									{adding ? "Available Balance:" : "Current Defence:"}
								</p>
								<div className="modalStake__left-title-row">
									<p className="sm">
										{!withdrawing ? printBD(ckiUnused) : printBD(defence)}
									</p>
									{
										<img
											src={process.env.PUBLIC_URL + "images/logo.svg"}
											alt=""
										/>
									}
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(value) ? value : ""}
										onChange={(e) => {
											if ((e.nativeEvent.data !== " ") && e.target.value <= balance) {
												setValue(e.target.value);
											}
										}}
									/>
									<p>{"CKI"}</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setValue(balance);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span style={{ width: `${(value * 100) / balance}%` }}></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 0 ? "active" : "")
									}
								>
									<button type="button" onClick={() => setValue(0)}></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(Math.floor(balance * 0.25))}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(Math.floor(balance * 0.5))}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(Math.floor(balance * 0.75))}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 99.9 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-box mb45">
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>Defence Balance</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(newDef)}</p>
									<img
										src={process.env.PUBLIC_URL + "images/logo.svg"}
										alt=""
										style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
									/>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>CKI</p>
									<div className="modalStake__left-box-info">
										<p className="sm">Vulnerability:</p>
										<div className="tooltip">
											<div className="tooltip__icon">{infoIcon}</div>
											<div className="tooltip__content">
												<div className="tooltip__content-inner">
													Vulnerability of your locked CKI tokens in the
													face of attacks.
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<p style={{ color: vulnerabilityCki.color }}>
										{vulnerabilityCki.vulnerability}
									</p>
									<img
										src={process.env.PUBLIC_URL + "images/icons/shield.svg"}
										alt=""
									/>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>FDG</p>
									<div className="modalStake__left-box-info">
										<p className="sm">Vulnerability:</p>
										<div className="tooltip">
											<div className="tooltip__icon">{infoIcon}</div>
											<div className="tooltip__content">
												<div className="tooltip__content-inner">
													Vulnerability of your locked FDG tokens in the
													face of attacks.
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<p style={{ color: vulnerabilityFdg.color }}>
										{vulnerabilityFdg.vulnerability}
									</p>
									<img
										src={process.env.PUBLIC_URL + "images/icons/shield.svg"}
										alt=""
									/>
								</div>
							</div>
						</div>
						<div
							className="button primary sm"
							onClick={async () => {
								if (account === undefined) {
									soundEffects["error"].play();
									setAlert("Please connect your wallet.");
									return;
								}
								
								if (value === undefined || isNaN(value)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number.");
									return;
								}

								if (value === 0) {
									soundEffects["error"].play();
									setAlert("Please enter a non-zero amount.");
									return;
								}

								const val = withdrawing
									? defence.subtract(BD(inputToBnScaled(value)))
									: defence.add(BD(inputToBnScaled(value)));

								if (withdrawing) {
									soundEffects["decrease"].play();
								} else {
									soundEffects["coin"].play();
								}

								const permit = withdrawing
									? {
											deadline: 0,
											v: 0,
											r: ZERO_BYTES_32,
											s: ZERO_BYTES_32,
									  }
									: await signPermit(
											Addresses.CKI,
											Addresses.CCCompete,
											inputToBnScaled(value).toString()
									  );

								const tx = setDefence(
									BN(val),
									permit.deadline,
									permit.v,
									permit.r,
									permit.s
								);

								addTx(
									TxIDs.SET_DEFENCE,
									"Changing Defence",
									(withdrawing ? "Removing " : "Adding ") +
										value.toLocaleString("en-US") +
										" DP",
									""
								);
								setModal(null);
							}}
						>
							{withdrawing ? "Withdraw" : "Add"} Defence
						</div>
					</div>
					<div className="modalStake__right">
						<div className="modalStake__right-title">
							<h5 className="sm">{"CKI"} Balance</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Your new CKI balance and distribution strategy.
									</div>
								</div>
							</div>
						</div>
						<div className="modalStake__right-inner">
							<div className="modalStake__right-chart">
								<div className="modalStake__right-chart-bal">
									{<img src="./images/logo.svg" alt="" />}
									{printBD(balCki)}
								</div>
								<DoghChart
									data={updatedCookieList.map((item) => item.percent)}
									bgColors={updatedCookieList.map((item) => item.color)}
									percentCircle="75%"
								/>
							</div>
							<div className="modalStake__right-data">
								{updatedCookieList.map((item, index) => {
									return (
										<div className="modalStake__right-data-item" key={index}>
											<div
												className="modalStake__right-data-item-icon"
												style={{ background: item.color }}
											></div>
											{item.title} <span>{item.percent}%</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
