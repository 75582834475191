import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Header from "./Base/Header";
import Staking from "./Pages/Staking/Staking";
import { RealTimeAD, RealTimeBalance } from "interfaces/Utils";
import { useEffect, useState, useMemo } from "react";
import SoundPopup from "Base/Modals/Sound";
import ErrorAlert from "Base/Modals/ErrorAlert";
import usePageTracking from "./usePageTracking";
import ModalWrapper from "Base/Modals/ModalWrapper";
import { TxState, TxIDs } from "Base/Modals/ModalWrapper";
import { useBlockMeta } from '@usedapp/core';


function RealApp(props) {
	const {
		activateBrowserWallet,
		account,
		ckiLeagueSizes,
		fdgLeagueSizes,
		deactivate,
		switchNetwork,
		createAttack,
		chainId,
		totalCki,
		totalFdg,
		ckiList,
		fdgList,
		ckiOpponents,
		fdgOpponents,
		targetLeague,
		setTargetLeague,
		compete,
		ad,
		gCki,
		signPermit,
		cki,
		lockingCki,
		stakingCki,
		utils,
		lockingFdg,
		ckiStakeApy,
		fdgStakeApy,
		fdg,
		stakingFdg,
		ccDistr,
		balance,
	} = props;

	const [modal, setModal] = useState(null);
	const [cookieBanner, setCookieBanner] = useState(false);
	const [errorAlert, setAlert] = useState("");
	const blockMeta = useBlockMeta();

	const [txs, setTxs] = useState([
		// {
		// 	id: TxIDs.CLAIM_ALL,
		// 	type: "Claiming rewards",
		// 	title: "Claiming all rewards",
		// 	amount: "",
		// 	state: TxState.SIGNING,
		// 	initalized: true,
		// },
	]);
	const removeTx = (id) => {
		setTxs((prevModals) => prevModals.filter((modal) => modal.id !== id));
	};

	const isTxAvailable = (id) => {
		const tx = txs.find((tx) => tx.id === id);
		return !tx || tx.state === TxState.SUCCESS || tx.state === TxState.FAILURE;
	};

	const addTx = (id, type, title, amount) => {
		if (id == undefined) {
			console.error("Unknown TX ID for popup");
		}

		setTxs((prevModals) => {
			const index = prevModals.findIndex((modal) => modal.id === id);

			if (index !== -1) {
				// Overwrite existing modal
				const updatedModals = [...prevModals];
				updatedModals[index] = {
					id: id,
					type: type,
					title: title,
					amount: amount,
					state: TxState.SIGNING,
					initalized: false,
				};
				console.warn("TX already exists in the list. Overwriting previous one.");
				return updatedModals;
			} else {
				// Add new modal
				return [
					...prevModals,
					{
						id: id,
						type: type,
						title: title,
						amount: amount,
						state: TxState.SIGNING,
						initalized: false,
					},
				];
			}
		});
	};

	useEffect(() => {
		if (modal || cookieBanner) {
			document.body.classList.add("active");
		} else {
			document.body.classList.remove("active");
		}
	}, [modal, cookieBanner]);

	const ckiStakingClaimableRT = RealTimeBalance(
		stakingCki.nowClaimable(),
		stakingCki.ownStake(),
		stakingCki.totalStake(),
		utils.ckiStakeRev(),
		blockMeta.timestamp,
	);
	const fdgStakingClaimableRT = RealTimeBalance(
		stakingFdg.nowClaimable(),
		stakingFdg.ownStake(),
		stakingFdg.totalStake(),
		utils.fdgStakeRev(),
		blockMeta.timestamp,
	);

	const ckiLockingClaimableRT = RealTimeBalance(
		lockingCki.nowClaimable(),
		lockingCki.ownStake(),
		lockingCki.totalStake(),
		utils.ckiLockRev(),
		blockMeta.timestamp,
	);
	const fdgLockingClaimableRT = RealTimeBalance(
		lockingFdg.nowClaimable(),
		lockingFdg.ownStake(),
		lockingFdg.totalStake(),
		utils.fdgLockRev(),
		blockMeta.timestamp,
	);

	const adBalRT = RealTimeAD(compete.adBal(), compete.ckiBlockedAp());
	const [musicEnabled, setMusicEnabled] = useState(null); // Initially null means modal is not shown

	const handleSoundSelected = (isEnabled) => {
		setMusicEnabled(isEnabled);
	};

	const [soundEffects, setSoundEffects] = useState({});
	useEffect(() => {
		let map = {};
		map["launchAttack"] = new Audio("/audio/sword-swing.wav");
		map["attackWon"] = new Audio("audio/jingle-win-fanfare.wav");
		map["attackLost"] = new Audio("audio/jingle-lose-fanfare.wav");
		// map["switchPage"] = new Audio("audio/switch-17.wav");
		// map["openStaking"] = new Audio("audio/switch-14.wav");
		map["claim"] = new Audio("/audio/money.wav");
		map["coin"] = map["claim"];
		map["error"] = new Audio("/audio/error.wav");
		map["click"] = new Audio("/audio/reg-click-sound.wav");
		map["boost"] = new Audio("/audio/boost.wav");
		map["search"] = new Audio("/audio/search.wav");

		map["increase"] = new Audio("/audio/increase.wav");
		map["decrease"] = new Audio("/audio/decrease.wav");

		// legacy:
		map["switchPage"] = map["click"];
		map["openStaking"] = map["click"];

		setSoundEffects(map);
	}, []); // Loaded only once!

	usePageTracking();

	return (
		<>
			<Header
				activateBrowserWallet={activateBrowserWallet}
				soundEffects={soundEffects}
				addTx={addTx}
				account={account}
				deactivate={deactivate}
				attackEvents={compete.attackEvents()}
				switchNetwork={switchNetwork}
				chainId={chainId}
				balCki={totalCki}
				balFdg={totalFdg}
				ckiList={ckiList}
				fdgList={fdgList}
				fdgLockedClaimable={lockingFdg.nowClaimable()}
				fdgStakedClaimable={stakingFdg.nowClaimable()}
				ckiLockedClaimable={lockingCki.nowClaimable()}
				ckiStakedClaimable={stakingCki.nowClaimable()}
				claimAll={utils.claimAll}
				setModal={setModal}
				musicEnabled={musicEnabled}
				hasMinted={ccDistr.hasMinted()}
			/>

			<Routes>
				<Route
					path="/"
					element={
						<Home
							addTx={addTx}
							isTxAvailable={isTxAvailable}
							setAlert={setAlert}
							setCookieBanner={setCookieBanner}
							cookieBanner={cookieBanner}
							setModal={setModal}
							soundEffects={soundEffects}
							ckiLeagueSizes={ckiLeagueSizes}
							fdgLeagueSizes={fdgLeagueSizes}
							modal={modal}
							mint={ccDistr.mint}
							account={account}
							adBlockedCKI={compete.ckiBlockedAp()}
							defense={compete.defenseBal()}
							setDefence={compete.setDefense}
							setDefenceState={compete.setDefenseState}
							ad={compete.adBal()}
							adObj={ad}
							adversaryVector={compete.adversaryVector()}
							approveState={ad.approveState}
							searchState={compete.searchState}
							createAttack={createAttack}
							attackState={compete.attackState}
							attackCount={compete.attackCount()}
							targetLeague={targetLeague}
							setTargetLeague={setTargetLeague}
							attackEvents={compete.attackEvents()}
							realTimeAdBal={adBalRT}
							ckiUnused={cki.bal()}
							fdgUnused={fdg.bal()}
							ckiList={ckiList}
							fdgList={fdgList}
							balCki={totalCki}
							balFdg={totalFdg}
							signPermit={signPermit}
							setAd={compete.setApGen}
							setAdState={compete.setApGenState}
							adDay={compete.apPerDay()}
							ckiApprove={cki.approveFunc}
							ckiLockedRaw={lockingCki.rawStake()}
							ckiLockedStake={lockingCki.ownStake()}
							ckiLockedTotal={lockingCki.totalStake()}
							ckiLockedTotalEarned={lockingCki.totalEarned()}
							ckiLockedClaimable={lockingCki.nowClaimable()}
							ckiStakedClaimable={stakingCki.nowClaimable()}
							ckiLock={lockingCki.lock}
							ckiLockState={lockingCki.lockState}
							ckiUnlock={lockingCki.unlock}
							ckiUnlockState={lockingCki.unlockState}
							ckiLockedClaim={lockingCki.claim}
							ckiLockedClaimState={lockingCki.claimState}
							ckiLockRev={utils.ckiLockRev()}
							ckiLeague={lockingCki.league()}
							ckiLeagueSize={lockingCki.leagueSize()}
							ckiOpponents={ckiOpponents}
							fdgOpponents={fdgOpponents}
							fdgLockedRaw={lockingFdg.rawStake()}
							fdgLockedStake={lockingFdg.ownStake()}
							fdgLockedTotal={lockingFdg.totalStake()}
							fdgLockedTotalEarned={lockingFdg.totalEarned()}
							fdgLockedClaimable={lockingFdg.nowClaimable()}
							fdgStakedClaimable={stakingFdg.nowClaimable()}
							fdgLock={lockingFdg.lock}
							fdgLockState={lockingFdg.lockState}
							fdgUnlock={lockingFdg.unlock}
							fdgUnlockState={lockingFdg.unlockState}
							fdgLockedClaim={lockingFdg.claim}
							fdgLockedClaimState={lockingFdg.claimState}
							fdgLockRev={utils.fdgLockRev()}
							fdgLeague={lockingFdg.league()}
							fdgLeagueSize={lockingFdg.leagueSize()}
							newOpponents={compete.drawOpponents}
							ckiStakeApy={ckiStakeApy}
							fdgStakeApy={fdgStakeApy}
							ckiLockingClaimableRT={ckiLockingClaimableRT}
							fdgLockingClaimableRT={fdgLockingClaimableRT}
							ckiLockChangeEvents={lockingCki.changeLocksEvents()}
							fdgLockChangeEvents={lockingFdg.changeLocksEvents()}
							ckiLockClaimEvents={lockingCki.claimEvents()}
							fdgLockClaimEvents={lockingFdg.claimEvents()}
							ckiStakeChangeEvents={stakingCki.changeStakeEvents()}
							fdgStakeChangeEvents={stakingFdg.changeStakeEvents()}
							ckiStakeClaimEvents={stakingCki.claimEvents()}
							fdgStakeClaimEvents={stakingFdg.claimEvents()}
							defenceChangeEvents={compete.changeDefenceEvents()}
							adGenChangeEvents={compete.changeADGenEvents()}
							changeGCkiEvents={gCki.changeGCkiEvents()}
							gCkiDeposit={gCki.getDeposit()}
							gCkiEnd={gCki.getEnd()}
							gCkiBal={gCki.bal()}
							changeGCki={utils.deposit}
							withdraw={gCki.withdraw}
							gCkiChangeState={utils.gCkiChangeState}
							claimAll={utils.claimAll}
							hasMinted={ccDistr.hasMinted()}
							fdgStakedTotal={stakingFdg.totalStake()}
							ckiStakedTotal={stakingCki.totalStake()}
							ckiStakeRev={utils.ckiStakeRev()}
							fdgStakeRev={utils.fdgStakeRev()}
						/>
					}
				/>
				<Route
					path="/staking"
					element={
						<Staking
							addTx={addTx}
							setAlert={setAlert}
							account={account}
							modal={modal}
							setModal={setModal}
							balCki={totalCki}
							soundEffects={soundEffects}
							balFdg={totalFdg}
							ckiList={ckiList}
							fdgList={fdgList}
							ckiUnused={cki.bal()}
							fdgUnused={fdg.bal()}
							ckiStaked={stakingCki.ownStake()}
							ckiStakeRev={utils.ckiStakeRev()}
							ckiTotalStaked={stakingCki.totalStake()}
							fdgStaked={stakingFdg.ownStake()}
							fdgStakeRev={utils.fdgStakeRev()}
							fdgTotalStaked={stakingFdg.totalStake()}
							fdgClaimable={stakingCki.nowClaimable()}
							ckiClaimable={stakingFdg.nowClaimable()}
							fdgTotalEarned={stakingCki.totalEarned()}
							ckiTotalEarned={stakingFdg.totalEarned()}
							stakeCki={stakingCki.stake}
							stakeCkiState={stakingCki.stakeState}
							stakeCkiClaim={stakingCki.claim}
							stakeCkiClaimState={stakingCki.claimState}
							unstakeCki={stakingCki.unstake}
							unstakeCkiState={stakingCki.unstakeState}
							stakeFdg={stakingFdg.stake}
							stakeFdgState={stakingFdg.stakeState}
							stakeFdgClaim={stakingFdg.claim}
							stakeFdgClaimState={stakingFdg.claimState}
							unstakeFdg={stakingFdg.unstake}
							unstakeFdgState={stakingFdg.unstakeState}
							signPermit={signPermit}
							mint={ccDistr.mint}
							ckiLockRev={utils.ckiLockRev()}
							ckiLockedRaw={lockingCki.rawStake()}
							ckiLockedStake={lockingCki.ownStake()}
							ckiLockedTotal={lockingCki.totalStake()}
							fdgLockedRaw={lockingFdg.rawStake()}
							fdgLockedStake={lockingFdg.ownStake()}
							fdgLockedTotal={lockingFdg.totalStake()}
							fdgLockRev={utils.fdgLockRev()}
							ckiStakeApy={ckiStakeApy}
							fdgStakeApy={fdgStakeApy}
							ckiStakingClaimableRT={fdgStakingClaimableRT}
							fdgStakingClaimableRT={ckiStakingClaimableRT}
							ckiLockChangeEvents={lockingCki.changeLocksEvents()}
							fdgLockChangeEvents={lockingFdg.changeLocksEvents()}
							ckiLockClaimEvents={lockingCki.claimEvents()}
							fdgLockClaimEvents={lockingFdg.claimEvents()}
							ckiStakeChangeEvents={stakingCki.changeStakeEvents()}
							fdgStakeChangeEvents={stakingFdg.changeStakeEvents()}
							ckiStakeClaimEvents={stakingCki.claimEvents()}
							fdgStakeClaimEvents={stakingFdg.claimEvents()}
							defenceChangeEvents={compete.changeDefenceEvents()}
							adGenChangeEvents={compete.changeADGenEvents()}
							gCkiWithdrawEvents={gCki.withdrawEvents()}
							gCkiChangeState={gCki.gCkiChangeState}
							changeGCkiEvents={gCki.changeGCkiEvents()}
							hasMinted={ccDistr.hasMinted()}
							balance={balance}
						/>
					}
				/>
			</Routes>
			{errorAlert.length > 0 && <ErrorAlert alert={errorAlert} setAlert={setAlert} />}
			<ModalWrapper
				txs={txs}
				setTxs={setTxs}
				removeTx={removeTx}
				lockingCki={lockingCki}
				lockingFdg={lockingFdg}
				stakingCki={stakingCki}
				stakingFdg={stakingFdg}
				compete={compete}
				utils={utils}
				ccDistr={ccDistr}
			/>
		</>
	);
}

export default RealApp;
