import React, { useEffect, useState } from "react";
import Sound from "react-sound";

const ErrorAlert = ({ alert, setAlert }) => {
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setVisible(false); // Set the modal to disappear slowly
			setTimeout(() => {
				setAlert(""); // Hide the modal completely
			}, 1000); // Wait for the transition to complete (1 second)
		}, 3000); // 3000 milliseconds = 3 seconds

		return () => clearTimeout(timer); // Clear the timer on component unmount
	}, [setAlert]);

	return (
		<div className={`errorAlert-modal ${visible ? "" : "hidden"}`}>
			<div className="errorAlert-content">
				<h6>Error</h6>
				<p className="sm">{alert}</p>
			</div>
		</div>
	);
};

export default ErrorAlert;
