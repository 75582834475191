import React, { useState } from "react";
import { closeIcon, infoIcon } from "../../SVG";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import usePromise from "react-promise";
import { BoardModul } from "./BoardModul";
import { getLeagueMax, getLeagueName, leagueInfo } from "Pages/Home/components/Compete/Compete";
import { printBD, printUnscaledBD } from "interfaces/Numbers";

export default function Leagues({
	setModal,
	leagueSizes,
	ckiLeague,
	fdgLeague,
	targetLeague,
	targetLeagueSize,
	setTargetLeague,
}) {
	const [tooltipHover1, setTooltipHover1] = useState(false);
	const [tooltipHover2, setTooltipHover2] = useState(false);

	const { dataType } = useSelector((state) => state.common);
	const [userData, setUserData] = useState({
		id: "9",
		no: "0xDe9cf..",
		sec: "125",
		cki_fdg: "54,000",
		defense: "23,000",
	});
	const settings = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		// fade: true,
		// responsive: [
		//   {
		//     breakpoint: 540,
		//     settings: {
		//       slidesToShow: 3,
		//       arrows: false,
		//       slidesToScroll: 1,
		//     },
		//   },
		// ],
	};
	const settings2 = {
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		// fade: true,
		// responsive: [
		//   {
		//     breakpoint: 540,
		//     settings: {
		//       slidesToShow: 3,
		//       arrows: false,
		//       slidesToScroll: 1,
		//     },
		//   },
		// ],
	};
	return (
		<div className="modalBoard">
			<div className="modalBoard__inner">
				<div className="modalBoard__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalBoard__inner-title">
					<h5>
						{dataType === "cookie" ? "Cookie " : "Fudge "}
						Leagues
					</h5>
				</div>
				<div className="modalBoard__table">
					<div className="modalBoard__table-inner">
						<table>
							<thead>
								<tr>
									<th></th>
									{/* <th></th> */}
									<th>
										<div className="modalBoard__table-th">
											<h6>Name</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Players</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Max win</h6>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{Object.entries(leagueInfo).map((item, index) => {
									return (
										<TableItem
											itemData={item}
											sizes={leagueSizes}
											dataType={dataType}
											onClick={() => {
												setTargetLeague(item[0]);
												setModal(null);
											}}
										/>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="modalBoard__table extra">
					<div className="modalBoard__table-inner">
						<table>
							<thead>
								<tr>
									<th></th>
									{/* <th></th> */}
									<th>
										<div className="modalBoard__table-th">
											<h6>Name</h6>
											<div className="tooltip">
												<div className="tooltip__icon">{infoIcon}</div>
											</div>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Players</h6>
										</div>
									</th>
									<th>
										<div className="modalBoard__table-th">
											<h6>Max win</h6>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									{/* <td>
										<p>{userData.id}</p>
									</td> */}
									<td></td>
									<td>{getLeagueName(targetLeague)}</td>
									<td>{printUnscaledBD(targetLeagueSize, 0)}</td>
									<td>
										{(getLeagueMax(targetLeague) * 0.01).toLocaleString(
											"en-US",
											{
												minimumFractionDigits: 0,
												maximumFractionDigits: 2,
												useGrouping: true,
											}
										)}
										{dataType == "cookie" ? " CKI" : " FDG"}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
const TableItem = ({ itemData, onClick, dataType, sizes }) => {
	// console.log(itemData);
	// console.log(sizes[itemData[0]]);
	const { value, loading } = usePromise(sizes[itemData[0]]);
	// console.log(value);

	return (
		<tr onClick={onClick}>
			<td></td>
			{/* <td>
				<p className="hidden">You</p>
			</td> */}
			<td>{itemData[1].name}</td>
			<td>{printUnscaledBD(value, 0)}</td>
			<td>
				{(itemData[1].max * 0.01).toLocaleString("en-US", {
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
					useGrouping: true,
				})}
				{dataType == "cookie" ? " CKI" : " FDG"}
			</td>
		</tr>
	);
};
