import { useEffect, useState } from "react";
import { closeIcon, infoIcon, plusIcon2 } from "../../SVG";
import DoghChart from "../../DoghChart";
import { BD, BN, ZERO, getUnscaledBD, inputToBnScaled, printBD } from "interfaces/Numbers";
import { computeRatio } from "App";
import { Addresses } from "index";
import { getGCki, getLockStrength } from "interfaces/Utils";
import { TxIDs } from "../ModalWrapper";

export default function Boost(props) {
	const {
		account,
		setAlert,
		setModal,
		totalBal,
		list,
		addTx,
		unused,
		gCkiDeposit,
		ckiStake,
		fdgStake,
		rawCkiStake,
		rawFdgStake,
		gCkiEnd,
		gCkiBal,
		signPermit,
		changeGCki,
		withdraw,
		setTxInfo,
		ckiTotalStake,
		fdgTotalStake,
		ckiLockRev,
		fdgLockRev,
		soundEffects,
		user,
	} = props;

	const currentUnixTimestampSeconds = Math.floor(Date.now() / 1000);
	const gCkiLeft =
		gCkiEnd && gCkiEnd > 0 ? Math.max(0, gCkiEnd - currentUnixTimestampSeconds) / 86400 : 0;
	const [balance, setBalance] = useState(1000);
	const [maxDays, setMaxDays] = useState(28);
	const [value, setValue] = useState(0);
	const [days, setDays] = useState(0);

	useEffect(() => {
		setBalance(Math.floor(getUnscaledBD(unused) * 100) / 100);
	}, [unused]);

	useEffect(() => {
		setMaxDays(Math.floor((28 - gCkiLeft) * 100) / 100);
	}, [gCkiEnd]);

	useEffect(() => {
		if (
			typeof value === "string" &&
			((value.charAt(value.length - 1) != "." &&
				value.charAt(value.length - 2) != "." &&
				value.charAt(value.length - 3) != ".") ||
				value[0] == "-" ||
				value[0] == "+")
		) {
			setValue(Math.abs(Math.floor(parseFloat(value.toString()) * 100) / 100));
		}
	}, [value]);

	useEffect(() => {
		if (
			typeof days === "string" &&
			((days.charAt(days.length - 1) != "." &&
				days.charAt(days.length - 2) != "." &&
				days.charAt(days.length - 3) != ".") ||
				days[0] == "-" ||
				days[0] == "+")
		) {
			setDays(Math.abs(Math.floor(parseFloat(days.toString()) * 100) / 100));
		}
	}, [days]);

	const [updatedList, setUpdatedList] = useState([...list]);
	useEffect(() => {
		setUpdatedList(list);
		if (
			value !== "" &&
			!isNaN(Number(value)) &&
			days !== "" &&
			!isNaN(Number(days)) &&
			unused.compareTo(ZERO) !== 0
		) {
			const tot = list.reduce((sum, item) => BD(sum).add(BD(item.bal)), BD(0));
			const newValue = BD(value)
				.multiply(BD(10 ** 18))
				.toBigInteger();
			const newList = list.map((obj) => ({ ...obj }));
			newList[0].percent = computeRatio(BD(BN(list[0].bal).sub(BN(newValue))), tot);
			newList[3].percent = computeRatio(BD(BN(list[3].bal).add(BN(newValue))), tot);

			setUpdatedList(newList);
		}
	}, [value, list, unused]);

	const [gCki, setGCki] = useState(0);
	useEffect(() => {
		if (value !== "" && !isNaN(Number(value)) && days !== "" && !isNaN(Number(days))) {
			const newValue = BD(value).multiply(BD(10 ** 18));
			const newNetCkiDeposit = BN(gCkiDeposit).add(BN(newValue.toBigInteger()));
			const gCkiBlocked = gCkiLeft + Number(days);
			setGCki(getGCki(newNetCkiDeposit, gCkiBlocked));
		} else {
			setGCki(getGCki(BN(gCkiDeposit), gCkiLeft));
		}
	}, [days, value, gCkiDeposit, gCkiLeft]);

	const [ckiApy, setCkiApy] = useState(0);

	const noBoostCkiStake = BD(getLockStrength(BN(rawCkiStake), BN(0)));
	let diffStake = BD(Number(ckiStake)).subtract(noBoostCkiStake);
	const noBoostTotalCkiStake = BD(ckiTotalStake).subtract(diffStake);
	const ckiWoApy =
		noBoostCkiStake.compareTo(ZERO) !== 0
			? (ckiLockRev * noBoostCkiStake) / (noBoostTotalCkiStake * 24)
			: 0;
	useEffect(() => {
		if (rawCkiStake.compareTo(ZERO) !== 0) {
			const newNetStake = getLockStrength(BN(rawCkiStake), gCki);
			const toAdd = BD(Number(newNetStake) - Number(ckiStake));
			setCkiApy((ckiLockRev * BD(newNetStake)) / (BD(ckiTotalStake).add(toAdd) * 24));
		} else {
			setCkiApy(ckiWoApy);
		}
	}, [rawCkiStake, gCki, ckiStake, ckiLockRev, ckiTotalStake]);

	const [fdgApy, setFdgApy] = useState(0);

	const noBoostFdgStake = BD(getLockStrength(BN(rawFdgStake), BN(0)));
	diffStake = BD(Number(fdgStake)).subtract(noBoostFdgStake);
	const noBoostTotalFdgStake = BD(fdgTotalStake).subtract(diffStake);
	const fdgWoApy =
		noBoostFdgStake.compareTo(ZERO) !== 0
			? (fdgLockRev * noBoostFdgStake) / (noBoostTotalFdgStake * 24)
			: 0;

	useEffect(() => {
		if (rawFdgStake.compareTo(ZERO) !== 0) {
			const newNetStake = getLockStrength(BN(rawFdgStake), gCki);
			const toAdd = BD(Number(newNetStake) - Number(fdgStake));
			setFdgApy((fdgLockRev * BD(newNetStake)) / (BD(fdgTotalStake).add(toAdd) * 24));
		} else {
			setFdgApy(fdgWoApy);
		}
	}, [rawFdgStake, gCki, fdgStake, fdgLockRev, fdgTotalStake]);

	return (
		<div className="modalStake">
			<div className="modalStake__inner">
				<div className="modalStake__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalStake__inner-title">
					<h4>Boost your Yield</h4>
				</div>
				<div className="modalStake__inner-desc">
					<p>
						Convert your CKI into Golden Cookies for a specified period to boost the yield of your locked CKI and FDG tokens.
					</p>
				</div>

				<div className="modalStake__content">
					<div className="modalStake__left">
						<div className="modalStake__left-func">
							<div className="modalStake__left-title">
								<p className="sm">Available Balance:</p>
								<div className="modalStake__left-title-row">
									<p className="sm">{printBD(unused)}</p>
									<img src={process.env.PUBLIC_URL + "images/logo.svg"} alt="" />
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(value) ? value : ""}
										onChange={(e) => {
											if ((e.nativeEvent.data !== " ") && e.target.value <= balance) {
												setValue(e.target.value);
											}
										}}
									/>
									<p>CKI</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setValue(balance);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span style={{ width: `${(value * 100) / balance}%` }}></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 0 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 0)}
									></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.25 * 100) / 100)
										}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.5 * 100) / 100)
										}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setValue(Math.floor(balance * 0.75 * 100) / 100)
										}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((value * 100) / balance >= 99 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setValue(balance * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-func">
							<div className="modalStake__left-title">
								<p className="sm">Time:</p>
								<div className="modalStake__left-title-row">
									<p className="sm">{maxDays} days</p>
								</div>
							</div>
							<div className="modalStake__left-row">
								<div className="modalStake__left-input">
									<input
										type="text"
										value={!isNaN(days) ? days : ""}
										onChange={(e) => {
											if ((e.nativeEvent.data !== " ") && e.target.value <= maxDays) {
												setDays(e.target.value);
											}
										}}
									/>
									<p>days</p>
								</div>
								<button
									className="button secondary"
									onClick={() => {
										soundEffects["click"].play();
										setDays(maxDays);
									}}
								>
									Max
								</button>
							</div>
							<div className="modalStake__range">
								<div className="modalStake__range-line">
									<span style={{ width: `${(days * 100) / maxDays}%` }}></span>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((days * 100) / maxDays >= 0 ? "active" : "")
									}
								>
									<button type="button" onClick={() => setDays(0)}></button>
									<p>0%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((days * 100) / maxDays >= 24 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setDays(Math.floor(maxDays * 0.25 * 100) / 100)
										}
									></button>
									<p>25%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((days * 100) / maxDays >= 49 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setDays(Math.floor(maxDays * 0.5 * 100) / 100)
										}
									></button>
									<p>50%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((days * 100) / maxDays >= 74 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() =>
											setDays(Math.floor(maxDays * 0.75 * 100) / 100)
										}
									></button>
									<p>75%</p>
								</div>
								<div
									className={
										"modalStake__range-item " +
										((days * 100) / maxDays >= 99 ? "active" : "")
									}
								>
									<button
										type="button"
										onClick={() => setDays(maxDays * 1)}
									></button>
									<p>100%</p>
								</div>
							</div>
						</div>
						<div className="modalStake__left-box mb24">
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>Golden CKI</p>
								</div>
								<div className="modalStake__left-box-row-right">
									<p>{printBD(gCki)}</p>
									<img
										src={process.env.PUBLIC_URL + "images/icons/kitchen/goldCookie.svg"}
										alt=""
										style={{ marginLeft: "5px", marginRight: "5px" }} // Adjust margin as needed
									/>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>CKI Locking</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Extra hourly yield from your locked CKI through
												boosting.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<div style={{ display: "flex", alignItems: "center" }}>
										<p>
											<span style={{ color: "#18be28" }}>+{Math.abs(((ckiApy -  ckiWoApy) / 1e18)).toFixed(2)}</span>
										</p>
										<img
											src={process.env.PUBLIC_URL + "images/logo.svg"}
											alt=""
											style={{ width: "17px", height: "17px", marginLeft: "3px", marginRight: "1px" }}
										/>
										<p style={{ fontWeight: "bold" }}>/H</p>
									</div>
								</div>
							</div>
							<div className="modalStake__left-box-row">
								<div className="modalStake__left-box-row-left">
									<p>FDG Locking</p>
									<div className="tooltip">
										<div className="tooltip__icon">{infoIcon}</div>
										<div className="tooltip__content">
											<div className="tooltip__content-inner">
												Extra hourly yield from your locked FDG through
												boosting.
											</div>
										</div>
									</div>
								</div>
								<div className="modalStake__left-box-row-right">
									<div style={{ display: "flex", alignItems: "center" }}>
										<p>
											<span style={{ color: "#18be28" }}>+{Math.abs(((fdgApy -  fdgWoApy) / 1e18)).toFixed(2)}</span>
										</p>
										<img
											src={process.env.PUBLIC_URL + "images/fudge.svg"}
											alt=""
											style={{ width: "17px", height: "17px", marginLeft: "px", marginRight: "px" }}
										/>
										<p style={{ fontWeight: "bold" }}>/H</p>
									</div>
								</div>
							</div>
						</div>
						<div
							className="button primary sm"
							onClick={async () => {
								if (account === undefined) {
									soundEffects["error"].play();
									setAlert("Please connect your wallet.");
									return;
								}
								
								if (value === undefined || isNaN(value)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number of CKI.");
									return;
								}

								if (days === undefined || isNaN(days)) {
									soundEffects["error"].play();
									setAlert("Please enter a valid number of days.");
									return;
								}

								soundEffects["coin"].play();
								const addCki = inputToBnScaled(value);
								const addTime = BN(days * 86400);

								if (addCki > 0) {
									const permit = await signPermit(
										Addresses.CKI,
										Addresses.GCKI,
										addCki.toString()
									);

									const tx = changeGCki(
										addTime,
										addCki,
										permit.deadline,
										permit.v,
										permit.r,
										permit.s,
										user
									);
								} else {
									const ZERO_BYTES_32 = "0x" + "00".repeat(32);
									const tx = changeGCki(
										addTime,
										0,
										0,
										0,
										ZERO_BYTES_32,
										ZERO_BYTES_32,
										user
									);
								}

								addTx(
									TxIDs.SET_GCKI,
									"Boost",
									"Baking " + value.toLocaleString("en-US") + " GCKI",
									""
								);
								setModal(null);
							}}
						>
							Boost
						</div>
					</div>
					<div className="modalStake__right">
						<div className="modalStake__right-title">
							<h5 className="sm">CKI Balance</h5>
							<div className="tooltip">
								<div className="tooltip__icon">{infoIcon}</div>
								<div className="tooltip__content">
									<div className="tooltip__content-inner">
										Your new CKI balance and distribution strategy.
									</div>
								</div>
							</div>
						</div>
						<div className="modalStake__right-inner">
							<div className="modalStake__right-chart">
								<div className="modalStake__right-chart-bal">
									<img src="./images/logo.svg" alt="" />
									{printBD(totalBal)}
								</div>
								<DoghChart
									data={updatedList.map((item) => item.percent)}
									bgColors={updatedList.map((item) => item.color)}
									percentCircle="75%"
								/>
							</div>
							<div className="modalStake__right-data">
								{updatedList.map((item, index) => (
									<div className="modalStake__right-data-item" key={index}>
										<div
											className="modalStake__right-data-item-icon"
											style={{ background: item.color }}
										></div>
										<p>
											{item.title} <span>{item.percent}%</span>
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
