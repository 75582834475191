import React, { useEffect } from "react";
import { roundArrowIcon, walletIcon } from "../../SVG";

export default function Transaction({ setModal, txInfo, approveState, searchState }) {
	useEffect(() => {
		let state = approveState;
		if (txInfo["action"] == "search") {
			state = searchState;
		}

		if (state["status"] == "PendingSignature") {
			setModal("transaction");
		} else if (state["status"] == "Mining") {
			setModal("transaction2");
		} else if (state["status"] == "Success") {
			setModal("transaction3");
		} else if (state["status"] == "Error") {
			setModal("transactionLose");
		} else if (state["status"] == "Exception") {
			setModal("transactionLose");
		}
	}, [txInfo, approveState, searchState]);

	return (
		<div className="modalAttack">
			<div className="modalAttack__inner">
				<h5>Approve Transaction</h5>
				<div className="modalAttack__item big">
					<div className="modalAttack__item-side">
						<div className="modalAttack__item-icon">{roundArrowIcon}</div>
						<div className="modalAttack__item-content">
							<h6>{txInfo.description}</h6>
							<p className="big">
								<b>Competition</b>
								<img
									src={process.env.PUBLIC_URL + "/images/icons/cupIcon.svg"}
									alt=""
								/>
							</p>
						</div>
					</div>
					<div className="modalAttack__item-price">-{txInfo.value} AD</div>
				</div>
				<div className="modalAttack__inner-image">
					<img
						className="swim2"
						src={process.env.PUBLIC_URL + "/images/icons/mixing.png"}
						alt=""
					/>
				</div>
				<div className="modalAttack__wallet">
					{walletIcon}
					<p className="sm">Go to your wallet to confirm attack</p>
				</div>
				<div className="modalAttack__foot">
					<p>TX Hash:</p>
					<button>0xba8...b808</button>
				</div>
				<div className="modalAttack__back">
					<button type="button" onClick={() => setModal(null)}>
						I’ve changed my mind...Cancel!
					</button>
				</div>
			</div>
		</div>
	);
}
