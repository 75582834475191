import React from "react";
import { closeIcon } from "../../SVG";

export default function CookieBanner({ setModal }) {
  return (
    <div className="modalCookie">
      <div className="modalCookie__inner">
        <div
          className="modalCookie__inner-close"
          onClick={() => setModal(null)}
        >
          {closeIcon}
        </div>
        <div className="modalCookie__title">We value your privacy</div>
        <div className="modalCookie__desc">
          <p>
            We use cookies to enhance your browsing experience servce
            personalized ads or content, and analyze our traffic. By clicking
            "Accept All", you consent to our use of cookies.{" "}
            <a href="#">Cookie Policy</a>
          </p>
        </div>
        <div className="modalCookie__btns">
          <button
            type="button"
            className="button secondary"
            onClick={() => setModal(null)}
          >
            Customize
          </button>
          <button
            type="button"
            className="button secondary"
            onClick={() => setModal(null)}
          >
            Reject All
          </button>
          <button
            type="button"
            className="button primary"
            onClick={() => setModal(null)}
          >
            Accept All
          </button>
        </div>
      </div>
    </div>
  );
}
