import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import {persistor, store} from "./store/store";

import {DAppProvider} from "@usedapp/core";

export const Addresses = {
    CKI: "0x407a5DF2b8DECe2CD385a06C5d480626b8dD7c0F",
    FDG: "0x72bf5b14Fb422fBD1FeF5Ad08f182AcCA947c134",
    GCKI: "0x7d18d72A8ecc6c60B5b183111314931e69482DC1",
    DGH: "0xD903092D9CbAAE5fb2511709C2aB6d0B46Ff87A5",
    CkiDistr: "0x0805155cfa484d9b9f0D760C76fF3DCFB5787b98",
    FdgDistr: "0x1d3F90dCBf615b8E8C2B2707a02787e65a4A96E2",
    CCDistr: "0xE8eA196Db711AED9E37333ea59c919eb9dcb5C84",
    CCStakingCKI: "0x8a8FA2698Ea7Eca2bA8223673F404c65A85766e5",
    CCStakingFDG: "0x21b904d3289938A7d61D81AEB3Ca552c0BB48644",
    CCCompete: "0x0457F0282F983301C5532C41127F003a4CA68852",
    vrfCoordinator: "0x50d47e4142598E3411aA864e08a44284e471AC6f",
    CCLockingCKI: "0xB6dD25265CC8350bAE2689E83DB459194a4C8C77",
    CCLockingFDG: "0xfB40DFc403f31d72A54e8627ee854aC23223e0a4",
    CCPoolHandler: "0x8465f734b05903Fa2bbf08aBf870c10093A12265",
    Utils: "0x02b073D1E973463e903FA6eca18739AfAc233Ad7"
};

export const SeasonDurationDays = 28;
export const SeasonStart = new Date("2024-07-31 07:12:42.944226+00");

export const CKI = Addresses.CKI;
export const FDG = Addresses.FDG;
export const CCLockingFDG = Addresses.CCLockingFDG;
export const CCStakingFDG = Addresses.CCStakingFDG;
export const CCLockingCKI = Addresses.CCLockingCKI;
export const CCStakingCKI = Addresses.CCStakingCKI;
export const AP = Addresses.DGH;
export const CCCompete = Addresses.CCCompete;

const sepolioExplorerUrl = "https://sepolia.arbiscan.io/";

export const Chainlabs = {
    chainId: 421614,
    chainName: "Arbitrum Sepolia",
    isTestChain: true,
    isLocalChain: false,
    getExplorerAddressLink: () => "",
    getExplorerTransactionLink: () => "",
    multicall2Address: "",
    multicallAddress: "0xF7bdf44cc524A06C34795c96756584c865073660",
    nativeCurrency: {
        name: "ETH",
        symbol: "ETH",
        decimals: 18
    },
    rpcUrl: "https://arb-sepolia.g.alchemy.com/v2/bRXI8Go3GHYifwELXeUJLy_QrSwyaaqI",
    blockExplorerUrl: sepolioExplorerUrl
};

const config = {
    networks: [Chainlabs],
    readOnlyChainId: Chainlabs.chainId,
    readOnlyUrls: {
        [Chainlabs.chainId]: "https://arb-sepolia.g.alchemy.com/v2/bRXI8Go3GHYifwELXeUJLy_QrSwyaaqI"
    }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render (
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null}
                persistor={persistor}>
                <DAppProvider config={config}>
                    <App/>
                </DAppProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);
