import { useEffect, useState } from "react";
import LayoutCard from "../../Base/LayoutCard";
import { AnimatePresence } from "framer-motion";

import Compete from "./components/Compete/Compete";
import Kitchen from "./components/Side/Kitchen";
import Alerts from "./components/Side/Alerts";
import Modal from "../../Base/Modals/Modal";
import Attack from "../../Base/Modals/Attack/Attack";
import Attack2 from "../../Base/Modals/Attack/Attack2";
import Attack3 from "../../Base/Modals/Attack/Attack3";
import AttackVictory from "../../Base/Modals/Attack/AttackVictory";
import Transaction from "../../Base/Modals/Transaction/Transaction";
import Transaction2 from "../../Base/Modals/Transaction/Transaction2";
import Transaction3 from "../../Base/Modals/Transaction/Transaction3";
import Purchase from "../../Base/Modals/Purchase/Purchase";
import Purchase2 from "../../Base/Modals/Purchase/Purchase2";
import PurchaseSuccess from "../../Base/Modals/Purchase/PurchaseSuccess";
import PurchaseLose from "../../Base/Modals/Purchase/PurchaseLose";
import TransactionLose from "../../Base/Modals/Transaction/TransactionLose";
import AttackFail from "../../Base/Modals/Attack/AttackFail";
import SetAttack from "../../Base/Modals/Kitchen/SetAttack";
import SetDefence from "../../Base/Modals/Kitchen/SetDefence";
import Boost from "../../Base/Modals/Kitchen/Boost";
import Lock from "../../Base/Modals/Kitchen/Lock";
import Board from "../../Base/Modals/Board/Board";
import AttackLose from "../../Base/Modals/Attack/AttackLose";
import GeneralInfo from "Base/Modals/Staking/GeneralInfo";
import Warning from "Base/Modals/Warning";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { printBD } from "interfaces/Numbers";
import ClaimAll from "Base/Modals/ClaimAll";
import Leagues from "../../Base/Modals/Leagues/Leagues";
import Tutorial from "../../Base/Modals/Tutorial/Tutorial";
import CookieBanner from "../../Base/Modals/CookieBanner/CookieBanner";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";

export default function Home(props) {
	const {
		addTx,
		isTxAvailable,
		modal,
		setAlert,
		mint,
		ckiLeagueSizes,
		fdgLeagueSizes,
		setModal,
		soundEffects,
		cookieBanner,
		setCookieBanner,
		account,
		adBlockedCKI,
		newOpponents,
		defense,
		setDefence,
		ad,
		signPermit,
		balCki,
		balFdg,
		ckiList,
		ckiUnused,
		fdgUnused,
		setDefenceState,
		setAd,
		setAdState,
		adDay,
		realTimeAdBal,
		ckiApprove,
		fdgList,
		adObj,
		approveState,
		searchState,
		createAttack,
		attackState,
		attackEvents,
		attackCount,

		claimAll,

		adversaryVector,
		ckiStakeApy,
		fdgStakeApy,

		ckiLockedRaw,
		ckiLockedStake,
		ckiLockedTotal,
		ckiLockedTotalEarned,
		ckiStakedClaimable,
		ckiLockedClaimable,
		ckiLock,
		ckiLockState,
		ckiUnlock,
		ckiUnlockState,
		ckiLockedClaim,
		ckiLockedClaimState,
		ckiLockRev,
		ckiLeague,
		ckiLeagueSize,
		ckiOpponents,
		fdgOpponents,

		fdgLockedRaw,
		fdgLockedStake,
		fdgLockedTotal,
		fdgLockedTotalEarned,
		fdgLockedClaimable,
		fdgStakedClaimable,
		fdgLock,
		fdgLockState,
		gCkiChangeState,
		fdgUnlock,
		fdgUnlockState,
		fdgLockedClaim,
		fdgLockedClaimState,
		fdgLockRev,
		fdgLeague,
		fdgLeagueSize,
		targetLeague,
		setTargetLeague,

		ckiLockChangeEvents,
		fdgLockChangeEvents,
		ckiLockClaimEvents,
		fdgLockClaimEvents,
		ckiStakeChangeEvents,
		fdgStakeChangeEvents,
		ckiStakeClaimEvents,
		fdgStakeClaimEvents,
		defenceChangeEvents,
		adGenChangeEvents,
		changeGCkiEvents,

		ckiLockingClaimableRT,
		fdgLockingClaimableRT,

		gCkiDeposit,
		gCkiEnd,
		gCkiBal,
		changeGCki,
		withdraw,
		hasMinted,
		balance,

		fdgStakedTotal,
		ckiStakedTotal,
		ckiStakeRev,
		fdgStakeRev,
	} = props;

	const checkConnection = () => {
		if (!account) {
			setModal("generalInfo");
			return false;
		}
		return true;
	};

	const [showWelcome, setShowWelcome] = useState(true);
	const [step, setStep] = useState("choose");
	const [pendingAttacks, setPendingAttacks] = useState(new Set());
	const [attackTokens, setAttackTokens] = useState(new Map());

	const [txInfo, setTxInfo] = useState({
		description: "",
		value: 0,
		action: "",
		token: "",
	});
	const { dataType } = useSelector((state) => state.common);

	/*useEffect(() => {
		const storedLastSeenTime = localStorage.getItem("lastSeenTime");

		if (
			showWelcome &&
			account &&
			ckiLockedClaimable != undefined &&
			fdgLockedClaimable != undefined &&
			ckiStakedClaimable != undefined &&
			fdgStakedClaimable != undefined
		) {
			const timeSinceLastSeen = Date.now() - parseInt(storedLastSeenTime);

			if (timeSinceLastSeen > 3600000 || !timeSinceLastSeen) {
				localStorage.setItem("lastSeenTime", Date.now());
				setShowWelcome(false);
				if (
					Number(ckiLockedClaimable) +
						Number(fdgLockedClaimable) +
						Number(ckiStakedClaimable) +
						Number(fdgStakedClaimable) >
					0
				) {
					setModal("welcomeBack");
				}
			}
		}
	}, [
		account,
		showWelcome,
		ckiLockedClaimable,
		fdgLockedClaimable,
		ckiStakedClaimable,
		fdgStakedClaimable,
	]);*/

	//console.log(showWelcome);
	// localStorage.setItem("lastSeenTime", 0);

	useEffect(() => {
		if (modal) {
			document.body.classList.add("active");
		} else {
			document.body.classList.remove("active");
		}
	}, [modal]);

	useEffect(() => {
		if (attackEvents) {
			const e = attackEvents.value[attackEvents.value?.length - 1];
			if (e && pendingAttacks.has(e.data.attackNumber)) {
				// console.log(e.data.attackNumber);
				const number = e.data.attackNumber;
				const newSet = new Set(pendingAttacks);
				newSet.delete(number);
				setPendingAttacks(newSet);
				// setStep("choose");

				const avatar = createAvatar(avataaarsNeutral, {
					seed: e.data.adv,
				});

				setTxInfo({
					description: e.data.adv,
					value: printBD(e.data.gain),
					token: attackTokens[number],
					avatar: avatar.toString(),
				});

				if (e.data.success) {
					soundEffects["attackWon"].play();
					setModal("attackVictory");
				} else {
					soundEffects["attackLost"].play();
					setModal("attackLose");
				}
			}
		}
	}, [attackEvents]);

	// console.log(attackEvents);
	// attackEvents?.value?.forEach((log) => {
	// console.log(log.transactionHash);
	// console.log(log);
	// });
	return (
		<>
			<main className="main">
				<div className="layout">
					<div className="auto__container">
						<div className="layout__inner">
							<div className="layout__inner-main">
								<div className="layout__inner-top">
									<LayoutCard
										type="board"
										league={dataType == "cookie" ? ckiLeague : fdgLeague}
										total={dataType == "cookie" ? ckiLockedRaw : fdgLockedRaw}
										onClick={() => {
											soundEffects["click"].play();
											setModal("board");
										}}
										token={dataType == "cookie" ? "CKI" : "FDG"}
									/>
									<LayoutCard type="season" status="progress" />
								</div>
								<Compete
									addTx={addTx}
									isTxAvailable={isTxAvailable}
									setAlert={setAlert}
									setModal={setModal}
									step={step}
									setStep={setStep}
									soundEffects={soundEffects}
									account={account}
									signPermit={signPermit}
									defence={defense}
									adObj={adObj}
									adBal={ad}
									attackCount={attackCount}
									adversaryVector={adversaryVector}
									pendingAttacks={pendingAttacks}
									setPendingAttacks={setPendingAttacks}
									attackTokens={attackTokens}
									createAttack={createAttack}
									newOpponents={newOpponents}
									ckiLockedRaw={ckiLockedRaw}
									ckiLockedStake={ckiLockedStake}
									ckiLockedTotal={ckiLockedTotal}
									ckiLockedTotalEarned={ckiLockedTotalEarned}
									ckiLockedClaimable={ckiLockedClaimable}
									ckiLock={ckiLock}
									ckiUnlock={ckiUnlock}
									ckiLockedClaim={ckiLockedClaim}
									ckiLockRev={ckiLockRev}
									ckiLeague={ckiLeague}
									ckiLeagueSize={ckiLeagueSize}
									ckiOpponents={ckiOpponents}
									fdgOpponents={fdgOpponents}
									fdgLockedRaw={fdgLockedRaw}
									fdgLockedStake={fdgLockedStake}
									fdgLockedTotal={fdgLockedTotal}
									fdgLockedTotalEarned={fdgLockedTotalEarned}
									fdgLockedClaimable={fdgLockedClaimable}
									fdgLock={fdgLock}
									fdgUnlock={fdgUnlock}
									fdgLockedClaim={fdgLockedClaim}
									fdgLockRev={fdgLockRev}
									fdgLeague={fdgLeague}
									fdgLeagueSize={fdgLeagueSize}
									targetLeague={targetLeague}
									setTargetLeague={setTargetLeague}
									setTxInfo={setTxInfo}
									ckiLockingClaimableRT={ckiLockingClaimableRT}
									fdgLockingClaimableRT={fdgLockingClaimableRT}
									hasMinted={hasMinted}
								/>
							</div>
							<div className="layout__inner-side">
								<Kitchen
									account={account}
									setModal={setModal}
									setAlert={setAlert}
									adBlockedCKI={adBlockedCKI}
									defense={defense}
									realTimeAdBal={realTimeAdBal}
									gCkiDeposit={gCkiDeposit}
									gCkiEnd={gCkiEnd}
									gCkiBal={gCkiBal}
									gCKIBlockedCKI={gCkiDeposit}
									adDay={adDay}
									soundEffects={soundEffects}
									withdrawGCki={withdraw}
								/>
								<Alerts
									account={account}
									ckiLockChangeEvents={ckiLockChangeEvents}
									fdgLockChangeEvents={fdgLockChangeEvents}
									ckiLockClaimEvents={ckiLockClaimEvents}
									fdgLockClaimEvents={fdgLockClaimEvents}
									ckiStakeChangeEvents={ckiStakeChangeEvents}
									fdgStakeChangeEvents={fdgStakeChangeEvents}
									ckiStakeClaimEvents={ckiStakeClaimEvents}
									fdgStakeClaimEvents={fdgStakeClaimEvents}
									defenceChangeEvents={defenceChangeEvents}
									adGenChangeEvents={adGenChangeEvents}
									changeGCkiEvents={changeGCkiEvents}
									attackEvents={attackEvents}
									soundEffects={soundEffects}
								/>
							</div>
						</div>
					</div>
				</div>
			</main>
			<AnimatePresence>
				{modal === "attack" && checkConnection() && (
					<Modal setModal={setModal}>
						<Attack setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attack2" && (
					<Modal setModal={setModal}>
						<Attack2 setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attack3" && (
					<Modal setModal={setModal}>
						<Attack3 setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackVictory" && (
					<Modal setModal={setModal}>
						<AttackVictory setModal={setModal} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackLose" && (
					<Modal setModal={setModal}>
						<AttackLose setModal={setModal} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "attackFail" && (
					<Modal setModal={setModal}>
						<AttackFail setModal={setModal} attackState={attackState} txInfo={txInfo} />
					</Modal>
				)}
				{modal === "transaction" && checkConnection() && (
					<Modal setModal={setModal}>
						<Transaction
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transaction2" && (
					<Modal setModal={setModal}>
						<Transaction2
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transaction3" && (
					<Modal setModal={setModal}>
						<Transaction3
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "transactionLose" && (
					<Modal setModal={setModal}>
						<TransactionLose
							setModal={setModal}
							txInfo={txInfo}
							approveState={approveState}
							searchState={searchState}
						/>
					</Modal>
				)}
				{modal === "purchase" && checkConnection() && (
					<Modal setModal={setModal}>
						<Purchase
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchase2" && (
					<Modal setModal={setModal}>
						<Purchase2
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseSuccess" && (
					<Modal setModal={setModal}>
						<PurchaseSuccess
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "purchaseLose" && (
					<Modal setModal={setModal}>
						<PurchaseLose
							setModal={setModal}
							txInfo={txInfo}
							setDefenceState={setDefenceState}
							setAdState={setAdState}
							ckiLockState={ckiLockState}
							ckiUnlockState={ckiUnlockState}
							ckiLockedClaimState={ckiLockedClaimState}
							fdgLockState={fdgLockState}
							fdgUnlockState={fdgUnlockState}
							fdgLockedClaimState={fdgLockedClaimState}
							gCkiChangeState={gCkiChangeState}
						/>
					</Modal>
				)}
				{modal === "setAttack" && (
					<Modal setModal={setModal}>
						<SetAttack
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							setTxInfo={setTxInfo}
							ad={ad}
							addTx={addTx}
							setAd={setAd}
							adBlockedCKI={adBlockedCKI}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							signPermit={signPermit}
							ckiApprove={ckiApprove}
							withdrawing={false}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "withdrawAttack" && (
					<Modal setModal={setModal}>
						<SetAttack
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							setTxInfo={setTxInfo}
							ad={ad}
							addTx={addTx}
							setAd={setAd}
							adBlockedCKI={adBlockedCKI}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							signPermit={signPermit}
							ckiApprove={ckiApprove}
							withdrawing={true}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "setDefence" && (
					<Modal setModal={setModal}>
						<SetDefence
							account={account}
							setAlert={setAlert}
							defence={defense}
							addTx={addTx}
							ckiLocked={ckiLockedRaw}
							fdgLocked={fdgLockedRaw}
							setDefence={setDefence}
							setModal={setModal}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							signPermit={signPermit}
							setTxInfo={setTxInfo}
							withdrawing={false}
							soundEffects={soundEffects}
							adding={true}
						/>
					</Modal>
				)}
				{modal === "withdrawDefence" && (
					<Modal setModal={setModal}>
						<SetDefence
							account={account}
							setAlert={setAlert}
							defence={defense}
							addTx={addTx}
							ckiLocked={ckiLockedRaw}
							fdgLocked={fdgLockedRaw}
							setDefence={setDefence}
							setModal={setModal}
							cookieList={ckiList}
							balCki={balCki}
							ckiUnused={ckiUnused}
							signPermit={signPermit}
							setTxInfo={setTxInfo}
							withdrawing={true}
							soundEffects={soundEffects}
							adding={false}
						/>
					</Modal>
				)}
				{modal === "boost" && (
					<Modal setModal={setModal}>
						<Boost
							account={account}
							setAlert={setAlert}
							setModal={setModal}
							addTx={addTx}
							list={ckiList}
							totalBal={balCki}
							ckiStake={ckiLockedStake}
							fdgStake={fdgLockedStake}
							ckiTotalStake={ckiLockedTotal}
							fdgTotalStake={fdgLockedTotal}
							ckiLockRev={ckiLockRev}
							fdgLockRev={fdgLockRev}
							rawCkiStake={ckiLockedRaw}
							rawFdgStake={fdgLockedRaw}
							unused={ckiUnused}
							gCkiDeposit={gCkiDeposit}
							gCkiEnd={gCkiEnd}
							gCkiBal={gCkiBal}
							signPermit={signPermit}
							changeGCki={changeGCki}
							withdraw={withdraw}
							setTxInfo={setTxInfo}
							soundEffects={soundEffects}
							user={account}
						/>
					</Modal>
				)}
				{modal === "lock" && (
					<Modal setModal={setModal}>
						<Lock
							account={account}
							setAlert={setAlert}
							locking={true}
							setModal={setModal}
							addTx={addTx}
							list={dataType === "cookie" ? ckiList : fdgList}
							totalBal={dataType === "cookie" ? balCki : balFdg}
							isLock={true}
							unused={dataType === "cookie" ? ckiUnused : fdgUnused}
							raw={dataType === "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType === "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType === "cookie" ? ckiLockedTotal : fdgLockedTotal}
							lock={dataType === "cookie" ? ckiLock : fdgLock}
							unlock={dataType === "cookie" ? ckiUnlock : fdgUnlock}
							rev={dataType === "cookie" ? ckiLockRev : fdgLockRev}
							signPermit={signPermit}
							setTxInfo={setTxInfo}
							stakeApy={dataType === "cookie" ? fdgStakeApy : ckiStakeApy}
							gCkiBal={gCkiBal}
							defence={defense}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "unlock" && (
					<Modal setModal={setModal}>
						<Lock
							account={account}
							setAlert={setAlert}
							locking={false}
							setModal={setModal}
							addTx={addTx}
							list={dataType === "cookie" ? ckiList : fdgList}
							totalBal={dataType === "cookie" ? balCki : balFdg}
							isLock={false}
							unused={dataType === "cookie" ? ckiUnused : fdgUnused}
							raw={dataType === "cookie" ? ckiLockedRaw : fdgLockedRaw}
							stake={dataType === "cookie" ? ckiLockedStake : fdgLockedStake}
							total={dataType === "cookie" ? ckiLockedTotal : fdgLockedTotal}
							lock={dataType === "cookie" ? ckiLock : fdgLock}
							unlock={dataType === "cookie" ? ckiUnlock : fdgUnlock}
							rev={dataType === "cookie" ? ckiLockRev : fdgLockRev}
							signPermit={signPermit}
							setTxInfo={setTxInfo}
							stakeApy={dataType === "cookie" ? fdgStakeApy : ckiStakeApy}
							gCkiBal={gCkiBal}
							defence={defense}
							soundEffects={soundEffects}
						/>
					</Modal>
				)}
				{modal === "board" && (
					<Modal setModal={setModal}>
						<Board
							account={account}
							setModal={setModal}
							fdgLockRev={fdgLockRev}
							fdgLockedTotal={fdgLockedTotal}
							fdgLockedStake={fdgLockedStake}
							ckiLockedTotal={ckiLockedTotal}
							ckiLockedStake={ckiLockedStake}
							ckiLockRev={ckiLockRev}
							fdgStakeApy={fdgStakeApy}
							ckiStakeApy={ckiStakeApy}
							defense={defense}
							balCki={balCki}
							balFdg={balFdg}
							fdgStakedTotal={fdgStakedTotal}
							ckiStakedTotal={ckiStakedTotal}
							ckiStakeRev={ckiStakeRev}
							fdgStakeRev={fdgStakeRev}
						/>
					</Modal>
				)}
				{modal === "generalInfo" && (
					<Modal setModal={setModal}>
						<GeneralInfo
							setModal={setModal}
							title={"Connect your wallet"}
							content={
								<p>
									Welcome to CryptoCookies ! Remember to connect your wallet by
									clicking the top right button. Get more info
									<Link
										to="https://cryptocookies.gitbook.io/documentation/game-dynamics/getting-started"
										target="_blank"
										rel="noopener noreferrer"
									>
										{" "}
										here.
									</Link>
								</p>
							}
						/>
					</Modal>
				)}
				{modal === "welcomeBack" && (
					<Modal setModal={setModal}>
						<ClaimAll
							setModal={setModal}
							title={"Welcome back!"}
							user={account}
							addTx={addTx}
							claimAll={claimAll}
							soundEffects={soundEffects}
							setShowWelcome={setShowWelcome}
							content={
								<p>
									Good news ! Your Cookies and Fudge kept growing while you were
									gone. Claim your earning before you start playing.
									<br />
									{printBD(
										Number(ckiLockedClaimable) + Number(fdgStakedClaimable)
									)}{" "}
									CKI
									<br />
									{printBD(
										Number(fdgLockedClaimable) + Number(ckiStakedClaimable)
									)}{" "}
									FDG
								</p>
							}
						/>
					</Modal>
				)}
				{modal === "leagues" && (
					<Modal setModal={setModal}>
						<Leagues
							setModal={setModal}
							ckiLeague={ckiLeague}
							leagueSizes={dataType == "cookie" ? ckiLeagueSizes : fdgLeagueSizes}
							targetLeagueSize={
								dataType == "cookie" ? Number(ckiLeagueSize) : Number(fdgLeagueSize)
							}
							fdgLeague={fdgLeague}
							setTargetLeague={setTargetLeague}
							targetLeague={targetLeague}
						/>
					</Modal>
				)}
				{modal === "tutorial" && (
					<Modal setModal={setModal}>
						<Tutorial
							setModal={setModal}
							mint={mint}
							canMint={balCki == 0}
							soundEffects={soundEffects}
							account={account}
							setAlert={setAlert}
							balCki={balCki}
							balFdg={balFdg}
							hasMinted={hasMinted}
							balance={balance}
						/>
					</Modal>
				)}
			</AnimatePresence>
			<AnimatePresence>
				{cookieBanner && (
					<Modal setModal={setCookieBanner}>
						<CookieBanner setModal={setCookieBanner} />
					</Modal>
				)}
			</AnimatePresence>
		</>
	);
}
