import RealApp from "./RealApp";
import { Chainlabs, Addresses } from "./";
import { useEtherBalance, useEthers } from "@usedapp/core";
import { CreateErc20 } from "./interfaces/Erc20";
import { CreateGCki } from "./interfaces/GCki";
import { CreateStakingPool } from "./interfaces/Staking";
import { CreateLockingPool } from "./interfaces/Locking";
import { CreateAllOpponents } from "interfaces/Opponent";
import { CreateCompete } from "./interfaces/Compete";
import { BD, ZERO } from "./interfaces/Numbers";
import { signERC2612Permit } from "eth-permit";
import { CreateUtils } from "interfaces/Utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CreateCCDistr } from "interfaces/CCDistr";
import { Contract, utils as utilsEther } from "ethers";
import CCLocking from "./abis/CCLocking.json";
import { keccak256 } from "ethers/lib/utils";
import { leagueInfo } from "Pages/Home/components/Compete/Compete";

var bigdecimal = require("bigdecimal");

export function computeRatio(a, b) {
	return Number(
		(b.compareTo(ZERO) !== 0
			? a
					.divide(b, 4, bigdecimal.RoundingMode.HALF_UP())
					.multiply(BD(100))
					.setScale(2, bigdecimal.RoundingMode.HALF_UP())
			: BD(0)
		).toString()
	);
}

const preloadImages = (imagePaths) => {
	imagePaths.forEach(path => {
	  const img = new Image();
	  img.src = path;
	});
};

const getImagePaths = async () => {
	const context = require.context('../public/images', true, /\.(png|jpe?g|svg)$/);
	const imagePaths = context.keys().map(context);
	return imagePaths;
};

function App() {
	const { dataType } = useSelector((state) => state.common);
	const { activateBrowserWallet, account, switchNetwork, deactivate, chainId, library } =
		useEthers();
	const etherBalance = useEtherBalance(account);
	const cki = CreateErc20(Addresses.CKI, account);
	const fdg = CreateErc20(Addresses.FDG, account);
	const gCki = CreateGCki(Addresses.GCKI, account);
	const ad = CreateErc20(Addresses.DGH, account);

	const [ckiLeagueSizes, setCkiLeagueSizes] = useState({});
	const [fdgLeagueSizes, setFdgLeagueSizes] = useState({});
	
	useEffect(() => {
		const loadImages = async () => {
		  const imagePaths = await getImagePaths();
		  preloadImages(imagePaths);
		};
		loadImages();
	  }, []);

	useEffect(() => {
		if (!library) return;
		let mapCki = {};
		let mapFdg = {};

		const contractCki = new Contract(
			Addresses.CCLockingCKI,
			new utilsEther.Interface(CCLocking),
			library
		);
		const contractFdg = new Contract(
			Addresses.CCLockingFDG,
			new utilsEther.Interface(CCLocking),
			library
		);
		for (const key in leagueInfo) {
			mapCki[key] = 0; // contractCki.getLeagueSize(key);
			mapFdg[key] = 0; // contractFdg.getLeagueSize(key);
		}

		setCkiLeagueSizes(mapCki);
		setFdgLeagueSizes(mapFdg);
	}, [library]); // Loaded only once!
	// console.log(leagueSizes);

	const [targetLeague, setTargetLeague] = useState(0);

	const utils = CreateUtils(Addresses.CkiDistr, Addresses.FdgDistr, Addresses.Utils);

	const stakingCki = CreateStakingPool(Addresses.CCStakingCKI, account, utils.ckiStakeRev());
	const lockingCki = CreateLockingPool(
		Addresses.CCLockingCKI,
		account,
		utils.ckiLockRev(),
		targetLeague
	);

	// console.log(lockingCki.adversaries());

	const ckiOpponents = CreateAllOpponents(
		Addresses.CCLockingCKI,
		Addresses.CCCompete,
		lockingCki.adversaries(targetLeague),
		account
	);

	// console.log(lockingCki.adversaries(targetLeague));
	const stakingFdg = CreateStakingPool(Addresses.CCStakingFDG, account, utils.fdgStakeRev());
	const lockingFdg = CreateLockingPool(
		Addresses.CCLockingFDG,
		account,
		utils.fdgLockRev(),
		targetLeague
	);

	const fdgOpponents = CreateAllOpponents(
		Addresses.CCLockingFDG,
		Addresses.CCCompete,
		lockingFdg.adversaries(targetLeague),
		account
	);
	// console.log(fdgOpponents);
	const ccDistr = CreateCCDistr(Addresses.CCDistr, account);

	const compete = CreateCompete(
		Addresses.CCCompete,
		account,
		targetLeague,
		dataType == "cookie" ? Addresses.CCLockingCKI : Addresses.CCLockingFDG
	);
	const totalCki = cki
		.bal()
		.add(gCki.getDeposit())
		.add(stakingCki.ownStake())
		.add(compete.ckiBlockedAp())
		.add(compete.defenseBal())
		.add(lockingCki.rawStake());

	const totalFdg = fdg.bal().add(stakingFdg.ownStake()).add(lockingFdg.rawStake());

	async function signPermit(token, spender, val, isAd = false) {
		let nonce = undefined;
		if (isAd) {
			// nonce = ad.nonce + 1;
		}
		return await signERC2612Permit(library, token, account, spender, val, nonce);
	}

	const fdgList = [
		{
			id: "1",
			title: "Unused",
			bal: fdg.bal(),
			percent: computeRatio(fdg.bal(), totalFdg),
			color: "#C0C0C0",
		},
		{
			id: "2",
			title: "Staked",
			bal: stakingFdg.ownStake(),
			percent: computeRatio(stakingFdg.ownStake(), totalFdg),
			color: "#3065EC",
		},
		{
			id: "3",
			title: "Locked",
			bal: lockingFdg.rawStake(),
			percent: computeRatio(lockingFdg.rawStake(), totalFdg),
			color: "#EC3030",
		},
	];

	const ckiList = [
		{
			id: "1",
			title: "Unused",
			bal: cki.bal(),
			percent: computeRatio(cki.bal(), totalCki),
			color: "#C0C0C0",
		},
		{
			id: "2",
			title: "Staked",
			bal: stakingCki.ownStake(),
			percent: computeRatio(stakingCki.ownStake(), totalCki),
			color: "#3065EC",
		},
		{
			id: "3",
			title: "Locked",
			bal: lockingCki.rawStake(),
			percent: computeRatio(lockingCki.rawStake(), totalCki),
			color: "#EC3030",
		},
		{
			id: "4",
			title: "gCKI",
			bal: gCki.getDeposit(),
			percent: computeRatio(gCki.bal(), totalCki),
			color: "#18be28",
		},
		{
			id: "5",
			title: "AD",
			bal: compete.ckiBlockedAp(),
			percent: computeRatio(compete.ckiBlockedAp(), totalCki),
			color: "#F27D03",
		},
		{
			id: "6",
			title: "DP",
			bal: compete.defenseBal(),
			percent: computeRatio(compete.defenseBal(), totalCki),
			color: "#0DF0FF",
		},
	];
	if (chainId !== Chainlabs.chainId) {
		switchNetwork(Chainlabs.chainId);
	}

	const ckiTotalStaked = stakingCki.totalStake();
	const ckiStakeRev = utils.ckiStakeRev();
	const ckiStaked = stakingCki.ownStake();
	const [ckiStakeApy, setCkiApy] = useState(null);
	useEffect(() => {
		if (ckiStakeRev && ckiTotalStaked) {
			setCkiApy((ckiStakeRev * ckiStaked) / (ckiTotalStaked * 24));
		}
	}, [ckiTotalStaked, ckiStakeRev, ckiStaked]);

	const fdgTotalStaked = stakingFdg.totalStake();
	const fdgStakeRev = utils.fdgStakeRev();
	const fdgStaked = stakingFdg.ownStake();
	const [fdgStakeApy, setFdgApy] = useState(null);
	useEffect(() => {
		if (fdgStakeRev && fdgTotalStaked) {
			setFdgApy((fdgStakeRev * fdgStaked) / (fdgTotalStaked * 24));
		}
	}, [fdgTotalStaked, fdgStakeRev, fdgStaked]);

	return (
		<RealApp
			activateBrowserWallet={activateBrowserWallet}
			account={account}
			deactivate={deactivate}
			switchNetwork={switchNetwork}
			ckiLeagueSizes={ckiLeagueSizes}
			fdgLeagueSizes={fdgLeagueSizes}
			chainId={chainId}
			totalCki={totalCki}
			totalFdg={totalFdg}
			ckiList={ckiList}
			fdgList={fdgList}
			ad={ad}
			createAttack={compete.createAttack}
			ckiOpponents={ckiOpponents}
			fdgOpponents={fdgOpponents}
			targetLeague={targetLeague}
			setTargetLeague={setTargetLeague}
			compete={compete}
			gCki={gCki}
			signPermit={signPermit}
			cki={cki}
			lockingCki={lockingCki}
			stakingCki={stakingCki}
			utils={utils}
			lockingFdg={lockingFdg}
			ckiStakeApy={ckiStakeApy}
			fdgStakeApy={fdgStakeApy}
			fdg={fdg}
			stakingFdg={stakingFdg}
			ccDistr={ccDistr}
			balance={etherBalance}
		/>
	);
}

export default App;
