import React from "react";
import Progress from "./Progress";

export default function Search({ setModal }) {
  return (
    <div className="competeBody__search">
      <div className="competeBody__search-box">
        <div className="competeBody__search-image">
          <img
            className="swim2"
            src={process.env.PUBLIC_URL + "/images/icons/mixing.png"}
            alt=""
          />
        </div>
        <p>Mixing dough...</p>
      </div>
      <Progress
        onClick={() => {
          setModal("transaction");
        }}
        title1="Request sent"
        title2={"Opponents found"}
      />
    </div>
  );
}
