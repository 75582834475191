import { closeIcon } from "../../SVG";
import Slider from "react-slick";

export default function Tutorial({ setModal, mint, balCki, balFdg, soundEffects, account, setAlert, hasMinted, balance }) {
	const settings = {
		dots: true,
		infinite: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		draggable: false,

		// responsive: [
		//   {
		//     breakpoint: 540,
		//     settings: {
		//       slidesToShow: 3,
		//       arrows: false,
		//       slidesToScroll: 1,
		//     },
		//   },
		// ],
	};
	return (
		<div className="modalTutor">
			<div className="modalTutor__inner">
				<div className="modalTutor__inner-close" onClick={() => setModal(null)}>
					{closeIcon}
				</div>
				<div className="modalTutor__slider">
					<Slider {...settings}>
						<div className="modalTutor__item">
							<div className="modalTutor__item-title">
								Welcome to CryptoCookies!
							</div>
							<div className="modalTutor__item-content nopl">
								<div className="modalTutor__item-desc">
									<p>
										Begin your CryptoCookies adventure by minting CKI and FDG
										tokens.
										<br /> <br />
										These are non-tradable digital assets which are exclusive to
										the beta version. Each player can mint a single starter
										pack, offering equal chances to climb the CryptoCookies game
										leaderboard.
										<br /> <br />
										These tokens are your key to a captivating world of fun and
										endless possibilities. Let the minting magic commence!
									</p>
								</div>
								<div className="modalTutor__item-image">
									<img
										className="swim2"
										src={process.env.PUBLIC_URL + "/images/tutorial/cookie.png"}
										alt=""
									/>
								</div>
							</div>
							{(hasMinted === false || account === undefined) && (
								<button
									type="button"
									className="button secondary"
									onClick={() => {
										if (account === undefined) {
											soundEffects["error"].play();
											setAlert("Please connect your wallet.");
											return;
										}
										if (Number(balance) < 1e15) {
											soundEffects["error"].play();
											setAlert("You need at least 0.001 ETH to create an account.");
											return;
										}
										soundEffects["claim"].play();
										mint();
									}}
								>
									Mint CKI & FDG
								</button>
							)}
						</div>
						<div className="modalTutor__item">
							<div className="modalTutor__item-title">
								Stake your CKI or FDG tokens into one of the staking pools!
							</div>
							<div className="modalTutor__item-content">
								<div className="modalTutor__item-desc">
									<p>
										The CryptoCookies Beta App includes two staking pools, which
										allow users to generate staking yields in the opposite
										currency!
									</p>
								</div>
								<div className="modalTutor__item-image">
									<img
										className="swim2"
										src={process.env.PUBLIC_URL + "/images/tutorial/clock.png"}
										alt=""
									/>
								</div>
							</div>
							{(hasMinted === false || account === undefined) && (
								<button
									type="button"
									className="button secondary"
									onClick={() => {
										if (account === undefined) {
											soundEffects["error"].play();
											setAlert("Please connect your wallet.");
											return;
										}
										if (Number(balance) < 1e15) {
											soundEffects["error"].play();
											setAlert("You need at least 0.001 ETH to create an account.");
											return;
										}
										soundEffects["claim"].play();
										mint();
									}}
								>
									Mint CKI & FDG
								</button>
							)}
						</div>
						<div className="modalTutor__item">
							<div className="modalTutor__item-title">
								Deploy Cookies in the CryptoCookies Kitchen
							</div>
							<div className="modalTutor__item-content">
								<div className="modalTutor__item-desc second">
									<p>
										In the CryptoCookies kitchen you can define the amount of
										cookies you want to deploy as attack dough (AD) and defense
										points (DP) to prepare and adapt for combat and you can also
										convert them into GoldenCookies (gCKI) to boost your locking
										returns.
									</p>
								</div>
								<div className="modalTutor__item-body">
									<div className="modalTutor__item-body-row">
										<div className="modalTutor__item-body-text">
											<div className="modalTutor__item-body-text-title">
												Attack with
											</div>
											<div className="modalTutor__item-body-text-subtitle">
												AD
											</div>
										</div>
										<div className="modalTutor__item-body-image">
											<img
												className="swim2"
												src={
													process.env.PUBLIC_URL +
													"/images/tutorial/attack_el.png"
												}
												alt=""
											/>
										</div>
									</div>
									<div className="modalTutor__item-body-row">
										<div className="modalTutor__item-body-text">
											<div className="modalTutor__item-body-text-title">
												Defend with
											</div>
											<div className="modalTutor__item-body-text-subtitle">
												DP
											</div>
										</div>
										<div className="modalTutor__item-body-image">
											<img
												className="swim2"
												src={
													process.env.PUBLIC_URL +
													"/images/tutorial/shield.png"
												}
												alt=""
											/>
										</div>
									</div>
									<div className="modalTutor__item-body-row">
										<div className="modalTutor__item-body-text">
											<div className="modalTutor__item-body-text-title">
												Boost witih
											</div>
											<div className="modalTutor__item-body-text-subtitle">
												GCKI
											</div>
										</div>
										<div className="modalTutor__item-body-image">
											<img
												className="swim2"
												src={
													process.env.PUBLIC_URL +
													"/images/tutorial/dough.png"
												}
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
							{(hasMinted === false || account === undefined) && (
								<button
									type="button"
									className="button secondary"
									onClick={() => {
										if (account === undefined) {
											soundEffects["error"].play();
											setAlert("Please connect your wallet.");
											return;
										}
										if (Number(balance) < 1e15) {
											soundEffects["error"].play();
											setAlert("You need at least 0.001 ETH to create an account.");
											return;
										}
										soundEffects["claim"].play();
										mint();
									}}
								>
									Mint CKI & FDG
								</button>
							)}
						</div>
						<div className="modalTutor__item">
							<div className="modalTutor__item-title">
								Lock your cookies in Locking pools or use them to launch an attack
								on an opponent.
							</div>
							<div className="modalTutor__item-content">
								<div className="modalTutor__item-desc third">
									<p>
										Earn additional cookies or fudge tokens by attacking your
										opponents in a competitive arena and increase your position
										in the leaderboard. The player who accumulates the most CKI
										and Fudge tokens at the end of the Season wins!
									</p>
								</div>
								<div className="modalTutor__item-image third">
									<img
										className="swim2"
										src={process.env.PUBLIC_URL + "/images/tutorial/cup.png"}
										alt=""
									/>
								</div>
							</div>
							{(hasMinted === false || account === undefined) && (
								<button
									type="button"
									className="button secondary"
									onClick={() => {
										if (account === undefined) {
											soundEffects["error"].play();
											setAlert("Please connect your wallet.");
											return;
										}
										if (Number(balance) < 1e15) {
											soundEffects["error"].play();
											setAlert("You need at least 0.001 ETH to create an account.");
											return;
										}
										soundEffects["claim"].play();
										mint();
									}}
								>
									Mint CKI & FDG
								</button>
							)}
						</div>
					</Slider>
				</div>
			</div>
		</div>
	);
}
