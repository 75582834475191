import { useState } from "react";
import {
	arrowUpIcon,
	changeIcon,
	pinIcon,
	plusIcon2,
	polRight,
	shieldIcon,
	attackIcon,
} from "../../../../Base/SVG";
import { printBD, printUnscaledBD } from "interfaces/Numbers";

import { createAvatar } from "@dicebear/core";
import { avataaarsNeutral } from "@dicebear/collection";

function timestampToLocaleTime(timestamp) {
	const milliseconds = timestamp * 1000;
	const date = new Date(milliseconds);

	const hours = String(date.getHours()).padStart(2, "0");
	const minutes = String(date.getMinutes()).padStart(2, "0");

	const formattedTime = `${hours}:${minutes}`;

	return formattedTime;
}

function extractEvents(account, events, textFormat, type) {
	var listPersonal = [];
	var listGlobal = [];
	if (events) {
		for (var i = 0; i < events.value.length; i++) {
			const data = events.value[i].data;
			if (data.user == account) {
				listPersonal.push({
					id: data.timestamp,
					time: timestampToLocaleTime(data.timestamp),
					type: type,
					text: `${textFormat
						.replace("%s", printBD(data.amount))
						.replace("%a", printBD(data.cki))
						.replace("%b", printUnscaledBD((data.end - data.timestamp) / 86400))}.`,
				});
			}
			if (
				type == "lock" ||
				type == "stake" ||
				type == "ad" ||
				type == "boost" ||
				type == "defence"
			) {
				const userAvatar = '<img src="./images/icons/metamask.svg" alt="" />';
				const style =
					' style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px;background-color: #d0eef4;" title="';

				const avatar = createAvatar(avataaarsNeutral, {
					seed: data.user,
				}).toString();
				const avatar1 =
					data.user == account
						? userAvatar.substring(0, 4) +
						  style +
						  data.user +
						  '"' +
						  userAvatar.substring(4, userAvatar.length)
						: avatar.substring(0, 4) +
						  style +
						  '"' +
						  avatar.substring(4, avatar.length - 6) +
						  "<title>" +
						  data.user +
						  "</title>" +
						  "</svg>";
				listGlobal.push({
					id: data.timestamp,
					time: timestampToLocaleTime(data.timestamp),
					type: type,
					text: `${
						avatar1 +
						" " +
						textFormat
							.replace("%s", printBD(data.amount))
							.replace("%a", printBD(data.cki))
							.replace("%b", printUnscaledBD((data.end - data.timestamp) / 86400))
					}.`,
				});
			}
		}
	}

	return { listPersonal, listGlobal };
}

function extractAttacks(account, events) {
	var listPersonal = [];
	var listGlobal = [];
	if (events) {
		for (var i = 0; i < events.value.length; i++) {
			const data = events.value[i].data;
			// console.log(data);
			//const avatar1 = createAvatar(avataaarsNeutral, {
			//	seed: data.attacke,
			//}).toString();
			const userAvatar = '<img src="./images/icons/metamask.svg" alt="" />';
			const style =
				' style="width: 25px; height: 25px; border-radius: 100%; vertical-align: -7px;background-color: #d0eef4;" title="';

			const avatarAttacker = createAvatar(avataaarsNeutral, {
				seed: data.attacker,
			}).toString();
			const avatar1 =
				data.attacker == account
					? userAvatar.substring(0, 4) +
					  style +
					  data.attacker +
					  '"' +
					  userAvatar.substring(4, userAvatar.length)
					: avatarAttacker.substring(0, 4) +
					  style +
					  '"' +
					  avatarAttacker.substring(4, avatarAttacker.length - 6) +
					  "<title>" +
					  data.attacker +
					  "</title>" +
					  "</svg>";

			const avatarAdv = createAvatar(avataaarsNeutral, {
				seed: data.adv,
			}).toString();
			const avatar2 =
				data.adv == account
					? userAvatar.substring(0, 4) +
					  style +
					  data.attacker +
					  '"' +
					  userAvatar.substring(4, userAvatar.length)
					: avatarAdv.substring(0, 4) +
					  style +
					  '"' +
					  avatarAdv.substring(4, avatarAdv.length - 6) +
					  "<title>" +
					  data.adv +
					  "</title>" +
					  "</svg>";

			const item = {
				id: data.timestamp,
				time: timestampToLocaleTime(data.timestamp),
				type: "attack",
				text: `${avatar1} vs ${avatar2}: ${data.success ? "WIN" : "LOSS"} (${printBD(
					data.apUSed
				)} AD +${printBD(data.gain)} TKN)`,
			};
			// console.log(avatar1);
			// </svg>
			if (data.attacker == account || data.adv == account) {
				listPersonal.push(item);
			}
			listGlobal.push(item);
		}
	}

	return { listPersonal, listGlobal };
}

function generateList(
	account,
	ckiLockChangeEvents,
	fdgLockChangeEvents,
	ckiLockClaimEvents,
	fdgLockClaimEvents,
	ckiStakeChangeEvents,
	fdgStakeChangeEvents,
	ckiStakeClaimEvents,
	fdgStakeClaimEvents,
	defenceChangeEvents,
	adGenChangeEvents,
	changeGCkiEvents,
	attackEvents
) {
	const { listPersonal: ckiLock, listGlobal: ckiGlobalLock } = extractEvents(
		account,
		ckiLockChangeEvents,
		"changed CKI locking to %s",
		"lock"
	);
	const { listPersonal: fdgLock, listGlobal: fdgGlobalLock } = extractEvents(
		account,
		fdgLockChangeEvents,
		"changed FDG locking to %s",
		"lock"
	);
	const { listPersonal: ckiStake, listGlobal: ckiGlobalStake } = extractEvents(
		account,
		ckiStakeChangeEvents,
		"changed CKI staking to %s",
		"stake"
	);
	const { listPersonal: fdgStake, listGlobal: fdgGlobalStake } = extractEvents(
		account,
		fdgStakeChangeEvents,
		"changed FDG staking to %s",
		"stake"
	);

	const { listPersonal: ckiLockClaim } = extractEvents(
		account,
		ckiLockClaimEvents,
		"claimed %s CKI from locking",
		"claim"
	);
	const { listPersonal: fdgLockClaim } = extractEvents(
		account,
		fdgLockClaimEvents,
		"claimed %s FDG from locking",
		"claim"
	);
	const { listPersonal: ckiStakeClaim } = extractEvents(
		account,
		ckiStakeClaimEvents,
		"claimed %s FDG from staking",
		"claim"
	);
	const { listPersonal: fdgStakeClaim } = extractEvents(
		account,
		fdgStakeClaimEvents,
		"claimed %s CKI from staking",
		"claim"
	);

	const { listPersonal: defenceChange, listGlobal: defenceGlobal } = extractEvents(
		account,
		defenceChangeEvents,
		"set defence to %s CKI",
		"defence"
	);
	const { listPersonal: adGenChange, listGlobal: adGlobal } = extractEvents(
		account,
		adGenChangeEvents,
		"set AD gen. to %s CKI",
		"ad"
	);

	const { listPersonal: gCkiChange, listGlobal: boostGlobal } = extractEvents(
		account,
		changeGCkiEvents,
		"boosted to %a CKI and %b days",
		"boost"
	);

	const { listPersonal: attacks, listGlobal: allAttacks } = extractAttacks(account, attackEvents);

	const personalEvents = ckiLock.concat(
		fdgLock,
		ckiStake,
		fdgStake,
		ckiLockClaim,
		fdgLockClaim,
		ckiStakeClaim,
		fdgStakeClaim,
		defenceChange,
		adGenChange,
		gCkiChange,
		attacks
	);
	const globalEvents = ckiGlobalLock.concat(
		fdgGlobalLock,
		ckiGlobalStake,
		fdgGlobalStake,
		allAttacks,
		defenceGlobal,
		adGlobal,
		boostGlobal
	);

	return {
		personalEvents: personalEvents.sort((a, b) => b.id - a.id),
		globalEvents: globalEvents.sort((a, b) => b.id - a.id),
	};
}

export default function Alerts(props) {
	const {
		addClass,
		account,
		ckiLockChangeEvents,
		fdgLockChangeEvents,
		ckiLockClaimEvents,
		fdgLockClaimEvents,
		ckiStakeChangeEvents,
		fdgStakeChangeEvents,
		ckiStakeClaimEvents,
		fdgStakeClaimEvents,
		defenceChangeEvents,
		adGenChangeEvents,
		changeGCkiEvents,
		attackEvents,
		soundEffects,
	} = props;

	const [tab, setTab] = useState("events");
	const { personalEvents, globalEvents } = generateList(
		account,
		ckiLockChangeEvents,
		fdgLockChangeEvents,
		ckiLockClaimEvents,
		fdgLockClaimEvents,
		ckiStakeChangeEvents,
		fdgStakeChangeEvents,
		ckiStakeClaimEvents,
		fdgStakeClaimEvents,
		defenceChangeEvents,
		adGenChangeEvents,
		changeGCkiEvents,
		attackEvents
	);

	// console.log(globalEvents);

	return (
		<div className={"alerts " + (addClass ? addClass : "")}>
			<h5>Game Alerts</h5>
			<div className="alerts__tabs">
				<button
					type="button"
					className={"alerts__tab " + (tab === "history" ? "active" : "")}
					onClick={() => {
						soundEffects["click"].play();
						setTab("history");
					}}
				>
					My Action History
				</button>
				<button
					type="button"
					className={"alerts__tab " + (tab === "events" ? "active" : "")}
					onClick={() => {
						soundEffects["click"].play();
						setTab("events");
					}}
				>
					Game Events
				</button>
			</div>
			<div className="alerts__inner">
				{(tab === "history" ? personalEvents : globalEvents).map((item, index) => {
					return (
						<div
							key={index}
							className={
								"alertsItem " +
								(item.type === "lock" ? "orange" : "") +
								(item.type === "claim" ? "pink" : "") +
								(item.type === "boost" ? "yellow" : "") +
								(item.type === "victory" ? "green" : "") +
								(item.type === "loss" ? "red" : "") +
								(item.type === "stake" ? "blue" : "") +
								(item.type === "ad" ? "purple" : "") +
								(item.type === "attack" ? "violet" : "")
							}
						>
							<p className="sm">{item.time}</p>
							<div className="alertsItem__icon">
								{item.type === "ad" && polRight}
								{item.type === "claim" && plusIcon2}
								{(item.type === "lock" || item.type === "stake") && changeIcon}
								{(item.type === "victory" || item.type === "loss") && pinIcon}
								{item.type === "boost" && arrowUpIcon}
								{item.type === "defence" && shieldIcon}
								{item.type === "attack" && attackIcon}
							</div>
							<p className="sm" dangerouslySetInnerHTML={{ __html: item.text }}>
								{/*item.text*/}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}
